import { CssBaseline } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

export const GlobalStyle = withStyles({
    "@global": {
        "html, body, #root": {
            width: "100%",
            height: "100%",
            fontFamily: "Noto Sans CJK TC, sans-serif",
            userSelect: "none",
        },
        ul: {
            paddingInlineStart: 0,
        },
        li: {
            listStyle: "none",
        },
        "#root": {
            display: "flex",
        },
        ".StripeElement": {
            height: "20px",
            padding: "12px 15px",
            boxSizing: "content-box",
            backgroundColor: "white",
            "& input": {
                fontSize: "16px",
            },
        },
    },
})(() => null)

ReactDOM.render(
    <>
        <App />
        <CssBaseline />
        <GlobalStyle />
    </>,
    document.getElementById("root") as HTMLElement,
)
serviceWorkerRegistration.register()
