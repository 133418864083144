import {
    IndexRouteProps,
    LayoutRouteProps,
    PathRouteProps,
    useParams,
} from "react-router-dom"
import { Title } from "./Title"

type ITitleRoute = (PathRouteProps | LayoutRouteProps | IndexRouteProps) & {
    mapper(match: any): string
}

export default function TitleRoute({ mapper, ...rest }: ITitleRoute) {
    const Component = () => {
        const { prefix } = useParams()
        return <Title title={mapper(prefix)} />
    }

    return <Component />
}
