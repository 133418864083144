import { defineMessages } from "react-intl"

const messages = defineMessages({
    invalidDestination: {
        id: "drive.status.invalid.destination",
        defaultMessage: "The destination is invalid",
    },
    insufficientSpace: {
        id: "drive.status.insufficient.space",
        defaultMessage: "The drive doesn't have enough space",
    },
})

export default messages
