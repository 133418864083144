import { createTheme } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

interface ISizeClass {
    [key: string]: number
}

interface IColorSetting {
    color: string
    light: string
    dark: string
    text: string
}

export interface IColorPresets {
    primary: IColorSetting
    secondary: IColorSetting
    background: IColorSetting

    [key: string]: IColorSetting
}

export interface ITheme {
    colors: IColorPresets
    icon: {
        color: string
        size: ISizeClass
    }
}

const breakpoints = createBreakpoints({})

export const MaterialBright = createTheme({
    palette: {
        action: {
            selected: "#20AB78",
            hover: "#fff",
            disabled: "#9B9B9B",
        },
        primary: {
            light: "#65e5a1",
            main: "#20AB78",
            dark: "#008146",
            contrastText: "#fff", // getContrastText(palette.primary[500]),
        },
        secondary: {
            light: "#b6d1cf",
            main: "#edeff1",
            dark: "#597170",
            contrastText: "#000",
        },
        error: {
            light: "#f44",
            main: "#f44",
            dark: "#f44",
            contrastText: "#fff",
        },
        background: {
            default: "#F8F8F8",
        },
        text: {
            secondary: "#7A7E81",
        },
    },
    props: {
        MuiButtonBase: {
            TouchRippleProps: {
                style: {
                    color: "rgba(0, 0, 0, 0.87)",
                },
            },
        },
        MuiButton: {
            disableRipple: true,
        },
        MuiInputBase: {
            spellCheck: false,
        },
        MuiSelect: {
            IconComponent: ExpandMoreIcon,
            disableUnderline: true,
        },
    },
    overrides: {
        MuiBreadcrumbs: {
            ol: {
                height: "22px",
                flexWrap: "nowrap",
            },
            li: {
                overflow: "hidden",
                minWidth: "16px",
                textAlign: "center",
                textOverflow: "ellipsis",
                "&:first-child": {
                    minWidth: "fit-content",
                },
                "&:last-child > *": {
                    color: "#20AB78",
                },
                "&:hover": {
                    textDecoration: "underline",
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                outline: "0 !important",
                borderRadius: "2px",
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    backgroundColor: "#22B37E",
                    boxShadow: "none",
                },
                "&:active": {
                    backgroundColor: "#00956E",
                    boxShadow: "none",
                },
                "&:disabled": {
                    backgroundColor: "#CAEDE0",
                    boxShadow: "none",
                },
            },
            containedPrimary: {
                boxShadow: "none",
                "&:hover": {
                    backgroundColor: "#22B37E",
                    boxShadow: "none",
                },
                "&:active": {
                    backgroundColor: "#00956E",
                    boxShadow: "none",
                },
                "&:disabled": {
                    backgroundColor: "#CAEDE0",
                    boxShadow: "none",
                    color: "#8BB6A6",
                },
            },
            containedSecondary: {
                boxShadow: "none",
                backgroundColor: "#EFEFEF",
                color: "#898989",
                "&:hover": {
                    backgroundColor: "#F2F3F4",
                    boxShadow: "none",
                },
                "&:active": {
                    backgroundColor: "#DFDFDF",
                    boxShadow: "none",
                },
                "&:disabled": {
                    backgroundColor: "#F0F0F0",
                    boxShadow: "none",
                    color: "#9EA1A2",
                },
            },
            text: {
                color: "#20AB78",
                transition: "color, .15s ease-in",
                "& > span > svg": {
                    transition: "color, .15s ease-in",
                },
                "&:hover": {
                    backgroundColor: "transparent",
                    color: "#57D3A5",
                    "& > span > svg": {
                        color: "#57D3A5",
                    },
                    "&:active": {
                        color: "#20AB78",
                        "& > span > svg": {
                            color: "#20AB78",
                        },
                    },
                },
            },
            textPrimary: {
                "&:hover": {
                    backgroundColor: "transparent",
                    color: "#57D3A5",
                    "& > span > svg": {
                        color: "#57D3A5",
                    },
                    "&:active": {
                        color: "#20AB78",
                        "& > span > svg": {
                            color: "#20AB78",
                        },
                    },
                },
            },
        },
        MuiDialog: {
            root: {
                backgroundColor: "rgba(127, 175, 214, 0.2)",
            },
        },
        MuiDivider: {
            root: {
                borderStyle: "solid",
                borderWidth: "0px",
            },
        },
        MuiDrawer: {
            paper: {
                width: "350px",
                backgroundColor: "#EDEFF1",
                border: "none !important",
                [breakpoints.down("sm")]: {
                    width: "240px",
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontStyle: "italic",
                marginLeft: "12px",
                "&::before": {
                    content: "'*'",
                },
            },
        },
        MuiListItem: {
            root: {
                "&:hover": {
                    color: "#20AB78",
                    "& > div": {
                        color: "#20AB78",
                    },
                },
                "&$selected": {
                    color: "#20AB78",
                    backgroundColor: "transparent",
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "& > div": {
                        color: "#20AB78",
                    },
                },
            },
            button: {
                "&:hover": {
                    backgroundColor: "#F7F8F8",
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: "auto",
                color: "#91A5B9",
                "&> svg": {
                    color: "inherit",
                },
            },
        },
        MuiListItemText: {
            root: {
                color: "#91A5B9",
            },
        },
        MuiNativeSelect: {
            icon: {
                color: "#B5B5B6",
                marginRight: "10px",
                width: "20px",
            },
            select: {
                "&:focus": {
                    backgroundColor: "white",
                },
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#91A5B9",
            },
        },
        MuiSelect: {
            root: {
                "&:hover": {
                    color: "#57D3A5",
                    border: "1px solid #C7E0D7",
                    "& ~ svg": {
                        color: "#57D3A5",
                    },
                    "& span": {
                        color: "#57D3A5",
                    },
                },
                "&:active": {
                    color: "#20AB78",
                    border: "1px solid #20AB78",
                    "& ~ svg": {
                        color: "#20AB78",
                    },
                },
                "&$disabled": {
                    background: "#EDEDED",
                    color: "#949495",
                    "& ~ svg": {
                        color: "#949495",
                    },
                    "&:hover": {
                        borderColor: "#E6E6E6",
                        color: "#949495",
                        "& ~ svg": {
                            color: "#949495",
                        },
                        "&:active": {
                            color: "#949495",
                            borderColor: "#E6E6E6",
                            "& ~ svg": {
                                color: "#949495",
                            },
                        },
                    },
                },
            },
            select: {
                color: "#949495",
                border: "1px solid #E6E6E6",
                borderRadius: "2px",
                "&:focus": {
                    color: "#20AB78",
                    border: "1px solid #20AB78",
                    borderRadius: "2px",
                    "& ~ svg": {
                        color: "#20AB78",
                    },
                    "& span": {
                        color: "#20AB78",
                    },
                },
                "&$disabled": {
                    border: "1px solid #E6E6E6",
                },
            },
            icon: {
                color: "#949495",
                right: "9px",
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: "rgba(33, 42, 64, 0.7)",
                borderRadius: "2px",
            },
        },
        MuiMenuItem: {
            root: {
                color: "#91A5B9",
                fontSize: "16px",
                height: "50px",
                paddingTop: "15px",
                paddingBottom: "15px",
                "& div": { padding: "0px" },
                "& span": { margin: "auto" },
                "&:hover": {
                    backgroundColor: "#F7F8F8",
                },
                "&$selected": {
                    "& div": { color: "#20AB78" },
                    backgroundColor: "transparent",
                    color: "#20AB78",
                },
            },
        },
        MuiInputLabel: {
            outlined: {
                color: "#AAABAB",
                transform: "translate(14px, 15px) scale(1)",
            },
        },
        MuiInputBase: {
            root: {
                color: "#949495",
                "&$error": {
                    "& > .MuiSelect-root": {
                        borderColor: "#f44",
                    },
                },
                "&$disabled": {
                    border: "none",
                    color: "#797E80",
                },
            },
            input: {
                border: "0px solid #E6E6E6",
                borderRadius: "2px",
                backgroundColor: "#ffffff",
                padding: "9px 13px",
                "&:focus": {
                    borderColor: "#20AB78",
                },
                "&:disabled": {
                    color: "#797E80",
                    backgroundColor: "#EDEDED",
                    borderColor: "transparent",
                },
                "&::placeholder": {
                    color: "#AAABAB",
                    opacity: 1,
                },
            },
        },
        MuiTextField: {
            root: {
                "& fieldset": {
                    borderRadius: 2,
                    borderWidth: "1 !important",
                    borderColor: "#E6E6E6",
                    "&:hover": {
                        border: "none",
                    },
                    "&:disabled": {
                        border: "none",
                    },
                },
                "&:disabled fieldset": {
                    border: "red",
                },
                "& > div": {
                    "&:hover": {
                        border: "none",
                    },
                },
            },
        },

        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline":
                    {
                        borderColor: "#B0C3BC",
                    },
                "&$focused $notchedOutline:not($error)": {
                    borderWidth: "1px",
                },
                "&$disabled $notchedOutline": {
                    borderColor: "transparent",
                },
            },
            input: {
                height: "20px",
                padding: "9px 13px",
                fontSize: "14px",
            },
        },
        MuiLink: {
            root: {
                transition: "color, .15s ease-in",
                outline: 0,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                "&:hover": {
                    color: "#57D3A5",
                },
                "&:active": {
                    color: "#20AB78",
                },
            },
        },
        MuiCheckbox: {
            colorPrimary: {
                "&$checked": {
                    "& > span > svg": {
                        color: "#20AB78",
                    },
                },
            },
            root: {
                "&$checked": {
                    "& > span > svg": {
                        color: "#20AB78",
                    },
                },
            },
        },
        MuiSwitch: {
            root: {
                width: "66px",
            },
            switchBase: {
                padding: "4px",
                "&:hover": {
                    // remove hover shadow
                    backgroundColor: "transparent",
                },
                "&$checked": {
                    "& + $track": {
                        opacity: 1,
                        backgroundColor: "#CAEDE0 !important",
                    },
                    "& $thumb": {
                        backgroundColor: "#20AB78",
                    },
                },
                "&$disabled": {
                    "& + $track": {
                        opacity: 1,
                    },
                    "& $thumb": {
                        backgroundColor: "#e6e6e6",
                    },
                },
                "&$disabled$checked": {
                    "& $thumb": {
                        backgroundColor: "#73E9BE",
                    },
                },
            },
            thumb: {
                height: "30px",
                width: "30px",
                backgroundColor: "#D6D6D6",
                boxShadow: "none",
            },
            track: {
                backgroundColor: "#EDEDED !important",
                height: "18px",
                opacity: 1,
            },
        },
        MuiTabs: {
            root: {
                minHeight: "38px !important",
            },
            indicator: {
                backgroundColor: "#20AB78",
                height: "2px",
            },
        },
        MuiTab: {
            root: {
                height: "38px !important",
                minHeight: "38px !important",
                minWidth: "90px !important",
                outline: "none !important",
                "&$selected .MuiTab-wrapper": {
                    color: "#20AB78",
                },
            },
            textColorInherit: {
                color: "#797E80",
            },
            wrapper: {
                textTransform: "initial",
            },
        },
        MuiTableSortLabel: {
            root: {
                color: "#9FA0A0",
                "& p": {
                    fontSize: "15px",
                },
            },
            active: {
                color: "#27b272 !important",
            },
            icon: {
                color: "inherit !important",
            },
        },
    },
})

export const MaterialDark = createTheme({
    palette: {
        primary: {
            light: "#ffffff",
            main: "#e0e0e0",
            dark: "#aeaeae",
            contrastText: "#fff",
        },
        secondary: {
            light: "#efefef",
            main: "#bdbdbd",
            dark: "#8d8d8d",
            contrastText: "#1a1a1a",
        },
        error: {
            light: "#f44",
            main: "#f44",
            dark: "#f44",
            contrastText: "#fff",
        },
        type: "dark",
    },
    overrides: {
        MuiDrawer: {
            paper: {
                width: "350px",
            },
        },
        MuiMenu: {
            paper: {
                backgroundColor: "#616161",
            },
        },
        MuiMenuItem: {
            root: {
                borderRadius: "5px",
                color: "#fff",
                fontSize: "16px",
                marginTop: "0.4em",
                marginBottom: "0.4em",
                padding: "0.5em 0.3em 0.5em 0.3em",
                "& div": { padding: "0px", width: "20%" },
                "& span": { margin: "auto" },
                "& div, span": { color: "#fff" },
                "&:hover": {
                    "& div, span": { color: "#20AB78 !important" },
                },
                "&$selected": {
                    "& div, span": { color: "white !important" },
                    color: "#ffffff !important",
                    backgroundColor: "#20AB78 !important",
                },
            },
        },
        MuiSvgIcon: {
            root: {
                color: "white",
            },
        },
        MuiSwitch: {
            root: {
                width: "66px",
            },
            switchBase: {
                padding: "4px",
                "&:hover": {
                    // remove hover shadow
                    backgroundColor: "transparent !important",
                },
            },
            thumb: {
                height: "30px",
                width: "30px",
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: "transparent",
            },
        },
        MuiTab: {
            root: {
                minWidth: "120px !important",
                "&$selected .MuiTab-wrapper": {
                    backgroundColor: "#27b272",
                    borderRadius: "3px",
                    color: "white",
                },
            },
            wrapper: {
                textTransform: "initial",
            },
        },
    },
})
