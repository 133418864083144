import React, {
    createContext,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from "react"
import { BehaviorSubject, of } from "rxjs"
import useEventSubject from "../hooks/useEventSubject"

export const LangContext = createContext({
    lang$: of("en"),
    setLang: (lang: string) => {},
})

LangContext.displayName = "LangContext"

export function LangProvider({ children }: { children: ReactElement }) {
    const [lang$] = useState(new BehaviorSubject("en"))
    const [setLang, setLang$] = useEventSubject<string>()

    useEffect(() => {
        const sub = setLang$.subscribe({ next: lang$.next.bind(lang$) })
        return () => sub.unsubscribe()
    }, [setLang$, lang$])

    const value = useMemo(
        () => ({
            lang$,
            setLang,
        }),
        [lang$, setLang],
    )

    return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

export const LangConsumer = LangContext.Consumer
