// source: protocol/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var protocol_plan_pb = require('../protocol/plan_pb.js');
goog.object.extend(proto, protocol_plan_pb);
goog.exportSymbol('proto.saasphoto.AddFeedbackRequest', null, global);
goog.exportSymbol('proto.saasphoto.CreateFreeTrialAccountRequest', null, global);
goog.exportSymbol('proto.saasphoto.CreateFreeTrialAccountResponse', null, global);
goog.exportSymbol('proto.saasphoto.CreateFreeTrialPlanRequest', null, global);
goog.exportSymbol('proto.saasphoto.CreateFreeTrialPlanResponse', null, global);
goog.exportSymbol('proto.saasphoto.CustomerCharge', null, global);
goog.exportSymbol('proto.saasphoto.CustomerErrorCode', null, global);
goog.exportSymbol('proto.saasphoto.Feedback', null, global);
goog.exportSymbol('proto.saasphoto.GetAccountTypeResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetChargesResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetFeedbackListResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetLatestUserPlanResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetSystemNotifyListResponse', null, global);
goog.exportSymbol('proto.saasphoto.IsValidSerialNumberRequest', null, global);
goog.exportSymbol('proto.saasphoto.IsValidSerialNumberResponse', null, global);
goog.exportSymbol('proto.saasphoto.IsValidUserResponse', null, global);
goog.exportSymbol('proto.saasphoto.QueryUsedResponse', null, global);
goog.exportSymbol('proto.saasphoto.SubscriptionRequest', null, global);
goog.exportSymbol('proto.saasphoto.SubscriptionStatus', null, global);
goog.exportSymbol('proto.saasphoto.SystemNotify', null, global);
goog.exportSymbol('proto.saasphoto.UpdateDefaultUploadFolderRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.AddFeedbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.AddFeedbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.AddFeedbackRequest.displayName = 'proto.saasphoto.AddFeedbackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.Feedback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.Feedback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.Feedback.displayName = 'proto.saasphoto.Feedback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetFeedbackListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetFeedbackListResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetFeedbackListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetFeedbackListResponse.displayName = 'proto.saasphoto.GetFeedbackListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.CustomerCharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.CustomerCharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.CustomerCharge.displayName = 'proto.saasphoto.CustomerCharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetChargesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetChargesResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetChargesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetChargesResponse.displayName = 'proto.saasphoto.GetChargesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetLatestUserPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.GetLatestUserPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetLatestUserPlanResponse.displayName = 'proto.saasphoto.GetLatestUserPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.QueryUsedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.QueryUsedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.QueryUsedResponse.displayName = 'proto.saasphoto.QueryUsedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.SystemNotify = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.SystemNotify, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.SystemNotify.displayName = 'proto.saasphoto.SystemNotify';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetSystemNotifyListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetSystemNotifyListResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetSystemNotifyListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetSystemNotifyListResponse.displayName = 'proto.saasphoto.GetSystemNotifyListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.UpdateDefaultUploadFolderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.UpdateDefaultUploadFolderRequest.displayName = 'proto.saasphoto.UpdateDefaultUploadFolderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.SubscriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.SubscriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.SubscriptionRequest.displayName = 'proto.saasphoto.SubscriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.CreateFreeTrialPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.CreateFreeTrialPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.CreateFreeTrialPlanRequest.displayName = 'proto.saasphoto.CreateFreeTrialPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.CreateFreeTrialPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.CreateFreeTrialPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.CreateFreeTrialPlanResponse.displayName = 'proto.saasphoto.CreateFreeTrialPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.IsValidUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.IsValidUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.IsValidUserResponse.displayName = 'proto.saasphoto.IsValidUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetAccountTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetAccountTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetAccountTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetAccountTypeResponse.displayName = 'proto.saasphoto.GetAccountTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.IsValidSerialNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.IsValidSerialNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.IsValidSerialNumberRequest.displayName = 'proto.saasphoto.IsValidSerialNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.IsValidSerialNumberResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.IsValidSerialNumberResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.IsValidSerialNumberResponse.displayName = 'proto.saasphoto.IsValidSerialNumberResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.CreateFreeTrialAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.CreateFreeTrialAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.CreateFreeTrialAccountRequest.displayName = 'proto.saasphoto.CreateFreeTrialAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.CreateFreeTrialAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.CreateFreeTrialAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.CreateFreeTrialAccountResponse.displayName = 'proto.saasphoto.CreateFreeTrialAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.AddFeedbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.AddFeedbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.AddFeedbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddFeedbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.AddFeedbackRequest}
 */
proto.saasphoto.AddFeedbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.AddFeedbackRequest;
  return proto.saasphoto.AddFeedbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.AddFeedbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.AddFeedbackRequest}
 */
proto.saasphoto.AddFeedbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.AddFeedbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.AddFeedbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.AddFeedbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddFeedbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.saasphoto.AddFeedbackRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AddFeedbackRequest} returns this
 */
proto.saasphoto.AddFeedbackRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.saasphoto.AddFeedbackRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AddFeedbackRequest} returns this
 */
proto.saasphoto.AddFeedbackRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.Feedback.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.Feedback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.Feedback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.Feedback.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    message: jspb.Message.getFieldWithDefault(msg, 5, ""),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.Feedback}
 */
proto.saasphoto.Feedback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.Feedback;
  return proto.saasphoto.Feedback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.Feedback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.Feedback}
 */
proto.saasphoto.Feedback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.Feedback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.Feedback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.Feedback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.Feedback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.saasphoto.Feedback.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.saasphoto.Feedback.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.saasphoto.Feedback.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.saasphoto.Feedback.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.saasphoto.Feedback.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.saasphoto.Feedback.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.saasphoto.Feedback} returns this
*/
proto.saasphoto.Feedback.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.Feedback.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.saasphoto.Feedback.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.saasphoto.Feedback} returns this
*/
proto.saasphoto.Feedback.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.Feedback} returns this
 */
proto.saasphoto.Feedback.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.Feedback.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetFeedbackListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetFeedbackListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetFeedbackListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetFeedbackListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetFeedbackListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.saasphoto.Feedback.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetFeedbackListResponse}
 */
proto.saasphoto.GetFeedbackListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetFeedbackListResponse;
  return proto.saasphoto.GetFeedbackListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetFeedbackListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetFeedbackListResponse}
 */
proto.saasphoto.GetFeedbackListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.Feedback;
      reader.readMessage(value,proto.saasphoto.Feedback.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetFeedbackListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetFeedbackListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetFeedbackListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetFeedbackListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.saasphoto.Feedback.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Feedback list = 1;
 * @return {!Array<!proto.saasphoto.Feedback>}
 */
proto.saasphoto.GetFeedbackListResponse.prototype.getListList = function() {
  return /** @type{!Array<!proto.saasphoto.Feedback>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.saasphoto.Feedback, 1));
};


/**
 * @param {!Array<!proto.saasphoto.Feedback>} value
 * @return {!proto.saasphoto.GetFeedbackListResponse} returns this
*/
proto.saasphoto.GetFeedbackListResponse.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.saasphoto.Feedback=} opt_value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.Feedback}
 */
proto.saasphoto.GetFeedbackListResponse.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.saasphoto.Feedback, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetFeedbackListResponse} returns this
 */
proto.saasphoto.GetFeedbackListResponse.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.CustomerCharge.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.CustomerCharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.CustomerCharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CustomerCharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cardHolder: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creditCard: jspb.Message.getFieldWithDefault(msg, 5, 0),
    receiptUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.CustomerCharge}
 */
proto.saasphoto.CustomerCharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.CustomerCharge;
  return proto.saasphoto.CustomerCharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.CustomerCharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.CustomerCharge}
 */
proto.saasphoto.CustomerCharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardHolder(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreditCard(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiptUrl(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.CustomerCharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.CustomerCharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.CustomerCharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CustomerCharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCardHolder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreditCard();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getReceiptUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.saasphoto.CustomerCharge.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.saasphoto.CustomerCharge.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float price = 3;
 * @return {number}
 */
proto.saasphoto.CustomerCharge.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string card_holder = 4;
 * @return {string}
 */
proto.saasphoto.CustomerCharge.prototype.getCardHolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.setCardHolder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 credit_card = 5;
 * @return {number}
 */
proto.saasphoto.CustomerCharge.prototype.getCreditCard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.setCreditCard = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string receipt_url = 6;
 * @return {string}
 */
proto.saasphoto.CustomerCharge.prototype.getReceiptUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.setReceiptUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.saasphoto.CustomerCharge.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.saasphoto.CustomerCharge} returns this
*/
proto.saasphoto.CustomerCharge.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.CustomerCharge} returns this
 */
proto.saasphoto.CustomerCharge.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.CustomerCharge.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetChargesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetChargesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetChargesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetChargesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetChargesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargesList: jspb.Message.toObjectList(msg.getChargesList(),
    proto.saasphoto.CustomerCharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetChargesResponse}
 */
proto.saasphoto.GetChargesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetChargesResponse;
  return proto.saasphoto.GetChargesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetChargesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetChargesResponse}
 */
proto.saasphoto.GetChargesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.CustomerCharge;
      reader.readMessage(value,proto.saasphoto.CustomerCharge.deserializeBinaryFromReader);
      msg.addCharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetChargesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetChargesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetChargesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetChargesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.saasphoto.CustomerCharge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomerCharge charges = 1;
 * @return {!Array<!proto.saasphoto.CustomerCharge>}
 */
proto.saasphoto.GetChargesResponse.prototype.getChargesList = function() {
  return /** @type{!Array<!proto.saasphoto.CustomerCharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.saasphoto.CustomerCharge, 1));
};


/**
 * @param {!Array<!proto.saasphoto.CustomerCharge>} value
 * @return {!proto.saasphoto.GetChargesResponse} returns this
*/
proto.saasphoto.GetChargesResponse.prototype.setChargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.saasphoto.CustomerCharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.CustomerCharge}
 */
proto.saasphoto.GetChargesResponse.prototype.addCharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.saasphoto.CustomerCharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetChargesResponse} returns this
 */
proto.saasphoto.GetChargesResponse.prototype.clearChargesList = function() {
  return this.setChargesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetLatestUserPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetLatestUserPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetLatestUserPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    plan: (f = msg.getPlan()) && protocol_plan_pb.PlanDetail.toObject(includeInstance, f),
    subscriptionStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiryDate: (f = msg.getExpiryDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    planPricing: (f = msg.getPlanPricing()) && protocol_plan_pb.PlanPricingDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetLatestUserPlanResponse}
 */
proto.saasphoto.GetLatestUserPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetLatestUserPlanResponse;
  return proto.saasphoto.GetLatestUserPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetLatestUserPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetLatestUserPlanResponse}
 */
proto.saasphoto.GetLatestUserPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = new protocol_plan_pb.PlanDetail;
      reader.readMessage(value,protocol_plan_pb.PlanDetail.deserializeBinaryFromReader);
      msg.setPlan(value);
      break;
    case 3:
      var value = /** @type {!proto.saasphoto.SubscriptionStatus} */ (reader.readEnum());
      msg.setSubscriptionStatus(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiryDate(value);
      break;
    case 6:
      var value = new protocol_plan_pb.PlanPricingDetail;
      reader.readMessage(value,protocol_plan_pb.PlanPricingDetail.deserializeBinaryFromReader);
      msg.setPlanPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetLatestUserPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetLatestUserPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetLatestUserPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPlan();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      protocol_plan_pb.PlanDetail.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiryDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlanPricing();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      protocol_plan_pb.PlanPricingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PlanDetail plan = 2;
 * @return {?proto.saasphoto.PlanDetail}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.getPlan = function() {
  return /** @type{?proto.saasphoto.PlanDetail} */ (
    jspb.Message.getWrapperField(this, protocol_plan_pb.PlanDetail, 2));
};


/**
 * @param {?proto.saasphoto.PlanDetail|undefined} value
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
*/
proto.saasphoto.GetLatestUserPlanResponse.prototype.setPlan = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.clearPlan = function() {
  return this.setPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.hasPlan = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SubscriptionStatus subscription_status = 3;
 * @return {!proto.saasphoto.SubscriptionStatus}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.getSubscriptionStatus = function() {
  return /** @type {!proto.saasphoto.SubscriptionStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.saasphoto.SubscriptionStatus} value
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.setSubscriptionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
*/
proto.saasphoto.GetLatestUserPlanResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expiry_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.getExpiryDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
*/
proto.saasphoto.GetLatestUserPlanResponse.prototype.setExpiryDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.clearExpiryDate = function() {
  return this.setExpiryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.hasExpiryDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PlanPricingDetail plan_pricing = 6;
 * @return {?proto.saasphoto.PlanPricingDetail}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.getPlanPricing = function() {
  return /** @type{?proto.saasphoto.PlanPricingDetail} */ (
    jspb.Message.getWrapperField(this, protocol_plan_pb.PlanPricingDetail, 6));
};


/**
 * @param {?proto.saasphoto.PlanPricingDetail|undefined} value
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
*/
proto.saasphoto.GetLatestUserPlanResponse.prototype.setPlanPricing = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.GetLatestUserPlanResponse} returns this
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.clearPlanPricing = function() {
  return this.setPlanPricing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.GetLatestUserPlanResponse.prototype.hasPlanPricing = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.QueryUsedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.QueryUsedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.QueryUsedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.QueryUsedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planStorage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    planBandwidth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    refillBandwidth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    usedBandwidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    usedStorage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    usedRefillBandwidth: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalCredit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    usedCredit: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.QueryUsedResponse}
 */
proto.saasphoto.QueryUsedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.QueryUsedResponse;
  return proto.saasphoto.QueryUsedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.QueryUsedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.QueryUsedResponse}
 */
proto.saasphoto.QueryUsedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlanStorage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPlanBandwidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRefillBandwidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsedBandwidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUsedStorage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUsedRefillBandwidth(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalCredit(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUsedCredit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.QueryUsedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.QueryUsedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.QueryUsedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.QueryUsedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanStorage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPlanBandwidth();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRefillBandwidth();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUsedBandwidth();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUsedStorage();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getUsedRefillBandwidth();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getTotalCredit();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getUsedCredit();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional uint64 plan_storage = 1;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getPlanStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setPlanStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 plan_bandwidth = 2;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getPlanBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setPlanBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 refill_bandwidth = 3;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getRefillBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setRefillBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 used_bandwidth = 4;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getUsedBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setUsedBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 used_storage = 5;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getUsedStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setUsedStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 used_refill_bandwidth = 6;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getUsedRefillBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setUsedRefillBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 total_credit = 7;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getTotalCredit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setTotalCredit = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 used_credit = 8;
 * @return {number}
 */
proto.saasphoto.QueryUsedResponse.prototype.getUsedCredit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.QueryUsedResponse} returns this
 */
proto.saasphoto.QueryUsedResponse.prototype.setUsedCredit = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.SystemNotify.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.SystemNotify.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.SystemNotify} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SystemNotify.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.SystemNotify}
 */
proto.saasphoto.SystemNotify.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.SystemNotify;
  return proto.saasphoto.SystemNotify.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.SystemNotify} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.SystemNotify}
 */
proto.saasphoto.SystemNotify.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.SystemNotify.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.SystemNotify.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.SystemNotify} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SystemNotify.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.saasphoto.SystemNotify.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.SystemNotify} returns this
 */
proto.saasphoto.SystemNotify.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetSystemNotifyListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetSystemNotifyListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetSystemNotifyListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetSystemNotifyListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetSystemNotifyListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemNotifiesList: jspb.Message.toObjectList(msg.getSystemNotifiesList(),
    proto.saasphoto.SystemNotify.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetSystemNotifyListResponse}
 */
proto.saasphoto.GetSystemNotifyListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetSystemNotifyListResponse;
  return proto.saasphoto.GetSystemNotifyListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetSystemNotifyListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetSystemNotifyListResponse}
 */
proto.saasphoto.GetSystemNotifyListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.SystemNotify;
      reader.readMessage(value,proto.saasphoto.SystemNotify.deserializeBinaryFromReader);
      msg.addSystemNotifies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetSystemNotifyListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetSystemNotifyListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetSystemNotifyListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetSystemNotifyListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemNotifiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.saasphoto.SystemNotify.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SystemNotify system_notifies = 1;
 * @return {!Array<!proto.saasphoto.SystemNotify>}
 */
proto.saasphoto.GetSystemNotifyListResponse.prototype.getSystemNotifiesList = function() {
  return /** @type{!Array<!proto.saasphoto.SystemNotify>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.saasphoto.SystemNotify, 1));
};


/**
 * @param {!Array<!proto.saasphoto.SystemNotify>} value
 * @return {!proto.saasphoto.GetSystemNotifyListResponse} returns this
*/
proto.saasphoto.GetSystemNotifyListResponse.prototype.setSystemNotifiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.saasphoto.SystemNotify=} opt_value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.SystemNotify}
 */
proto.saasphoto.GetSystemNotifyListResponse.prototype.addSystemNotifies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.saasphoto.SystemNotify, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetSystemNotifyListResponse} returns this
 */
proto.saasphoto.GetSystemNotifyListResponse.prototype.clearSystemNotifiesList = function() {
  return this.setSystemNotifiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.UpdateDefaultUploadFolderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.UpdateDefaultUploadFolderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.UpdateDefaultUploadFolderRequest}
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.UpdateDefaultUploadFolderRequest;
  return proto.saasphoto.UpdateDefaultUploadFolderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.UpdateDefaultUploadFolderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.UpdateDefaultUploadFolderRequest}
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.UpdateDefaultUploadFolderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.UpdateDefaultUploadFolderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.UpdateDefaultUploadFolderRequest} returns this
 */
proto.saasphoto.UpdateDefaultUploadFolderRequest.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.SubscriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.SubscriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.SubscriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SubscriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_switch: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.SubscriptionRequest}
 */
proto.saasphoto.SubscriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.SubscriptionRequest;
  return proto.saasphoto.SubscriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.SubscriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.SubscriptionRequest}
 */
proto.saasphoto.SubscriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.SubscriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.SubscriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.SubscriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SubscriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwitch();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool switch = 1;
 * @return {boolean}
 */
proto.saasphoto.SubscriptionRequest.prototype.getSwitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.SubscriptionRequest} returns this
 */
proto.saasphoto.SubscriptionRequest.prototype.setSwitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.CreateFreeTrialPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.CreateFreeTrialPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.CreateFreeTrialPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareSerialNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.CreateFreeTrialPlanRequest}
 */
proto.saasphoto.CreateFreeTrialPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.CreateFreeTrialPlanRequest;
  return proto.saasphoto.CreateFreeTrialPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.CreateFreeTrialPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.CreateFreeTrialPlanRequest}
 */
proto.saasphoto.CreateFreeTrialPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.CreateFreeTrialPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.CreateFreeTrialPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.CreateFreeTrialPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string hardware_serial_number = 1;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialPlanRequest.prototype.getHardwareSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialPlanRequest} returns this
 */
proto.saasphoto.CreateFreeTrialPlanRequest.prototype.setHardwareSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.CreateFreeTrialPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.CreateFreeTrialPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.CreateFreeTrialPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.CreateFreeTrialPlanResponse}
 */
proto.saasphoto.CreateFreeTrialPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.CreateFreeTrialPlanResponse;
  return proto.saasphoto.CreateFreeTrialPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.CreateFreeTrialPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.CreateFreeTrialPlanResponse}
 */
proto.saasphoto.CreateFreeTrialPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.saasphoto.CustomerErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.CreateFreeTrialPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.CreateFreeTrialPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.CreateFreeTrialPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CustomerErrorCode error_code = 1;
 * @return {!proto.saasphoto.CustomerErrorCode}
 */
proto.saasphoto.CreateFreeTrialPlanResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.CustomerErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.saasphoto.CustomerErrorCode} value
 * @return {!proto.saasphoto.CreateFreeTrialPlanResponse} returns this
 */
proto.saasphoto.CreateFreeTrialPlanResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.IsValidUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.IsValidUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.IsValidUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.IsValidUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.IsValidUserResponse}
 */
proto.saasphoto.IsValidUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.IsValidUserResponse;
  return proto.saasphoto.IsValidUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.IsValidUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.IsValidUserResponse}
 */
proto.saasphoto.IsValidUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.saasphoto.CustomerErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.IsValidUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.IsValidUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.IsValidUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.IsValidUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CustomerErrorCode error_code = 1;
 * @return {!proto.saasphoto.CustomerErrorCode}
 */
proto.saasphoto.IsValidUserResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.CustomerErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.saasphoto.CustomerErrorCode} value
 * @return {!proto.saasphoto.IsValidUserResponse} returns this
 */
proto.saasphoto.IsValidUserResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetAccountTypeResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetAccountTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetAccountTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetAccountTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetAccountTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extraFeaturesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetAccountTypeResponse}
 */
proto.saasphoto.GetAccountTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetAccountTypeResponse;
  return proto.saasphoto.GetAccountTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetAccountTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetAccountTypeResponse}
 */
proto.saasphoto.GetAccountTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addExtraFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetAccountTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetAccountTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetAccountTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetAccountTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtraFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string account_type = 1;
 * @return {string}
 */
proto.saasphoto.GetAccountTypeResponse.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.GetAccountTypeResponse} returns this
 */
proto.saasphoto.GetAccountTypeResponse.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string extra_features = 2;
 * @return {!Array<string>}
 */
proto.saasphoto.GetAccountTypeResponse.prototype.getExtraFeaturesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.saasphoto.GetAccountTypeResponse} returns this
 */
proto.saasphoto.GetAccountTypeResponse.prototype.setExtraFeaturesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.GetAccountTypeResponse} returns this
 */
proto.saasphoto.GetAccountTypeResponse.prototype.addExtraFeatures = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetAccountTypeResponse} returns this
 */
proto.saasphoto.GetAccountTypeResponse.prototype.clearExtraFeaturesList = function() {
  return this.setExtraFeaturesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.IsValidSerialNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.IsValidSerialNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.IsValidSerialNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.IsValidSerialNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    softwareSerialNumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.IsValidSerialNumberRequest}
 */
proto.saasphoto.IsValidSerialNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.IsValidSerialNumberRequest;
  return proto.saasphoto.IsValidSerialNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.IsValidSerialNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.IsValidSerialNumberRequest}
 */
proto.saasphoto.IsValidSerialNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSoftwareSerialNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.IsValidSerialNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.IsValidSerialNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.IsValidSerialNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.IsValidSerialNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSoftwareSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string software_serial_number = 1;
 * @return {string}
 */
proto.saasphoto.IsValidSerialNumberRequest.prototype.getSoftwareSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.IsValidSerialNumberRequest} returns this
 */
proto.saasphoto.IsValidSerialNumberRequest.prototype.setSoftwareSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.IsValidSerialNumberResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.IsValidSerialNumberResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.IsValidSerialNumberResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.IsValidSerialNumberResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareSerialNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.IsValidSerialNumberResponse}
 */
proto.saasphoto.IsValidSerialNumberResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.IsValidSerialNumberResponse;
  return proto.saasphoto.IsValidSerialNumberResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.IsValidSerialNumberResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.IsValidSerialNumberResponse}
 */
proto.saasphoto.IsValidSerialNumberResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareSerialNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.saasphoto.CustomerErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.IsValidSerialNumberResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.IsValidSerialNumberResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.IsValidSerialNumberResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.IsValidSerialNumberResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string hardware_serial_number = 1;
 * @return {string}
 */
proto.saasphoto.IsValidSerialNumberResponse.prototype.getHardwareSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.IsValidSerialNumberResponse} returns this
 */
proto.saasphoto.IsValidSerialNumberResponse.prototype.setHardwareSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CustomerErrorCode error_code = 2;
 * @return {!proto.saasphoto.CustomerErrorCode}
 */
proto.saasphoto.IsValidSerialNumberResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.CustomerErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.saasphoto.CustomerErrorCode} value
 * @return {!proto.saasphoto.IsValidSerialNumberResponse} returns this
 */
proto.saasphoto.IsValidSerialNumberResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.CreateFreeTrialAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.CreateFreeTrialAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hardwareSerialNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    captcha: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.CreateFreeTrialAccountRequest;
  return proto.saasphoto.CreateFreeTrialAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.CreateFreeTrialAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareSerialNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptcha(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.CreateFreeTrialAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.CreateFreeTrialAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHardwareSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCaptcha();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string company_name = 4;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone_number = 7;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string hardware_serial_number = 8;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getHardwareSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setHardwareSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string captcha = 9;
 * @return {string}
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.getCaptcha = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountRequest} returns this
 */
proto.saasphoto.CreateFreeTrialAccountRequest.prototype.setCaptcha = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.CreateFreeTrialAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.CreateFreeTrialAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.CreateFreeTrialAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.CreateFreeTrialAccountResponse}
 */
proto.saasphoto.CreateFreeTrialAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.CreateFreeTrialAccountResponse;
  return proto.saasphoto.CreateFreeTrialAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.CreateFreeTrialAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.CreateFreeTrialAccountResponse}
 */
proto.saasphoto.CreateFreeTrialAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.saasphoto.CustomerErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.CreateFreeTrialAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.CreateFreeTrialAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.CreateFreeTrialAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateFreeTrialAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CustomerErrorCode error_code = 1;
 * @return {!proto.saasphoto.CustomerErrorCode}
 */
proto.saasphoto.CreateFreeTrialAccountResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.CustomerErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.saasphoto.CustomerErrorCode} value
 * @return {!proto.saasphoto.CreateFreeTrialAccountResponse} returns this
 */
proto.saasphoto.CreateFreeTrialAccountResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.saasphoto.SubscriptionStatus = {
  SUBSCRIPTION_IS_CANCELLED: 0,
  SUBSCRIPTION_IS_RECURRING: 1,
  SUBSCRIPTION_IS_CANCEL_AT_PERIOD_END: 2,
  SUBSCRIPTION_IS_EMPTY: 3,
  SUBSCRIPTION_IS_CUSTOM_PLAN_NO_RECURRING: 4
};

/**
 * @enum {number}
 */
proto.saasphoto.CustomerErrorCode = {
  CUSTOMER_OK: 0,
  CUSTOMER_INVALID_HARDWARE_SERIAL_NUMBER: 1,
  CUSTOMER_HARDWARE_SERIAL_NUMBER_HAS_BEEN_USED: 2,
  CUSTOMER_NOT_ALLOW_CREATE_FREE_TRIAL_PLAN: 3,
  CUSTOMER_HARDWARE_SERIAL_NUMBER_NOT_FOUND: 4,
  CUSTOMER_UNKNOWN: 5,
  CUSTOMER_NO_USER_PLAN: 6,
  CUSTOMER_USER_PLAN_EXPIRED: 7,
  CUSTOMER_BAN_USER: 8,
  CUSTOMER_INVALID_SOFTWARE_SERIAL_NUMBER: 9,
  CUSTOMER_SOFTWARE_SERIAL_NUMBER_HAS_BEEN_USED: 10,
  CUSTOMER_SOFTWARE_SERIAL_NUMBER_NOT_FOUND: 11,
  CUSTOMER_EMAIL_HAS_EXISTED: 12
};

goog.object.extend(exports, proto.saasphoto);
