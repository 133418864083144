import {
    Observable,
    Observer,
    Subject,
    catchError,
    map,
    of,
    retry,
    take,
} from "rxjs"
import { TaskRef, isID, isNestedID } from "../context/task"
import { OfGrpc } from "../grpc"
import { Progress } from "../helper/api"
import {
    DriveErrorCode,
    GetUploadLinkRequestInfo,
    GetUploadLinksRequest,
    GetUploadLinksResponse,
    GetUploadLinksResponseInfo,
} from "../protocol/drive_pb"

import { Empty } from "google-protobuf/google/protobuf/empty_pb"
import { QueryUsedResponse } from "../protocol/customer_pb"
import { UploadError, UploadItem, isCreateDirectory } from "../tasks"

interface IGetUploadFileRequest {
    identity: string
    uploadTo: string[]
}

interface IUploadInfo {
    urlLink: GetUploadLinksResponseInfo
    file: File
}

interface IWork {
    subject$: Subject<IUploadInfo>
    error$: Observer<UploadError | void>
    stopSubTask$: Subject<Progress>
    file: File
    uploadTo: string
    taskID: number
    subTaskID: number
    uploadFileID: string
}

const BYTE_IN_ONE_GIGABYTE = 1073741824

export class UploadFileInOrder {
    private validatedWorks: IWork[]
    private workMap: Map<number, IWork[]>
    private uploadItemByTaskID: Map<
        number,
        { subTaskID: number; items: UploadItem[] }[]
    >
    private sizeOfTaskFilesMap: Map<number, number>
    private currentRoundWorkers: IWork[]
    private identity: string
    private readonly maxUploadSize: number
    private getUploadLinks: OfGrpc<
        GetUploadLinksRequest,
        GetUploadLinksResponse
    >
    private getUsage: OfGrpc<Empty, QueryUsedResponse>
    private remainUploadFiles: number
    private readonly maxItemsToTakeOneTime: number
    private readonly maxItemsToAddInOneTime: number

    constructor({
        concurrently = 0.5, //GIGABYTE
        getUploadLinks,
        getUsage,
    }: {
        concurrently?: number
        getUploadLinks: OfGrpc<GetUploadLinksRequest, GetUploadLinksResponse>
        getUsage: OfGrpc<Empty, QueryUsedResponse>
    }) {
        this.validatedWorks = []
        this.maxUploadSize = concurrently * BYTE_IN_ONE_GIGABYTE
        this.currentRoundWorkers = []
        this.identity = ""
        this.getUploadLinks = getUploadLinks
        this.getUsage = getUsage
        this.remainUploadFiles = 0
        this.maxItemsToTakeOneTime = 50
        this.maxItemsToAddInOneTime = 30
        this.workMap = new Map()
        this.uploadItemByTaskID = new Map()
        this.sizeOfTaskFilesMap = new Map()
    }

    private get isWorking(): boolean {
        return this.currentRoundWorkers.length > 0
    }

    private get isUploadedExceedingAddition(): boolean {
        return (
            this.maxItemsToTakeOneTime - this.currentRoundWorkers.length >=
            this.maxItemsToAddInOneTime
        )
    }

    private get hasWork(): boolean {
        return this.validatedWorks.length > 0
    }

    private getNextWorks(): IWork[] {
        const maxItemsToTake = this.isWorking
            ? Math.min(this.validatedWorks.length, this.maxItemsToAddInOneTime)
            : Math.min(this.validatedWorks.length, this.maxItemsToTakeOneTime)

        let totalSize = 0
        let targetIndex = 0

        while (
            targetIndex < maxItemsToTake &&
            totalSize + this.validatedWorks[targetIndex].file.size <=
                this.maxUploadSize
        ) {
            totalSize += this.validatedWorks[targetIndex].file.size
            targetIndex++
        }
        if (targetIndex === 0 && this.validatedWorks.length > 0)
            return this.validatedWorks.splice(0, 1)
        return this.validatedWorks.splice(0, targetIndex)
    }

    public handlePrepareCancelTask(cancelSubTask$: Observable<TaskRef>): void {
        cancelSubTask$.pipe(take(1)).subscribe((cancelTaskIDInfo: TaskRef) => {
            this.deleteWorkTaskByTaskID(cancelTaskIDInfo)
            this.handleRecalculateSizeOfTaskFilesMap(cancelTaskIDInfo)

            if (!this.isWorking) {
                this.startWorkers()
            }
        })
    }

    public handleUploadedTask(uploadFileID: string): void {
        const task = this.currentRoundWorkers.find(
            (work) => work.uploadFileID === uploadFileID,
        )

        if (!task) return

        this.remainUploadFiles -= 1
        this.currentRoundWorkers = this.currentRoundWorkers.filter(
            (work) => work.uploadFileID !== uploadFileID,
        )

        if (!this.hasWork) return
        if (!this.isWorking || this.isUploadedExceedingAddition) {
            this.startWorkers()
        }
    }

    public setUploadItemByTaskID(items: UploadItem[][], id: number): void {
        this.uploadItemByTaskID.set(
            id,
            items.map((data, index) => {
                return { subTaskID: index, items: data }
            }),
        )
        this.sizeOfTaskFilesMap.set(id, this.countTotalFiles(items))
    }

    private handleStoreWork(work: IWork): void {
        this.workMap.set(work.taskID, [
            ...(this.workMap.get(work.taskID) || []),
            work,
        ])
        this.handleCheckStartWorker(work.taskID)
    }

    private handleCheckStartWorker(workID: number) {
        const currentWorkGroupByTaskID = this.workMap.get(workID)
        const totalFileByTaskID = this.sizeOfTaskFilesMap.get(workID)

        if (
            !currentWorkGroupByTaskID ||
            !totalFileByTaskID ||
            currentWorkGroupByTaskID.length < totalFileByTaskID
        ) {
            return
        }

        this.validateUpload(currentWorkGroupByTaskID).subscribe((res) => {
            if (res !== undefined) {
                //error
                currentWorkGroupByTaskID.forEach((item) => {
                    item.error$.next(res)
                })
                return
            }

            this.remainUploadFiles += currentWorkGroupByTaskID.length

            this.sizeOfTaskFilesMap.delete(workID)
            this.uploadItemByTaskID.delete(workID)

            this.validatedWorks = [
                ...this.validatedWorks,
                ...currentWorkGroupByTaskID,
            ]

            if (this.isWorking) return
            if (
                this.validatedWorks.length >= this.maxItemsToAddInOneTime ||
                this.validatedWorks.length >= this.remainUploadFiles
            ) {
                this.startWorkers()
            }
        })
    }

    private startWorkers(): void {
        if (!this.hasWork) return
        const nextWorks = this.getNextWorks()
        this.currentRoundWorkers = [...this.currentRoundWorkers, ...nextWorks]

        const uploadTo = nextWorks.map((item) => item.uploadTo)
        const request = this.getUploadFileRequest({
            identity: this.identity,
            uploadTo,
        })

        this.getUploadLinks(request as GetUploadLinksRequest)
            .pipe(retry({ count: 10, delay: 500 }))
            .subscribe({
                next: (res) => {
                    const status = res.getStatus()
                    if (status !== DriveErrorCode.DRIVE_OK) {
                        this.handleOnGetUploadLinksError(status, nextWorks)
                        return
                    }
                    const links = res.getLinksList()
                    nextWorks.forEach((item, index) => {
                        item.subject$.next({
                            urlLink: links[index],
                            file: item.file,
                        })
                    })
                },
                error: (error) => {
                    if (error instanceof Error) {
                        this.handleOnGetUploadLinksError(error, nextWorks)
                    }
                },
            })
    }

    private handleOnGetUploadLinksError(e: any, nextWorks: IWork[]): void {
        let errorCode: UploadError | void

        if (e === DriveErrorCode.DRIVE_SIZE_IS_NOT_ENOUGH) {
            errorCode = UploadError.INSUFFICIENT_SPACE
        } else if (e === DriveErrorCode.DRIVE_INVALID_DESTINATION) {
            errorCode = UploadError.DRIVE_INVALID_DESTINATION
        }
        nextWorks.forEach((work) => {
            work.error$.next(errorCode)
            work.stopSubTask$.next({ loaded: 1, total: 1 })
            this.deleteWorkTaskByTaskID([work.taskID, work.subTaskID])
        })
    }

    public storeWorkOperator({
        file,
        identity,
        uploadTo,
        taskID,
        subTaskID,
        uploadFileID,
        error$,
        stopSubTask$,
    }: {
        file: File
        identity: string
        uploadTo: string
        taskID: number
        subTaskID: number
        uploadFileID: string
        error$: Observer<UploadError | void>
        stopSubTask$: Subject<Progress>
    }) {
        return () => {
            let hasStoredWork = false
            return new Observable<IUploadInfo>((subscriber) => {
                if (!hasStoredWork) {
                    const subject$ = new Subject<IUploadInfo>()
                    subject$.pipe(take(1)).subscribe(subscriber)
                    this.identity = identity
                    hasStoredWork = true
                    this.handleStoreWork({
                        subject$,
                        file,
                        uploadTo,
                        taskID,
                        subTaskID,
                        uploadFileID,
                        error$,
                        stopSubTask$,
                    })
                }
            })
        }
    }

    public deleteWorkTaskByTaskID(taskRef: TaskRef): void {
        if (isNestedID(taskRef)) {
            const [taskID, subTaskID] = taskRef
            //index 0=taskID, index 1=subTaskID
            this.currentRoundWorkers = this.currentRoundWorkers.filter(
                (work) => {
                    const isCurrentDeleteWork =
                        work.subTaskID === subTaskID && work.taskID === taskID
                    return !isCurrentDeleteWork
                },
            )

            this.validatedWorks = this.validatedWorks.filter((work) => {
                const isCurrentDeleteWork =
                    work.subTaskID === subTaskID && work.taskID === taskID

                return !isCurrentDeleteWork
            })
        }

        if (isID(taskRef)) {
            //only taskID
            this.currentRoundWorkers = this.currentRoundWorkers.filter(
                (work) => work.taskID !== taskRef,
            )

            this.validatedWorks = this.validatedWorks.filter(
                (work) => work.taskID !== taskRef,
            )
        }

        this.remainUploadFiles =
            this.currentRoundWorkers.length + this.validatedWorks.length
    }

    private handleRecalculateSizeOfTaskFilesMap(taskRef: TaskRef): void {
        if (isNestedID(taskRef)) {
            const [taskID, subTaskID] = taskRef
            const uploadItemByTaskID = this.uploadItemByTaskID.get(taskID)
            if (uploadItemByTaskID !== undefined) {
                const updatedUploadItemByTaskID = uploadItemByTaskID.filter(
                    (item) => item.subTaskID !== subTaskID,
                )
                this.uploadItemByTaskID.set(taskID, updatedUploadItemByTaskID)
                this.sizeOfTaskFilesMap.set(
                    taskID,
                    this.countTotalFiles(
                        updatedUploadItemByTaskID.map((data) => data.items),
                    ),
                )
                this.handleCheckStartWorker(taskID)
            }
        }
    }

    private countTotalFiles(arr: UploadItem[][]): number {
        let total = 0
        arr.forEach((files) => {
            files.forEach((item) => {
                if (!isCreateDirectory(item)) {
                    total += 1
                }
            })
        })
        return total
    }

    private validateUpload(work: IWork[]) {
        const isValid = work.every(
            (w) => w.file.size <= 4 * BYTE_IN_ONE_GIGABYTE,
        )

        if (!isValid) return of(UploadError.EXCEED_UPLOAD_SIZE)

        const totalSize = work.reduce(
            (acc: number, cur) => acc + cur.file.size,
            0,
        )

        return this.getUsage(new Empty()).pipe(
            map((res: QueryUsedResponse) => {
                const planStorage = res.getPlanStorage()
                if (planStorage === 0) {
                    // the user is admin if storage is 0
                    return undefined
                }
                const uploadingSizeTotal = this.currentRoundWorkers.reduce(
                    (acc, item) => {
                        return acc + item.file.size
                    },
                    0,
                )
                const waitingUploadSizeTotal = this.validatedWorks.reduce(
                    (acc, item) => {
                        return acc + item.file.size
                    },
                    0,
                )
                const remain =
                    planStorage -
                    res.getUsedStorage() -
                    uploadingSizeTotal -
                    waitingUploadSizeTotal

                return totalSize > remain
                    ? UploadError.INSUFFICIENT_SPACE
                    : undefined
            }),
            catchError((e) => of(e.message || e)),
        )
    }

    private getUploadFileRequest({
        identity,
        uploadTo,
    }: IGetUploadFileRequest): GetUploadLinksRequest {
        const dataInfoList: GetUploadLinkRequestInfo[] = []
        const getUploadLinksRequest = new GetUploadLinksRequest()
        getUploadLinksRequest.setIdentity(identity)

        uploadTo.forEach((item) => {
            const getUploadLinkRequestInfo = new GetUploadLinkRequestInfo()
            getUploadLinkRequestInfo.setKey(item)
            dataInfoList.push(getUploadLinkRequestInfo)
        })

        return getUploadLinksRequest.setObjectsList(dataInfoList)
    }
}
