/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/manage.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var protocol_customer_pb = require('../protocol/customer_pb.js')

var protocol_plan_pb = require('../protocol/plan_pb.js')

var protocol_histogram_pb = require('../protocol/histogram_pb.js')
const proto = {};
proto.saasphoto = require('./manage_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.ManageClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.ManagePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetPoliciesList>}
 */
const methodDescriptor_Manage_GetPolicies = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetPolicies',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetPoliciesList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPoliciesList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPoliciesList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPoliciesList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getPolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetPolicies',
      request,
      metadata || {},
      methodDescriptor_Manage_GetPolicies,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPoliciesList>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getPolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetPolicies',
      request,
      metadata || {},
      methodDescriptor_Manage_GetPolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetGroupsList>}
 */
const methodDescriptor_Manage_GetGroups = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetGroups',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetGroupsList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetGroupsList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetGroupsList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetGroupsList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetGroups',
      request,
      metadata || {},
      methodDescriptor_Manage_GetGroups,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetGroupsList>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetGroups',
      request,
      metadata || {},
      methodDescriptor_Manage_GetGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.PoliciesList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_AddPolicies = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/AddPolicies',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.PoliciesList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.PoliciesList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.PoliciesList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.addPolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/AddPolicies',
      request,
      metadata || {},
      methodDescriptor_Manage_AddPolicies,
      callback);
};


/**
 * @param {!proto.saasphoto.PoliciesList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.addPolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/AddPolicies',
      request,
      metadata || {},
      methodDescriptor_Manage_AddPolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GroupsList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_AddGroups = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/AddGroups',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GroupsList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.GroupsList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GroupsList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.addGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/AddGroups',
      request,
      metadata || {},
      methodDescriptor_Manage_AddGroups,
      callback);
};


/**
 * @param {!proto.saasphoto.GroupsList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.addGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/AddGroups',
      request,
      metadata || {},
      methodDescriptor_Manage_AddGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.PoliciesList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_RemovePolicies = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/RemovePolicies',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.PoliciesList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.PoliciesList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.PoliciesList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.removePolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/RemovePolicies',
      request,
      metadata || {},
      methodDescriptor_Manage_RemovePolicies,
      callback);
};


/**
 * @param {!proto.saasphoto.PoliciesList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.removePolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/RemovePolicies',
      request,
      metadata || {},
      methodDescriptor_Manage_RemovePolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GroupsList,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_RemoveGroups = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/RemoveGroups',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GroupsList,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.GroupsList} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GroupsList} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.removeGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/RemoveGroups',
      request,
      metadata || {},
      methodDescriptor_Manage_RemoveGroups,
      callback);
};


/**
 * @param {!proto.saasphoto.GroupsList} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.removeGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/RemoveGroups',
      request,
      metadata || {},
      methodDescriptor_Manage_RemoveGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddSystemNotifyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_AddSystemNotify = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/AddSystemNotify',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddSystemNotifyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.AddSystemNotifyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddSystemNotifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.addSystemNotify =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/AddSystemNotify',
      request,
      metadata || {},
      methodDescriptor_Manage_AddSystemNotify,
      callback);
};


/**
 * @param {!proto.saasphoto.AddSystemNotifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.addSystemNotify =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/AddSystemNotify',
      request,
      metadata || {},
      methodDescriptor_Manage_AddSystemNotify);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetSystemNotifiesResponse>}
 */
const methodDescriptor_Manage_GetSystemNotifies = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetSystemNotifies',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetSystemNotifiesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetSystemNotifiesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetSystemNotifiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetSystemNotifiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getSystemNotifies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetSystemNotifies',
      request,
      metadata || {},
      methodDescriptor_Manage_GetSystemNotifies,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetSystemNotifiesResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getSystemNotifies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetSystemNotifies',
      request,
      metadata || {},
      methodDescriptor_Manage_GetSystemNotifies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateSystemNotifiesRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_UpdateSystemNotifies = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/UpdateSystemNotifies',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateSystemNotifiesRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateSystemNotifiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateSystemNotifiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.updateSystemNotifies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/UpdateSystemNotifies',
      request,
      metadata || {},
      methodDescriptor_Manage_UpdateSystemNotifies,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateSystemNotifiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.updateSystemNotifies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/UpdateSystemNotifies',
      request,
      metadata || {},
      methodDescriptor_Manage_UpdateSystemNotifies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.CreateAccountRequest,
 *   !proto.saasphoto.CreateAccountResponse>}
 */
const methodDescriptor_Manage_CreateAccount = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/CreateAccount',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.CreateAccountRequest,
  proto.saasphoto.CreateAccountResponse,
  /**
   * @param {!proto.saasphoto.CreateAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.CreateAccountResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.CreateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.CreateAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.CreateAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.createAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/CreateAccount',
      request,
      metadata || {},
      methodDescriptor_Manage_CreateAccount,
      callback);
};


/**
 * @param {!proto.saasphoto.CreateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.CreateAccountResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.createAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/CreateAccount',
      request,
      metadata || {},
      methodDescriptor_Manage_CreateAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SalesReportRequest,
 *   !proto.saasphoto.SalesReportResponse>}
 */
const methodDescriptor_Manage_SalesReport = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/SalesReport',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SalesReportRequest,
  proto.saasphoto.SalesReportResponse,
  /**
   * @param {!proto.saasphoto.SalesReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.SalesReportResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SalesReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.SalesReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.SalesReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.salesReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/SalesReport',
      request,
      metadata || {},
      methodDescriptor_Manage_SalesReport,
      callback);
};


/**
 * @param {!proto.saasphoto.SalesReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.SalesReportResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.salesReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/SalesReport',
      request,
      metadata || {},
      methodDescriptor_Manage_SalesReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetAgentsResponse>}
 */
const methodDescriptor_Manage_GetAgents = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetAgents',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetAgentsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetAgentsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetAgentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetAgentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getAgents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetAgents',
      request,
      metadata || {},
      methodDescriptor_Manage_GetAgents,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetAgentsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getAgents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetAgents',
      request,
      metadata || {},
      methodDescriptor_Manage_GetAgents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetAccountTypesResponse>}
 */
const methodDescriptor_Manage_GetAccountTypes = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetAccountTypes',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetAccountTypesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetAccountTypesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetAccountTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetAccountTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getAccountTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetAccountTypes',
      request,
      metadata || {},
      methodDescriptor_Manage_GetAccountTypes,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetAccountTypesResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getAccountTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetAccountTypes',
      request,
      metadata || {},
      methodDescriptor_Manage_GetAccountTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SearchRequest,
 *   !proto.saasphoto.SearchResponse>}
 */
const methodDescriptor_Manage_Search = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/Search',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SearchRequest,
  proto.saasphoto.SearchResponse,
  /**
   * @param {!proto.saasphoto.SearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.SearchResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.SearchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.SearchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/Search',
      request,
      metadata || {},
      methodDescriptor_Manage_Search,
      callback);
};


/**
 * @param {!proto.saasphoto.SearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.SearchResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/Search',
      request,
      metadata || {},
      methodDescriptor_Manage_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ChangeAccountTypeRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_ChangeAccountType = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/ChangeAccountType',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ChangeAccountTypeRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ChangeAccountTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ChangeAccountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.changeAccountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/ChangeAccountType',
      request,
      metadata || {},
      methodDescriptor_Manage_ChangeAccountType,
      callback);
};


/**
 * @param {!proto.saasphoto.ChangeAccountTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.changeAccountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/ChangeAccountType',
      request,
      metadata || {},
      methodDescriptor_Manage_ChangeAccountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ChangeUserEmailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_ChangeUserEmail = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/ChangeUserEmail',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ChangeUserEmailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ChangeUserEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ChangeUserEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.changeUserEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/ChangeUserEmail',
      request,
      metadata || {},
      methodDescriptor_Manage_ChangeUserEmail,
      callback);
};


/**
 * @param {!proto.saasphoto.ChangeUserEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.changeUserEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/ChangeUserEmail',
      request,
      metadata || {},
      methodDescriptor_Manage_ChangeUserEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ChangeUserPasswordRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_ChangeUserPassword = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/ChangeUserPassword',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ChangeUserPasswordRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ChangeUserPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ChangeUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.changeUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/ChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_Manage_ChangeUserPassword,
      callback);
};


/**
 * @param {!proto.saasphoto.ChangeUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.changeUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/ChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_Manage_ChangeUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GenerateUserPasswordRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_GenerateUserPassword = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GenerateUserPassword',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GenerateUserPasswordRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.GenerateUserPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GenerateUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.generateUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GenerateUserPassword',
      request,
      metadata || {},
      methodDescriptor_Manage_GenerateUserPassword,
      callback);
};


/**
 * @param {!proto.saasphoto.GenerateUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.generateUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GenerateUserPassword',
      request,
      metadata || {},
      methodDescriptor_Manage_GenerateUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateUserStatusRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_UpdateUserStatus = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/UpdateUserStatus',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateUserStatusRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateUserStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateUserStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.updateUserStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/UpdateUserStatus',
      request,
      metadata || {},
      methodDescriptor_Manage_UpdateUserStatus,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateUserStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.updateUserStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/UpdateUserStatus',
      request,
      metadata || {},
      methodDescriptor_Manage_UpdateUserStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddUserPlanRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_AddUserPlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/AddUserPlan',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddUserPlanRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.AddUserPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddUserPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.addUserPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/AddUserPlan',
      request,
      metadata || {},
      methodDescriptor_Manage_AddUserPlan,
      callback);
};


/**
 * @param {!proto.saasphoto.AddUserPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.addUserPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/AddUserPlan',
      request,
      metadata || {},
      methodDescriptor_Manage_AddUserPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SendSystemNotifyMailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_SendSystemNotifyMail = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/SendSystemNotifyMail',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SendSystemNotifyMailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.SendSystemNotifyMailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SendSystemNotifyMailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.sendSystemNotifyMail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/SendSystemNotifyMail',
      request,
      metadata || {},
      methodDescriptor_Manage_SendSystemNotifyMail,
      callback);
};


/**
 * @param {!proto.saasphoto.SendSystemNotifyMailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.sendSystemNotifyMail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/SendSystemNotifyMail',
      request,
      metadata || {},
      methodDescriptor_Manage_SendSystemNotifyMail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.DeleteSystemNotifyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Manage_DeleteSystemNotify = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/DeleteSystemNotify',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.DeleteSystemNotifyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.DeleteSystemNotifyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.DeleteSystemNotifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.deleteSystemNotify =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/DeleteSystemNotify',
      request,
      metadata || {},
      methodDescriptor_Manage_DeleteSystemNotify,
      callback);
};


/**
 * @param {!proto.saasphoto.DeleteSystemNotifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.deleteSystemNotify =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/DeleteSystemNotify',
      request,
      metadata || {},
      methodDescriptor_Manage_DeleteSystemNotify);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetBandwidthUsagesRequest,
 *   !proto.saasphoto.GetBandwidthUsagesResponse>}
 */
const methodDescriptor_Manage_GetBandwidthUsages = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetBandwidthUsages',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetBandwidthUsagesRequest,
  proto.saasphoto.GetBandwidthUsagesResponse,
  /**
   * @param {!proto.saasphoto.GetBandwidthUsagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetBandwidthUsagesResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetBandwidthUsagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetBandwidthUsagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetBandwidthUsagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getBandwidthUsages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetBandwidthUsages',
      request,
      metadata || {},
      methodDescriptor_Manage_GetBandwidthUsages,
      callback);
};


/**
 * @param {!proto.saasphoto.GetBandwidthUsagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetBandwidthUsagesResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getBandwidthUsages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetBandwidthUsages',
      request,
      metadata || {},
      methodDescriptor_Manage_GetBandwidthUsages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetStorageUsagesResponse>}
 */
const methodDescriptor_Manage_GetStorageUsages = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetStorageUsages',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetStorageUsagesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetStorageUsagesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetStorageUsagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetStorageUsagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getStorageUsages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetStorageUsages',
      request,
      metadata || {},
      methodDescriptor_Manage_GetStorageUsages,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetStorageUsagesResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getStorageUsages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetStorageUsages',
      request,
      metadata || {},
      methodDescriptor_Manage_GetStorageUsages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetUserBandwidthUsagesRequest,
 *   !proto.saasphoto.GetUserBandwidthUsagesResponse>}
 */
const methodDescriptor_Manage_GetUserBandwidthUsages = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetUserBandwidthUsages',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetUserBandwidthUsagesRequest,
  proto.saasphoto.GetUserBandwidthUsagesResponse,
  /**
   * @param {!proto.saasphoto.GetUserBandwidthUsagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetUserBandwidthUsagesResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetUserBandwidthUsagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetUserBandwidthUsagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetUserBandwidthUsagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getUserBandwidthUsages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetUserBandwidthUsages',
      request,
      metadata || {},
      methodDescriptor_Manage_GetUserBandwidthUsages,
      callback);
};


/**
 * @param {!proto.saasphoto.GetUserBandwidthUsagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetUserBandwidthUsagesResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getUserBandwidthUsages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetUserBandwidthUsages',
      request,
      metadata || {},
      methodDescriptor_Manage_GetUserBandwidthUsages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetSubscriptionStatusResponse>}
 */
const methodDescriptor_Manage_GetSubscriptionStatus = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetSubscriptionStatus',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetSubscriptionStatusResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetSubscriptionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetSubscriptionStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetSubscriptionStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getSubscriptionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetSubscriptionStatus',
      request,
      metadata || {},
      methodDescriptor_Manage_GetSubscriptionStatus,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetSubscriptionStatusResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getSubscriptionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetSubscriptionStatus',
      request,
      metadata || {},
      methodDescriptor_Manage_GetSubscriptionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetPaymentStatisticsRequest,
 *   !proto.saasphoto.GetPaymentStatisticsResponse>}
 */
const methodDescriptor_Manage_GetPaymentStatistics = new grpc.web.MethodDescriptor(
  '/saasphoto.Manage/GetPaymentStatistics',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetPaymentStatisticsRequest,
  proto.saasphoto.GetPaymentStatisticsResponse,
  /**
   * @param {!proto.saasphoto.GetPaymentStatisticsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPaymentStatisticsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetPaymentStatisticsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPaymentStatisticsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPaymentStatisticsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ManageClient.prototype.getPaymentStatistics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Manage/GetPaymentStatistics',
      request,
      metadata || {},
      methodDescriptor_Manage_GetPaymentStatistics,
      callback);
};


/**
 * @param {!proto.saasphoto.GetPaymentStatisticsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPaymentStatisticsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ManagePromiseClient.prototype.getPaymentStatistics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Manage/GetPaymentStatistics',
      request,
      metadata || {},
      methodDescriptor_Manage_GetPaymentStatistics);
};


module.exports = proto.saasphoto;

