/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/image_processing.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.saasphoto = require('./image_processing_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.ImageProcessingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.ImageProcessingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.LsProfileResponse>}
 */
const methodDescriptor_ImageProcessing_LsProfile = new grpc.web.MethodDescriptor(
  '/saasphoto.ImageProcessing/LsProfile',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.LsProfileResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.LsProfileResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.LsProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.LsProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ImageProcessingClient.prototype.lsProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.ImageProcessing/LsProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_LsProfile,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.LsProfileResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ImageProcessingPromiseClient.prototype.lsProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.ImageProcessing/LsProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_LsProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.NewProfileRequest,
 *   !proto.saasphoto.NewProfileResponse>}
 */
const methodDescriptor_ImageProcessing_NewProfile = new grpc.web.MethodDescriptor(
  '/saasphoto.ImageProcessing/NewProfile',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.NewProfileRequest,
  proto.saasphoto.NewProfileResponse,
  /**
   * @param {!proto.saasphoto.NewProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.NewProfileResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.NewProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.NewProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.NewProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ImageProcessingClient.prototype.newProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.ImageProcessing/NewProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_NewProfile,
      callback);
};


/**
 * @param {!proto.saasphoto.NewProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.NewProfileResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ImageProcessingPromiseClient.prototype.newProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.ImageProcessing/NewProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_NewProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateProfileRequest,
 *   !proto.saasphoto.UpdateProfileResponse>}
 */
const methodDescriptor_ImageProcessing_UpdateProfile = new grpc.web.MethodDescriptor(
  '/saasphoto.ImageProcessing/UpdateProfile',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateProfileRequest,
  proto.saasphoto.UpdateProfileResponse,
  /**
   * @param {!proto.saasphoto.UpdateProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.UpdateProfileResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.UpdateProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.UpdateProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ImageProcessingClient.prototype.updateProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.ImageProcessing/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_UpdateProfile,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.UpdateProfileResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ImageProcessingPromiseClient.prototype.updateProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.ImageProcessing/UpdateProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_UpdateProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.DeleteProfileRequest,
 *   !proto.saasphoto.DeleteProfileResponse>}
 */
const methodDescriptor_ImageProcessing_DeleteProfile = new grpc.web.MethodDescriptor(
  '/saasphoto.ImageProcessing/DeleteProfile',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.DeleteProfileRequest,
  proto.saasphoto.DeleteProfileResponse,
  /**
   * @param {!proto.saasphoto.DeleteProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.DeleteProfileResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.DeleteProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.DeleteProfileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.DeleteProfileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ImageProcessingClient.prototype.deleteProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.ImageProcessing/DeleteProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_DeleteProfile,
      callback);
};


/**
 * @param {!proto.saasphoto.DeleteProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.DeleteProfileResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ImageProcessingPromiseClient.prototype.deleteProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.ImageProcessing/DeleteProfile',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_DeleteProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.RemoveBackgroundRequest,
 *   !proto.saasphoto.RemoveBackgroundResponse>}
 */
const methodDescriptor_ImageProcessing_RemoveBackground = new grpc.web.MethodDescriptor(
  '/saasphoto.ImageProcessing/RemoveBackground',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.RemoveBackgroundRequest,
  proto.saasphoto.RemoveBackgroundResponse,
  /**
   * @param {!proto.saasphoto.RemoveBackgroundRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.RemoveBackgroundResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.RemoveBackgroundRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.RemoveBackgroundResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.RemoveBackgroundResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ImageProcessingClient.prototype.removeBackground =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.ImageProcessing/RemoveBackground',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_RemoveBackground,
      callback);
};


/**
 * @param {!proto.saasphoto.RemoveBackgroundRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.RemoveBackgroundResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ImageProcessingPromiseClient.prototype.removeBackground =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.ImageProcessing/RemoveBackground',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_RemoveBackground);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetTaskCountRequest,
 *   !proto.saasphoto.GetTaskCountResponse>}
 */
const methodDescriptor_ImageProcessing_GetTaskCount = new grpc.web.MethodDescriptor(
  '/saasphoto.ImageProcessing/GetTaskCount',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetTaskCountRequest,
  proto.saasphoto.GetTaskCountResponse,
  /**
   * @param {!proto.saasphoto.GetTaskCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetTaskCountResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetTaskCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetTaskCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetTaskCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.ImageProcessingClient.prototype.getTaskCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.ImageProcessing/GetTaskCount',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_GetTaskCount,
      callback);
};


/**
 * @param {!proto.saasphoto.GetTaskCountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetTaskCountResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.ImageProcessingPromiseClient.prototype.getTaskCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.ImageProcessing/GetTaskCount',
      request,
      metadata || {},
      methodDescriptor_ImageProcessing_GetTaskCount);
};


module.exports = proto.saasphoto;

