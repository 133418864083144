import { createContext, ReactNode, useEffect, useState } from "react"

interface IImpersonateContext {
    asEmail: string
}

export const ImpersonateContext = createContext<IImpersonateContext>({
    asEmail: "",
})

ImpersonateContext.displayName = "ImpersonateContext"

export function ImpersonateProvider({ children }: { children: ReactNode }) {
    const [initParams, setInitParams] = useState<IImpersonateContext>({
        asEmail: "",
    })
    useEffect(() => {
        let urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        const impersonateSetting = {
            asEmail: params?.asEmail || "",
        }
        if (impersonateSetting.asEmail.length > 0) {
            console.log(`impersonating: email: ${impersonateSetting.asEmail}`)
        }
        setInitParams(impersonateSetting)
    }, [])
    return (
        <ImpersonateContext.Provider value={initParams}>
            {children}
        </ImpersonateContext.Provider>
    )
}
