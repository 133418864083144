import Auth, { CognitoUser } from "@aws-amplify/auth"
import { CognitoUserAttribute } from "amazon-cognito-identity-js"
import { Empty } from "google-protobuf/google/protobuf/empty_pb"
import {
    ReactElement,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import {
    BehaviorSubject,
    EMPTY,
    NEVER,
    Observable,
    Subject,
    combineLatest,
    from,
    iif,
    merge,
    of,
    pipe,
} from "rxjs"
import {
    auditTime,
    catchError,
    filter,
    map,
    mapTo,
    share,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from "rxjs/operators"
import { CustomerErrorCode } from "../protocol/customer_pb"

import { useLocation, useNavigate } from "react-router-dom"
import { Customer, grpc } from "../grpc"

const getEmail = pipe(
    switchMap((u: CognitoUser) =>
        from<Promise<string>>(
            new Promise((res, rej) => {
                u.getUserAttributes((err, attrs?: CognitoUserAttribute[]) => {
                    if (!err && attrs) {
                        const emailAttr = attrs.filter(
                            (a) => a.getName() === "email",
                        )[0]
                        if (emailAttr) {
                            res(emailAttr.getValue())
                        }
                    }
                    rej(new Error("cannot get email"))
                })
            }),
        ),
    ),
)

interface IAuthContext {
    init?: boolean
    login?: boolean
    promptResetPassword?: boolean
    promptUpdateTemporaryPassword?: boolean
    requiredAttributes?: string[]
    email?: string
    accountType?: string
    userAbilityType?: string[]

    logout(): void

    doLogin(email: string, password: string): void
    loginError?: any
    loading?: boolean
    setRememberDevice(remember: boolean): void
    rememberDevice?: boolean
    currentUser?: CognitoUser
    setPromptResetPassword(promptResetPassword: boolean): void
    setEmail(email: string): void
    resetFlags(): void
    getIdToken(): Observable<string>
    currentUser$: Observable<CognitoUser | undefined>
    checkValidUser$: Subject<any>
    refreshUserAbilities(): void
}

export const AuthContext = createContext<IAuthContext>({
    init: false,
    login: false,
    email: undefined,
    currentUser: undefined,
    promptResetPassword: false,
    promptUpdateTemporaryPassword: false,
    requiredAttributes: [],
    accountType: "",
    userAbilityType: [],
    logout: () => {
        throw new Error("context uninitialized")
    },
    doLogin: () => {
        throw new Error("context uninitialized")
    },
    setRememberDevice: () => {
        throw new Error("context uninitialized")
    },
    setPromptResetPassword: () => {
        throw new Error("context uninitialized")
    },
    setEmail: () => {
        throw new Error("context uninitialized")
    },
    resetFlags: () => {
        throw new Error("context uninitialized")
    },
    getIdToken: () => new Observable(),
    checkValidUser$: new Subject<any>(),
    currentUser$: NEVER,
    refreshUserAbilities: () => {
        throw new Error("context uninitialized")
    },
})

AuthContext.displayName = "AuthContext"

function isCognitoUser(x: CognitoUser | any): x is CognitoUser {
    return x && "getUsername" in (x as CognitoUser)
}

const RememberDeviceFlag = "saasphoto:rememberDevice"
enum LoginResult {
    OK,
    Error,
    ResetPassword,
}
interface LoginOK {
    type: LoginResult.OK
    user: CognitoUser | any
}
interface LoginError {
    type: LoginResult.Error
    err: Error
}
interface LoginResetPassword {
    type: LoginResult.ResetPassword
}

function isLoginOK(
    event: LoginOK | LoginResetPassword | LoginError,
): event is LoginOK {
    return event.type === LoginResult.OK
}
function isLoginResetPassword(
    event: LoginOK | LoginResetPassword | LoginError,
): event is LoginResetPassword {
    return event.type === LoginResult.ResetPassword
}
function isLoginError(
    event: LoginOK | LoginResetPassword | LoginError,
): event is LoginError {
    return event.type === LoginResult.Error
}

export function AuthProvider({ children }: { children: ReactElement }) {
    const navigate = useNavigate()
    const location = useLocation()

    const [init, setInit] = useState(false)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const [loginError, setLoginError] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [accountType, setAccountType] = useState("")
    const [userAbilityType, setUserAbilityType] = useState<string[]>([])
    const [currentUser$, setCurrentUser$] =
        useState<Observable<CognitoUser | undefined>>(NEVER)
    const [rememberDeviceState, setRememberDeviceState] = useState(false)
    // user forgots password. reset with verification code
    const [promptResetPassword, setPromptResetPassword] = useState(false)
    // admin create account or resend password. user needs to update temporary password
    const [promptUpdateTemporaryPassword, setPromptUpdateTemporaryPassword] =
        useState(false)
    const [authState, setAuthState] = useState({
        login: false,
        user: undefined,
    })
    // required attributes when update temporary password
    const [requiredAttributes, setRequiredAttributes] = useState<string[]>([])

    const [logout$] = useState(new Subject<void>())
    const [login$] = useState(
        new Subject<{ username: string; password: string }>(),
    )
    const [setRememberDevice$] = useState(new Subject<boolean>())

    const logout = useCallback(() => logout$.next(), [logout$])
    const doLogin = useCallback(
        (username, password) => login$.next({ username, password }),
        [login$],
    )
    const setRememberDevice = useCallback(
        (remember: boolean) => setRememberDevice$.next(remember),
        [setRememberDevice$],
    )
    const resetFlags = useCallback(() => {
        setLoginError({})
        setPromptResetPassword(false)
        setPromptUpdateTemporaryPassword(false)
        setRequiredAttributes([])
    }, [])

    const wrappedGetAccountType = useMemo(
        () => grpc(Customer.getAccountType.bind(Customer)),
        [],
    )

    const [checkValidUser$] = useState(new Subject<any>())
    const wrappedValidCheck = useMemo(
        () => grpc(Customer.isValidUser.bind(Customer)),
        [],
    )

    // make sure to call Auth.currentSession to get token before each request
    // Auth.currentSession will check whether token is valid or not and update it automatically
    const getIdToken = useCallback(
        () =>
            from(Auth.currentSession()).pipe(
                map((session) => session.getIdToken().getJwtToken()),
            ),
        [],
    )

    const refreshUserAbilities = useCallback(() => {
        const accountType$ = getIdToken().pipe(
            switchMap((idtoken) =>
                wrappedGetAccountType(new Empty(), { idtoken }),
            ),
            catchError(() => {
                setInit(true)
                return EMPTY
            }),
        )
        accountType$.subscribe((res) => {
            const userAbilityType = res?.getExtraFeaturesList()
            userAbilityType && setUserAbilityType(userAbilityType)
        })
    }, [getIdToken, wrappedGetAccountType])

    useEffect(() => {
        let legacyChecked = false
        const logoutResult$ = logout$.pipe(
            switchMap(() => from(Auth.signOut()).pipe(mapTo(true))),
        )
        const loginResult$ = login$.pipe(
            switchMap(({ username, password }) =>
                from<Promise<CognitoUser | any>>(
                    Auth.signIn(username, password),
                ).pipe(
                    map((user) => ({ type: LoginResult.OK, user } as LoginOK)),
                    catchError((err) => {
                        // try to retrieve user from legacy DB
                        if (
                            err.code === "UserNotFoundException" &&
                            !legacyChecked
                        ) {
                            Auth.configure({
                                authenticationFlowType: "USER_PASSWORD_AUTH",
                            })
                            login$.next({ username, password })
                            legacyChecked = true
                            return EMPTY
                        } else if (
                            err.code === "PasswordResetRequiredException"
                        ) {
                            return of({
                                type: LoginResult.ResetPassword,
                            } as LoginResetPassword)
                        }
                        return of({
                            type: LoginResult.Error,
                            err,
                        } as LoginError)
                    }),
                ),
            ),
            share<LoginOK | LoginError | LoginResetPassword>(),
        )

        const bye$ = new Subject()
        merge(login$.pipe(mapTo(true)), loginResult$.pipe(mapTo(false)))
            .pipe(takeUntil(bye$))
            .subscribe(setLoading)
        loginResult$
            .pipe(
                filter(isLoginOK),
                map(({ user }) => user),
                filter((user) => user.challengeName),
                takeUntil(bye$),
            )
            .subscribe((user) => {
                setRequiredAttributes(user.challengeParam.requiredAttributes)
                setPromptUpdateTemporaryPassword(true)
            })
        const loginError$ = loginResult$.pipe(
            filter(isLoginError),
            map(({ err }) => err),
        )
        const loginOK$ = loginResult$.pipe(filter(isLoginOK))

        const getCurrentUser$ = from(Auth.currentAuthenticatedUser()).pipe(
            catchError((e) => {
                const msg: string = e.message || e.code || e || ""
                // "The user is not authenticated" means user is not logged in
                msg &&
                    msg !== "The user is not authenticated" &&
                    setLoginError(msg)
                return of(null)
            }),
        )
        const cognitoUser$ = merge(
            loginOK$.pipe(
                map(({ user }) => (isCognitoUser(user) ? user : null)),
            ),
            logoutResult$.pipe(mapTo(null)),
            getCurrentUser$,
        ).pipe(shareReplay(1))
        const loginSuccess$ = loginOK$.pipe(
            filter(({ user }) => !user.challengeName),
        )
        loginSuccess$.pipe(takeUntil(bye$)).subscribe(resetFlags)
        const email$ = merge(
            login$.pipe(map(({ username }) => username)),
            cognitoUser$.pipe(
                switchMap((x) =>
                    iif(
                        () => !!x && !x.challengeName,
                        of(x).pipe(
                            getEmail,
                            catchError((err) => {
                                logout()
                                return EMPTY
                            }),
                        ),
                        of(undefined),
                    ),
                ),
            ),
        )
        loginResult$
            .pipe(
                filter(isLoginResetPassword),
                withLatestFrom(email$),
                takeUntil(bye$),
            )
            .subscribe(([, email]) => {
                setEmail(email)
                setPromptResetPassword(true)
            })
        const isLogin$ = cognitoUser$.pipe(
            map((user) => !!user && !user.challengeName),
        )

        email$
            .pipe(takeUntil(bye$))
            .subscribe((email) => email && setEmail(email))
        combineLatest({
            login: isLogin$.pipe(startWith(false)),
            user: cognitoUser$.pipe(startWith(undefined)),
        }).subscribe(setAuthState)
        loginError$.pipe(takeUntil(bye$)).subscribe(setLoginError)

        const localStorageRemember$ = new Observable<boolean>((observer) => {
            observer.next(
                window.localStorage.getItem(RememberDeviceFlag) !== "0",
            )
            observer.complete()
        })
        const remember$ = merge(
            localStorageRemember$,
            setRememberDevice$.pipe(
                tap((x) =>
                    window.localStorage.setItem(
                        RememberDeviceFlag,
                        x ? "1" : "0",
                    ),
                ),
            ),
        )

        remember$.pipe(takeUntil(bye$)).subscribe(setRememberDeviceState)

        loginOK$
            .pipe(filter(isCognitoUser), withLatestFrom(remember$))
            .subscribe(([user, remember]) => {
                if (remember) {
                    user.setDeviceStatusRemembered({
                        onFailure: console.log,
                        onSuccess: () => {
                            /* noop */
                        },
                    })
                } else {
                    user.setDeviceStatusNotRemembered({
                        onFailure: console.log,
                        onSuccess: () => {
                            /* noop */
                        },
                    })
                }
            })

        const accountType$ = getIdToken().pipe(
            switchMap((idtoken) =>
                wrappedGetAccountType(new Empty(), { idtoken }),
            ),
            catchError(() => {
                setInit(true)
                return EMPTY
            }),
            takeUntil(bye$),
        )
        accountType$.subscribe((res) => {
            const accounType = res?.getAccountType()
            accounType && setAccountType(accounType)
            setInit(true)
        })
        accountType$.subscribe((res) => {
            const userAbilityType = res?.getExtraFeaturesList()
            userAbilityType && setUserAbilityType(userAbilityType)
        })

        const currentUser$ = new BehaviorSubject<CognitoUser | undefined>(
            undefined,
        )
        cognitoUser$.pipe(takeUntil(bye$)).subscribe(currentUser$)
        setCurrentUser$(currentUser$)

        return () => {
            bye$.next(undefined)
        }
    }, [
        login$,
        logout$,
        setRememberDevice$,
        getIdToken,
        resetFlags,
        wrappedGetAccountType,
        logout,
    ])

    useEffect(() => {
        const subscription = checkValidUser$
            .pipe(
                auditTime(500),
                switchMap((m) =>
                    wrappedValidCheck(new Empty(), m).pipe(
                        catchError(() => EMPTY),
                    ),
                ),
                map((x) => x.getErrorCode()),
            )
            .subscribe((code) => {
                if (
                    code === CustomerErrorCode.CUSTOMER_NO_USER_PLAN ||
                    code === CustomerErrorCode.CUSTOMER_USER_PLAN_EXPIRED
                ) {
                    location.pathname !== "/p/select-plan" &&
                        navigate("/p/select-plan")
                } else if (code === CustomerErrorCode.CUSTOMER_BAN_USER) {
                    navigate("/banned")
                    setInit(true)
                } else {
                    console.error(`customer error code:${code}`)
                }
            })
        return () => subscription.unsubscribe()
    }, [checkValidUser$, wrappedValidCheck, navigate, location.pathname])

    const value = useMemo(
        () => ({
            init,
            login: authState.login,
            email,
            accountType,
            userAbilityType,
            logout,
            doLogin,
            loginError,
            loading,
            currentUser: authState.user,
            promptResetPassword,
            promptUpdateTemporaryPassword,
            requiredAttributes,
            setRememberDevice,
            rememberDevice: rememberDeviceState,
            setPromptResetPassword,
            setEmail,
            resetFlags,
            getIdToken,
            checkValidUser$,
            currentUser$,
            refreshUserAbilities,
        }),
        [
            init,
            authState.login,
            authState.user,
            email,
            accountType,
            userAbilityType,
            logout,
            doLogin,
            loginError,
            loading,
            promptResetPassword,
            promptUpdateTemporaryPassword,
            requiredAttributes,
            setRememberDevice,
            rememberDeviceState,
            resetFlags,
            getIdToken,
            checkValidUser$,
            currentUser$,
            refreshUserAbilities,
        ],
    )

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
export const AuthConsumer = AuthContext.Consumer
