export enum HttpKey {
    REMOVE_IMAGE_BACKGROUND,
}
export enum UserAbilityType {
    USE_CREDIT = "use_credit",
    USE_ORTERY_DIRECT_3 = "ortery_direct_3",
}
export enum HistoryType {
    Undo,
    Redo,
}
export enum SettingButton {
    Save,
    SaveAs,
    ExitFullscreen,
    RevertAllChange,
    Fullscreen,
    OpenInNewBrowser,
    Redo,
    Undo,
    RollbackSetting,
    DowloadImage,
}
