/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/user_settings.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.saasphoto = require('./user_settings_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.UserSettingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.UserSettingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.QuerySettingResponse>}
 */
const methodDescriptor_UserSetting_QueryUserSetting = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/QueryUserSetting',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.QuerySettingResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.QuerySettingResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.QuerySettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.QuerySettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.queryUserSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryUserSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryUserSetting,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.QuerySettingResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.queryUserSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryUserSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryUserSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.QueryDarkModeResponse>}
 */
const methodDescriptor_UserSetting_QueryDarkMode = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/QueryDarkMode',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.QueryDarkModeResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.QueryDarkModeResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.QueryDarkModeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.QueryDarkModeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.queryDarkMode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryDarkMode',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryDarkMode,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.QueryDarkModeResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.queryDarkMode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryDarkMode',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryDarkMode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.QueryLanguageResponse>}
 */
const methodDescriptor_UserSetting_QueryLanguage = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/QueryLanguage',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.QueryLanguageResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.QueryLanguageResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.QueryLanguageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.QueryLanguageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.queryLanguage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryLanguage',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryLanguage,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.QueryLanguageResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.queryLanguage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryLanguage',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryLanguage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateSettingRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdateUserSetting = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdateUserSetting',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateSettingRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updateUserSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateUserSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateUserSetting,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updateUserSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateUserSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateUserSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateLanguageRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdateLanguage = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdateLanguage',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateLanguageRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateLanguageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateLanguageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updateLanguage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateLanguage',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateLanguage,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateLanguageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updateLanguage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateLanguage',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateLanguage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateDarkModeRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdateDarkMode = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdateDarkMode',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateDarkModeRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateDarkModeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateDarkModeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updateDarkMode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateDarkMode',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateDarkMode,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateDarkModeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updateDarkMode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateDarkMode',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateDarkMode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.QueryUserEmailResponse>}
 */
const methodDescriptor_UserSetting_QueryUserEmail = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/QueryUserEmail',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.QueryUserEmailResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.QueryUserEmailResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.QueryUserEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.QueryUserEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.queryUserEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryUserEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryUserEmail,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.QueryUserEmailResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.queryUserEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryUserEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryUserEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateReceiptRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdateReceipt = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdateReceipt',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateReceiptRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updateReceipt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateReceipt',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateReceipt,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updateReceipt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateReceipt',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateReceipt);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateStatisticalRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdateStatistical = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdateStatistical',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateStatisticalRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateStatisticalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateStatisticalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updateStatistical =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateStatistical',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateStatistical,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateStatisticalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updateStatistical =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateStatistical',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateStatistical);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddUserEmailRequest,
 *   !proto.saasphoto.UserEmail>}
 */
const methodDescriptor_UserSetting_AddUserEmail = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/AddUserEmail',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddUserEmailRequest,
  proto.saasphoto.UserEmail,
  /**
   * @param {!proto.saasphoto.AddUserEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.UserEmail.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddUserEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.UserEmail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.UserEmail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.addUserEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/AddUserEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_AddUserEmail,
      callback);
};


/**
 * @param {!proto.saasphoto.AddUserEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.UserEmail>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.addUserEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/AddUserEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_AddUserEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ResendVerifyEmailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_ResendVerifyEmail = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/ResendVerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ResendVerifyEmailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ResendVerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ResendVerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.resendVerifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/ResendVerifyEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_ResendVerifyEmail,
      callback);
};


/**
 * @param {!proto.saasphoto.ResendVerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.resendVerifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/ResendVerifyEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_ResendVerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdatePrimaryUserEmailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdatePrimaryUserEmail = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdatePrimaryUserEmail',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdatePrimaryUserEmailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdatePrimaryUserEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdatePrimaryUserEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updatePrimaryUserEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdatePrimaryUserEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdatePrimaryUserEmail,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdatePrimaryUserEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updatePrimaryUserEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdatePrimaryUserEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdatePrimaryUserEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.VerifyRequest,
 *   !proto.saasphoto.VerifyResponse>}
 */
const methodDescriptor_UserSetting_VerifyEmail = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.VerifyRequest,
  proto.saasphoto.VerifyResponse,
  /**
   * @param {!proto.saasphoto.VerifyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.VerifyResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.VerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.VerifyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.VerifyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_VerifyEmail,
      callback);
};


/**
 * @param {!proto.saasphoto.VerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.VerifyResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_UserSetting_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.VerifyRequest,
 *   !proto.saasphoto.VerifyResponse>}
 */
const methodDescriptor_UserSetting_VerifySignup = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/VerifySignup',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.VerifyRequest,
  proto.saasphoto.VerifyResponse,
  /**
   * @param {!proto.saasphoto.VerifyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.VerifyResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.VerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.VerifyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.VerifyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.verifySignup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/VerifySignup',
      request,
      metadata || {},
      methodDescriptor_UserSetting_VerifySignup,
      callback);
};


/**
 * @param {!proto.saasphoto.VerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.VerifyResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.verifySignup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/VerifySignup',
      request,
      metadata || {},
      methodDescriptor_UserSetting_VerifySignup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.QueryOrteryDirectSettingResponse>}
 */
const methodDescriptor_UserSetting_QueryOrteryDirectSetting = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/QueryOrteryDirectSetting',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.QueryOrteryDirectSettingResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.QueryOrteryDirectSettingResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.QueryOrteryDirectSettingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.QueryOrteryDirectSettingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.queryOrteryDirectSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryOrteryDirectSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryOrteryDirectSetting,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.QueryOrteryDirectSettingResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.queryOrteryDirectSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/QueryOrteryDirectSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_QueryOrteryDirectSetting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateOrteryDirectSettingRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserSetting_UpdateOrteryDirectSetting = new grpc.web.MethodDescriptor(
  '/saasphoto.UserSetting/UpdateOrteryDirectSetting',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateOrteryDirectSettingRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateOrteryDirectSettingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateOrteryDirectSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.UserSettingClient.prototype.updateOrteryDirectSetting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateOrteryDirectSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateOrteryDirectSetting,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateOrteryDirectSettingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.UserSettingPromiseClient.prototype.updateOrteryDirectSetting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.UserSetting/UpdateOrteryDirectSetting',
      request,
      metadata || {},
      methodDescriptor_UserSetting_UpdateOrteryDirectSetting);
};


module.exports = proto.saasphoto;

