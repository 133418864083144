import React from "react"
// eslint-disable-next-line
import { Route, Routes } from "react-router-dom"
import { basename } from "../path-util"
import TitleRoute from "../scenes/drive/components/TitleRoute"
import { capitalize } from "@material-ui/core/utils"

interface ITitleChangerProps {
    validPaths?: string[]
}

export default function TitleChanger({ validPaths = [] }: ITitleChangerProps) {
    return (
        <Routes>
            <Route
                path={"/@my-drive/:prefix/*"}
                element={
                    <TitleRoute
                        path={"/@my-drive/:prefix/*"}
                        mapper={(prefix) =>
                            `${
                                prefix
                                    ? decodeURIComponent(basename(prefix)) +
                                      " - "
                                    : ""
                            }@my-drive - SAAS Photo`
                        }
                    />
                }
            />
            <Route
                path="/plan/invoice"
                element={
                    <TitleRoute
                        path="/plan/invoice"
                        mapper={() => "Invoice - Plan - SAAS Photo"}
                    />
                }
            />
            {validPaths.map((p) => (
                <Route
                    key={p}
                    path={`/${p}`}
                    element={
                        <TitleRoute
                            path={`/${p}`}
                            mapper={() =>
                                `${p
                                    .split("-")
                                    .map(capitalize)
                                    .join(" ")} - SAAS Photo`
                            }
                        />
                    }
                />
            ))}
            <Route
                path="/plan/invoice"
                element={<TitleRoute mapper={() => "SAAS Photo"} />}
            />
        </Routes>
    )
}
