import { defineMessages } from "react-intl"

const toastMessage = defineMessages({
    undo: {
        id: "toast.undo",
        defaultMessage: "Undo",
    },
    deleteFile: {
        id: "toast.delete.file",
        defaultMessage: "Deleted 「{source}」",
    },
    copyFile: {
        id: "toast.copy.file",
        defaultMessage: "Copied 「{source}」 to 「{target}」",
    },
    restoreFile: {
        id: "toast.restore.file",
        defaultMessage: "Restored",
    },
    moveFile: {
        id: "toast.move.file",
        defaultMessage: "Moved 「{source}」 to 「{target}」",
    },
    copyToClipboard: {
        id: "toast.copy.to.clipboard",
        defaultMessage: "{item} copied to clipboard",
    },
    link: {
        id: "toast.link",
        defaultMessage: "link",
    },
    allLink: {
        id: "toast.all.link",
        defaultMessage: "all links",
    },
    copyQR: {
        id: "toast.copy.qr",
        defaultMessage: "Copied QR Code",
    },
    multipleFile: {
        id: "toast.file.multiple",
        defaultMessage: "{count} items",
    },
    internetDisconnected: {
        id: "toast.internet.disconnected",
        defaultMessage: "Internet disconnected",
    },
    internetConnected: {
        id: "toast.internet.connected",
        defaultMessage: "Internet connected",
    },
    serverBusy: {
        id: "toast.server.busy",
        defaultMessage:
            "Servers are busy, please try again. If the problem persists, please contact us.",
    },
    serverError: {
        id: "toast.server.error",
        defaultMessage: "Server error",
    },
    codeSent: {
        id: "toast.code.sent",
        defaultMessage: "The verification code is sent",
    },
    confirmLinkSent: {
        id: "toast.confirm.link.sent",
        defaultMessage: "The confirmation email is sent",
    },
    passwordUpdated: {
        id: "toast.password.updated",
        defaultMessage: "Password has been changed successfully!",
    },
    closeUserSuccess: {
        id: "toast.close.user.success",
        defaultMessage: "The user account is closed successfully",
    },
    getUsageFail: {
        id: "toast.get.usage.fail",
        defaultMessage: "Unable to get usage temporarily",
    },
    updateUserEmail: {
        id: "toast.update.user.email.success",
        defaultMessage: "Mailbox updated successfully",
    },
    updateUserAccountType: {
        id: "toast.update.user.account.type.success",
        defaultMessage: "User permissions successfully updated",
    },
    sentNewPassword: {
        id: "toast.send.new.password",
        defaultMessage: "The new password is sent",
    },
    success: {
        id: "success",
        defaultMessage: "Success",
    },
    syncProjectSuccess: {
        id: "syncProject.success",
        defaultMessage: "Project has been successfully synchronized",
    },
    syncProjectError: {
        id: "syncProject.error",
        defaultMessage: "Failed to synchronize project",
    },
})

export default toastMessage
