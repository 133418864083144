import React, { useContext, useEffect, useState } from "react"
import { IntlProvider } from "react-intl"
import { LangContext } from "../context/lang"
import useObservableLatest from "../hooks/useObservableLatest"
import loadLocaleData, { translationData } from "../locale"

type PromiseResult<T> = T extends Promise<infer U> ? U : never

function hasTranslation(lang: string): lang is keyof typeof translationData {
    return lang in translationData
}

interface IRenderWithReactIntl {
    children: React.ReactNode
}

export default function RenderWithReactIntl({
    children,
}: IRenderWithReactIntl) {
    const { lang$ } = useContext(LangContext)
    const [locale, setLocale] = useState("en")
    const [messages, setMessage] =
        useState<PromiseResult<ReturnType<typeof loadLocaleData>>>()
    const lang = useObservableLatest(lang$)

    useEffect(() => {
        if (lang && hasTranslation(lang)) {
            loadLocaleData(lang).then((message) => {
                setMessage(message)
                setLocale(lang)
            })
        } else {
            setMessage(undefined)
            setLocale("en")
        }
    }, [lang])

    return (
        <IntlProvider messages={messages} locale={locale}>
            {children}
        </IntlProvider>
    )
}
