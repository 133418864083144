import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from "react"
import { Dialog } from "../dialogs"

export const DialogContext = createContext({
    dialog: Dialog.NONE,
    setDialog: (() => {}) as Dispatch<SetStateAction<Dialog>>,
    closeDialog: () => {},
})

DialogContext.displayName = "DialogContext"

export function DialogProvider({ children }: { children: ReactElement }) {
    const [dialog, setDialog] = useState<Dialog>(Dialog.NONE)

    const closeDialog = useCallback(() => setDialog(Dialog.NONE), [])

    const value = useMemo(
        () => ({
            dialog,
            setDialog,
            closeDialog,
        }),
        [dialog, closeDialog],
    )

    return (
        <DialogContext.Provider value={value}>
            {children}
        </DialogContext.Provider>
    )
}

export const DialogConsumer = DialogContext.Consumer
