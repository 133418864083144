import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import logo from "../images/saasphoto.png"
import FakeMaterialBox from "./FakeMaterialBox"

const useStyles = makeStyles(({ breakpoints, palette }) => ({
    background: {
        padding: "0 !important",
        backgroundColor: palette.background.default,
    },
    wrapper: {
        [breakpoints.down("sm")]: {
            width: "90%",
        },
    },
    logo: {
        width: "100%",
        height: "auto",
        maxWidth: "300px",
        maxHeight: "60px",
    },
    iconHint: {
        marginTop: "13vh",
        width: "100%",
        maxWidth: "501px",
        height: "auto",
        maxHeight: "301px",
    },
    textHint: {
        "&>span,div": {
            lineHeight: "34px",
            textAlign: "center",
        },
    },
    hintTitle: {
        fontSize: "20px",
        color: "#20ab78",
    },
    hintContent: {
        fontSize: "18px",
        color: "#7a7e81",
        marginTop: "18px",
        minHeight: "68px",
        whiteSpace: "pre-wrap",
    },
    actionBtn: {
        "& > div": {
            gap: "10px",
            display: "flex",
            flexWrap: "wrap",
        },
        "& button,a": {
            width: "200px",
            minWidth: "105px",
            height: "50px",
            borderRadius: "2px",
            fontSize: "18px",
            lineHeight: "34px",
            padding: 0,
            cursor: "pointer",
            outline: "none",
            textTransform: "none",
        },
    },
}))

interface IStatusLayout {
    title: string
    description: string | JSX.Element
    image: string
    buttonComponents?: JSX.Element[]
}
export default function StatusLayout({
    title,
    description,
    image,
    buttonComponents,
}: IStatusLayout) {
    const classes = useStyles()
    return (
        <FakeMaterialBox
            className={`${classes.background}`}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            width="100%"
            overflow="hidden auto"
            pt="64px"
            pb="60px"
        >
            <FakeMaterialBox
                className={classes.wrapper}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="60%"
                pt="20px"
                pb="20px"
            >
                <img src={logo} alt="saasphoto icon" className={classes.logo} />
                <img src={image} alt="hint icon" className={classes.iconHint} />
                <FakeMaterialBox
                    className={classes.textHint}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt="2vh"
                >
                    <Typography component="span" className={classes.hintTitle}>
                        {title}
                    </Typography>
                    <Typography component="div" className={classes.hintContent}>
                        {description}
                    </Typography>
                </FakeMaterialBox>
                <FakeMaterialBox
                    display="flex"
                    flexDirection="row"
                    className={classes.actionBtn}
                    gridGap="16px"
                    flexWrap="wrap"
                    justifyContent="center"
                    mt="40px"
                    mb="0"
                >
                    {buttonComponents?.map((c, index) => (
                        <FakeMaterialBox key={index}>{c}</FakeMaterialBox>
                    ))}
                </FakeMaterialBox>
            </FakeMaterialBox>
        </FakeMaterialBox>
    )
}
