import { ReactNode } from "react"
import {
    IndexRouteProps,
    LayoutRouteProps,
    Navigate,
    PathRouteProps,
    RouteProps,
} from "react-router-dom"
import useAccountType from "../hooks/useAccountType"
import useLogin from "../hooks/useLogin"
type IMemorizedProtectedAdminRouteProps = (
    | PathRouteProps
    | LayoutRouteProps
    | IndexRouteProps
) & {
    login?: boolean
    accountType?: string
    element: ReactNode
}

const MemorizedProtectedAdminRoute = ({
    login,
    accountType,
    element,
    ...rest
}: IMemorizedProtectedAdminRouteProps) => {
    if (!login) return <Navigate to="/l/login" />

    if (
        accountType === "agent" ||
        accountType === "admin" ||
        accountType === "sales"
    ) {
        return <>{element}</>
    }

    return <Navigate to="/" />
}

export default function ProtectedAdminRoute(props: RouteProps) {
    const { element } = props
    const login = useLogin()
    const accountType = useAccountType()
    return (
        <MemorizedProtectedAdminRoute
            login={login}
            element={element}
            accountType={accountType}
            {...props}
        />
    )
}
