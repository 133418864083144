import { Empty } from "google-protobuf/google/protobuf/empty_pb"
import {
    ReactElement,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { EMPTY, Observable, Subject, from, merge } from "rxjs"
import {
    catchError,
    filter,
    finalize,
    groupBy,
    map,
    mapTo,
    mergeMap,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from "rxjs/operators"
import { Customer } from "../grpc"
import useAuthGRPC from "../hooks/useAuthGRPC"
import { QueryUsedResponse } from "../protocol/customer_pb"

import { TaskContext, TaskState } from "./task"

export const UpdateAccountInfoContext = createContext({
    loading: false,
    error: false,
    queryUsedData: {
        usedBandwidth: 0,
        totalBandwidth: 0,
        usedStorage: 0,
        totalStorage: 0,
        usedFillBandwidth: 0,
        totalFillBandwidth: 0,
        credit: 0,
        usedCredit: 0,
    },
    update: () => {},
})

UpdateAccountInfoContext.displayName = "UpdateAccountInfoContext"

export function UpdateAccountInfoProviderContextContainer({
    children,
}: {
    children: ReactElement
}) {
    const getUsage = useAuthGRPC(Customer.queryUsed, Customer)
    return useMemo(
        () => (
            <UpdateAccountInfoProvider
                children={children}
                getUsage={getUsage}
            />
        ),
        [getUsage, children],
    )
}

export function UpdateAccountInfoProvider({
    children,
    getUsage,
}: {
    children: ReactElement
    getUsage: (request: Empty, metadata?: any) => Observable<QueryUsedResponse>
}) {
    const [update$] = useState(new Subject<void>())
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [queryUsedData, setQueryUsedData] = useState({
        usedBandwidth: 0,
        totalBandwidth: 0,
        usedStorage: 0,
        totalStorage: 0,
        usedFillBandwidth: 0,
        totalFillBandwidth: 0,
        credit: 0,
        usedCredit: 0,
    })
    const { tasks$ } = useContext(TaskContext)

    const update = useCallback(() => {
        update$.next()
    }, [update$])

    useEffect(() => {
        const bye$ = new Subject()

        merge(
            update$,
            tasks$.pipe(
                mergeMap((x) => from(x)),
                groupBy((x) => x.id),
                mergeMap((x) =>
                    x.pipe(
                        filter(
                            (x) =>
                                x.state !== TaskState.Pending &&
                                x.state !== TaskState.Running,
                        ),
                        take(1),
                        mapTo(undefined),
                    ),
                ),
            ),
        )
            .pipe(
                // init
                startWith(undefined),
                tap(() => {
                    setLoading(true)
                    setError(false)
                }),
                switchMap(() =>
                    getUsage(new Empty()).pipe(
                        catchError(() => {
                            setError(true)
                            setLoading(false)
                            return EMPTY
                        }),
                        map((res) => ({
                            usedBandwidth: res.getUsedBandwidth(),
                            totalBandwidth: res.getPlanBandwidth(),
                            usedStorage: res.getUsedStorage(),
                            totalStorage: res.getPlanStorage(),
                            usedFillBandwidth: res.getUsedRefillBandwidth(),
                            totalFillBandwidth: res.getRefillBandwidth(),
                            credit: res.getTotalCredit(),
                            usedCredit: res.getUsedCredit(),
                        })),
                        finalize(() => setLoading(false)),
                        takeUntil(bye$),
                    ),
                ),
                takeUntil(bye$),
            )
            .subscribe(setQueryUsedData)

        return () => bye$.next(undefined)
    }, [getUsage, update$, tasks$])

    const value = useMemo(
        () => ({
            loading,
            error,
            queryUsedData,
            update,
        }),
        [loading, error, queryUsedData, update],
    )

    return (
        <UpdateAccountInfoContext.Provider value={value}>
            {children}
        </UpdateAccountInfoContext.Provider>
    )
}
export const UpdateAccountInfoConsumer = UpdateAccountInfoContext.Consumer
