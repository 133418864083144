/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/plan.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.saasphoto = require('./plan_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.PlanClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.PlanPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetPlansResponse>}
 */
const methodDescriptor_Plan_GetPlans = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/GetPlans',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetPlansResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPlansResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPlansResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPlansResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.getPlans =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/GetPlans',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlans,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPlansResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.getPlans =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/GetPlans',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlans);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddPlanRequest,
 *   !proto.saasphoto.PlanDetail>}
 */
const methodDescriptor_Plan_AddPlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/AddPlan',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddPlanRequest,
  proto.saasphoto.PlanDetail,
  /**
   * @param {!proto.saasphoto.AddPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PlanDetail.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PlanDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PlanDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.addPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/AddPlan',
      request,
      metadata || {},
      methodDescriptor_Plan_AddPlan,
      callback);
};


/**
 * @param {!proto.saasphoto.AddPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PlanDetail>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.addPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/AddPlan',
      request,
      metadata || {},
      methodDescriptor_Plan_AddPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ModifyPlanRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plan_ModifyPlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/ModifyPlan',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ModifyPlanRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ModifyPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ModifyPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.modifyPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/ModifyPlan',
      request,
      metadata || {},
      methodDescriptor_Plan_ModifyPlan,
      callback);
};


/**
 * @param {!proto.saasphoto.ModifyPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.modifyPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/ModifyPlan',
      request,
      metadata || {},
      methodDescriptor_Plan_ModifyPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.DeletePlanRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plan_DeletePlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/DeletePlan',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.DeletePlanRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.DeletePlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.DeletePlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.deletePlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/DeletePlan',
      request,
      metadata || {},
      methodDescriptor_Plan_DeletePlan,
      callback);
};


/**
 * @param {!proto.saasphoto.DeletePlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.deletePlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/DeletePlan',
      request,
      metadata || {},
      methodDescriptor_Plan_DeletePlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetPlanPricingsRequest,
 *   !proto.saasphoto.GetPlanPricingsResponse>}
 */
const methodDescriptor_Plan_GetPlanPricings = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/GetPlanPricings',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetPlanPricingsRequest,
  proto.saasphoto.GetPlanPricingsResponse,
  /**
   * @param {!proto.saasphoto.GetPlanPricingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPlanPricingsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetPlanPricingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPlanPricingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPlanPricingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.getPlanPricings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/GetPlanPricings',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlanPricings,
      callback);
};


/**
 * @param {!proto.saasphoto.GetPlanPricingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPlanPricingsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.getPlanPricings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/GetPlanPricings',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlanPricings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddPlanPricingRequest,
 *   !proto.saasphoto.PlanPricingDetail>}
 */
const methodDescriptor_Plan_AddPlanPricing = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/AddPlanPricing',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddPlanPricingRequest,
  proto.saasphoto.PlanPricingDetail,
  /**
   * @param {!proto.saasphoto.AddPlanPricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PlanPricingDetail.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddPlanPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PlanPricingDetail)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PlanPricingDetail>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.addPlanPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/AddPlanPricing',
      request,
      metadata || {},
      methodDescriptor_Plan_AddPlanPricing,
      callback);
};


/**
 * @param {!proto.saasphoto.AddPlanPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PlanPricingDetail>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.addPlanPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/AddPlanPricing',
      request,
      metadata || {},
      methodDescriptor_Plan_AddPlanPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ActivePlanPricingRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Plan_ActivePlanPricing = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/ActivePlanPricing',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ActivePlanPricingRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ActivePlanPricingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ActivePlanPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.activePlanPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/ActivePlanPricing',
      request,
      metadata || {},
      methodDescriptor_Plan_ActivePlanPricing,
      callback);
};


/**
 * @param {!proto.saasphoto.ActivePlanPricingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.activePlanPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/ActivePlanPricing',
      request,
      metadata || {},
      methodDescriptor_Plan_ActivePlanPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddPlanCustomRequest,
 *   !proto.saasphoto.AddPlanCustomResponse>}
 */
const methodDescriptor_Plan_AddPlanCustom = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/AddPlanCustom',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddPlanCustomRequest,
  proto.saasphoto.AddPlanCustomResponse,
  /**
   * @param {!proto.saasphoto.AddPlanCustomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.AddPlanCustomResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddPlanCustomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.AddPlanCustomResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.AddPlanCustomResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.addPlanCustom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/AddPlanCustom',
      request,
      metadata || {},
      methodDescriptor_Plan_AddPlanCustom,
      callback);
};


/**
 * @param {!proto.saasphoto.AddPlanCustomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.AddPlanCustomResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.addPlanCustom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/AddPlanCustom',
      request,
      metadata || {},
      methodDescriptor_Plan_AddPlanCustom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetPlanCustomRequest,
 *   !proto.saasphoto.GetPlanCustomResponse>}
 */
const methodDescriptor_Plan_GetPlanCustom = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/GetPlanCustom',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetPlanCustomRequest,
  proto.saasphoto.GetPlanCustomResponse,
  /**
   * @param {!proto.saasphoto.GetPlanCustomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPlanCustomResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetPlanCustomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPlanCustomResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPlanCustomResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.getPlanCustom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/GetPlanCustom',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlanCustom,
      callback);
};


/**
 * @param {!proto.saasphoto.GetPlanCustomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPlanCustomResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.getPlanCustom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/GetPlanCustom',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlanCustom);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetPlanCustomsResponse>}
 */
const methodDescriptor_Plan_GetPlanCustoms = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/GetPlanCustoms',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetPlanCustomsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPlanCustomsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPlanCustomsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPlanCustomsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.getPlanCustoms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/GetPlanCustoms',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlanCustoms,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPlanCustomsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.getPlanCustoms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/GetPlanCustoms',
      request,
      metadata || {},
      methodDescriptor_Plan_GetPlanCustoms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ActivePlanCustomRequest,
 *   !proto.saasphoto.ActivePlanCustomResponse>}
 */
const methodDescriptor_Plan_ActivePlanCustom = new grpc.web.MethodDescriptor(
  '/saasphoto.Plan/ActivePlanCustom',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ActivePlanCustomRequest,
  proto.saasphoto.ActivePlanCustomResponse,
  /**
   * @param {!proto.saasphoto.ActivePlanCustomRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.ActivePlanCustomResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ActivePlanCustomRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.ActivePlanCustomResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.ActivePlanCustomResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PlanClient.prototype.activePlanCustom =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Plan/ActivePlanCustom',
      request,
      metadata || {},
      methodDescriptor_Plan_ActivePlanCustom,
      callback);
};


/**
 * @param {!proto.saasphoto.ActivePlanCustomRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.ActivePlanCustomResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PlanPromiseClient.prototype.activePlanCustom =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Plan/ActivePlanCustom',
      request,
      metadata || {},
      methodDescriptor_Plan_ActivePlanCustom);
};


module.exports = proto.saasphoto;

