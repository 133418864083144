import { VALID_REMOVE_IMAGE_BACKGROUND_EXTENSION } from "../constants"
import { IFile } from "../models/IFile"
import { RemoveBackgroundRequest } from "../protocol/image_processing_pb"

export { UploadFileInOrder } from "./uploadInOrder"

export function getRemoveBackgroundImageSavePaths(
    sourcePaths: string[],
    timestamp: number,
) {
    let targetPaths: string[] = []
    sourcePaths.forEach((path) => {
        if (path.slice(-1) === "/") {
            targetPaths.push(path.slice(0, -1) + "_" + timestamp + "/")
            return
        }
        const arr = path.split(".")
        if (
            arr.length > 0 &&
            VALID_REMOVE_IMAGE_BACKGROUND_EXTENSION.includes(
                arr[arr.length - 1].toLowerCase(),
            )
        ) {
            targetPaths.push(
                arr.slice(0, -1).join(".") + "_" + timestamp + ".png",
            )
            return
        }
    })
    return targetPaths
}

export const getValidRemoveImageBackgroundFiles = (files: IFile[]) => {
    return files.filter((file: IFile) => {
        const fileExtension = file.key?.split(".").pop()?.toLowerCase()
        return (
            file.isDir ||
            (fileExtension &&
                VALID_REMOVE_IMAGE_BACKGROUND_EXTENSION.includes(fileExtension))
        )
    })
}
export const getRemoveBackgroundRequest = (
    selectedFiles: IFile[],
    timestamp: number,
    isExecutionAllowed: boolean,
) => {
    const request = new RemoveBackgroundRequest()
    const sourcePaths = getValidRemoveImageBackgroundFiles(selectedFiles).map(
        (f) => f.key,
    )
    request.setKeysList(sourcePaths)
    request.setTimestamp(timestamp)
    request.setIsExecutionAllowed(isExecutionAllowed)
    return request
}
