import { Observable } from "rxjs"
import { useEffect, useState } from "react"

// Subscribe the given observable and return the latest emitted event as the value
export default function useObservableLatest<E>(
    state$: Observable<E>,
): E | undefined {
    const [state, setState] = useState<E>()
    useEffect(() => {
        const sub = state$.subscribe(setState)
        return () => sub.unsubscribe()
    }, [state$])
    return state
}
