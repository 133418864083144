import { ClientReadableStream, Metadata } from "grpc-web"
import { Observable } from "rxjs"
import { AnalyticsClient } from "./protocol/analytics_grpc_web_pb"
import { AnimationClient } from "./protocol/animation_grpc_web_pb"
import { CustomerClient } from "./protocol/customer_grpc_web_pb"
import { DriveClient } from "./protocol/drive_grpc_web_pb"
import { EventsClient } from "./protocol/events_grpc_web_pb"
import { HistogramClient } from "./protocol/histogram_grpc_web_pb"
import { ImageProcessingClient } from "./protocol/image_processing_grpc_web_pb"
import { ManageClient } from "./protocol/manage_grpc_web_pb"
import { PaymentClient } from "./protocol/payment_grpc_web_pb"
import { PlanClient } from "./protocol/plan_grpc_web_pb"
import { UserSettingClient } from "./protocol/user_settings_grpc_web_pb"

export type UnaryCallback<T, R> = (
    request: T,
    metadata: Metadata,
    callback: (err: Error, response: R) => void,
) => ClientReadableStream<R>
export type OfGrpc<T, R> = (
    request: T,
    metadata?: { [key: string]: string },
) => Observable<R>

export const grpc = <T, R>(callback: UnaryCallback<T, R>) => {
    return (request: T, metadata?: { [key: string]: string }) =>
        new Observable<R>((s) => {
            const ctx = callback(
                request,
                metadata ? metadata : {},
                (err, response) => {
                    if (err) {
                        s.error(err)
                        return
                    }
                    s.next(response)
                    s.complete()
                },
            )
            return () => ctx.cancel()
        })
}

if (!process.env.REACT_APP_GRPC_BASE) {
    throw new Error("api base not set")
}
export const Drive = new DriveClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)
export const Analytic = new AnalyticsClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)
export const Customer = new CustomerClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)

export const Histogram = new HistogramClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)

export const UserSetting = new UserSettingClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)

export const Payment = new PaymentClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)

export const Plan = new PlanClient(process.env.REACT_APP_GRPC_BASE, null, null)

export const Manage = new ManageClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)

export const Events = new EventsClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)

export const ImageProcessing = new ImageProcessingClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)
export const Animations = new AnimationClient(
    process.env.REACT_APP_GRPC_BASE,
    null,
    null,
)
