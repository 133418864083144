import { Auth } from "@aws-amplify/auth"
import { PubSub } from "@aws-amplify/pubsub"
import {
    createContext,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { useIntl } from "react-intl"
import {
    combineLatest,
    concat,
    EMPTY,
    from,
    merge,
    Observable,
    Subject,
} from "rxjs"
import {
    ignoreElements,
    map,
    scan,
    share,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    withLatestFrom,
} from "rxjs/operators"
import { AuthContext } from "../context/auth"
import { ToastContext } from "../context/toast"
import { Customer, Drive, Events, ImageProcessing } from "../grpc"
import useAuthGRPC from "../hooks/useAuthGRPC"
import { IFile } from "../models/IFile"
import { AuthorizeRequest } from "../protocol/events_pb"
import {
    ArchiveResult,
    deleteProcessor,
    DownloadIntent,
    IDeleteProcess,
    initRemoveBackgroundImageProcessor,
    IremoveBackgroundImageProcess,
    isCreateDirectory,
    newDownloadProcessor,
    newUploadProcessor,
    UploadIntent,
    UploadItem,
} from "../tasks"
import { TaskType } from "../TaskType"
import { wrapZenObservable } from "../zenObservableWrapper"

interface ITaskContext {
    upload(files: UploadItem[][], prefix?: string): void

    upload$: Subject<UploadIntent>

    download({
        targets,
        prefix,
        onComplete,
        onCompleteCompress,
    }: DownloadIntent): void

    cancel(taskRef: TaskRef): void

    remove(taskRef: TaskRef): void

    tasks: UITaskStatus[]
    tasks$: Observable<UITaskStatus[]>
    newPath$: Observable<string>
    conflict$: Observable<Conflict>
    resolve: ({ type, taskRef, file }: Resolve) => void
    setResolve: (taskRef: number) => void
    conflictFiles: Conflict[]
    taskUploadingPathMemo: TaskUploadingPathMemo

    removeBackgroundImageProcess(
        selectedFiles: IFile[],
        done$: Subject<{ timestamp: number }>,
        error$: Subject<{ timestamp: number; statusCode?: number }>,
    ): void
    handleDeleteFiles({
        fileIds,
        path,
    }: {
        fileIds: string[]
        path: string
    }): void
    completeDropFile$: Subject<string[]>
    deletingFileIds: string[]
    isUploading: boolean
}

export const TaskContext = createContext<ITaskContext>({
    upload: () => {},
    upload$: new Subject<any>(),
    completeDropFile$: new Subject<string[]>(),
    deletingFileIds: [],
    download: () => {},
    removeBackgroundImageProcess: () => {},
    handleDeleteFiles: () => {},
    cancel: () => {},
    remove: () => {},
    tasks: [],
    tasks$: new Observable(),
    newPath$: new Observable(),
    conflict$: new Observable(),
    resolve: () => {},
    setResolve: () => {},
    conflictFiles: [],
    taskUploadingPathMemo: {},
    isUploading: false,
})

TaskContext.displayName = "TaskContext"

export interface UITaskStatus {
    id: number
    type: TaskType
    state: TaskState
    progress: number
    error: any
    path?: string
    compress?: number
    prefix?: string
}

interface GroupedTaskCount {
    running: number
    finished: number
}

interface TaskUploadingPathMemo {
    [key: string]: TaskUploadingPathMemoValue
}

type TaskUploadingPathMemoValue = {
    taskID: number
    progress: number
    state: TaskState
}

export interface GroupedTaskStatus<C extends UITaskStatus>
    extends UITaskStatus {
    count: GroupedTaskCount
    children: C[]
}

export interface GroupedTransferTaskStatus<C extends TransferTaskStatus>
    extends TransferTaskStatus {
    count: GroupedTaskCount
    children: C[]
}

export interface TransferTaskStatus extends UITaskStatus {
    path: string
    removeBackgroundPath?: string //remove background used
    isClickable?: boolean //remove background used
    compress?: number
    isDir: boolean
}

export function isTransferTaskStatus(x: UITaskStatus): x is TransferTaskStatus {
    return (x as TransferTaskStatus).path !== undefined
}

export function isGroupedTask(
    x: UITaskStatus,
): x is GroupedTaskStatus<UITaskStatus> {
    return (
        (x as GroupedTaskStatus<UITaskStatus>).children !== undefined &&
        (x as GroupedTaskStatus<UITaskStatus>).children.length !== 0
    )
}

export function stateScan<T>(seed: T) {
    return scan<Partial<T>, T>((acc, c) => ({ ...acc, ...c }), seed)
}

export enum TaskState {
    Pending,
    Running,
    Finished,
    Error,
    Cancelled,
    PartialFailure,
}

export enum ConflictResolveType {
    Overwrite,
    Keepboth,
    Cancel,
}

export interface Conflict {
    taskRef: TaskRef
    filePath: string
    file: UploadItem
}

export interface Resolve {
    type: ConflictResolveType
    taskRef: TaskRef
    file: UploadItem
}

export interface ResolveSubjects {
    [key: number]: Subject<Resolve>
}

export type NestedID = number[]
export type TaskRef = NestedID | number

export function isNestedID(ref: TaskRef): ref is NestedID {
    return ref instanceof Array
}

export function isID(ref: TaskRef): ref is number {
    return !isNestedID(ref)
}

// use state for collect fast and continuous output data from rxjs subscribe will block react re-render
// so use static variable collect all conflict files before re-render
let conflictFilesCollector: Conflict[] = []

export function TaskProvider({ children }: { children: ReactElement }) {
    const intl = useIntl()
    const [tasks, setTasks] = useState<UITaskStatus[]>([])

    const [initTaskList, setInitTaskList] = useState<
        GroupedTransferTaskStatus<TransferTaskStatus>[]
    >([])
    const [taskUploadingPathMemo, setTaskUploadingPathMemo] =
        useState<TaskUploadingPathMemo>({})
    const [remove$] = useState(new Subject<TaskRef>())
    const [cancel$] = useState(new Subject<TaskRef>())
    const toastContextValue = useContext(ToastContext)
    const { currentUser$ } = useContext(AuthContext)
    // tasks
    const [upload$] = useState(new Subject<UploadIntent>())
    const [download$] = useState(new Subject<DownloadIntent>())
    const [removeBackgroundImageProcess$] = useState(
        new Subject<IremoveBackgroundImageProcess>(),
    )
    const [completeDropFile$] = useState(new Subject<string[]>())
    const [deleteProcess$] = useState(new Subject<IDeleteProcess>())
    const [deletingFileIds, setDeletingFileIds] = useState<string[]>([])

    // expose
    const [tasks$] = useState(new Subject<UITaskStatus[]>())
    const [newPath$] = useState(new Subject<string>())
    const [conflict$] = useState(new Subject<Conflict>())
    const [resolves$, setResolves$] = useState<ResolveSubjects>({})
    const [conflictFiles, setConflictFiles] = useState<Conflict[]>([])
    const isUploading = useMemo(() => {
        return tasks.some((task) => {
            return (
                task.type === TaskType.UPLOAD &&
                task.state === TaskState.Running
            )
        })
    }, [tasks])
    const conflict = useCallback(
        ({ taskRef, filePath, file }: Conflict): void => {
            conflict$.next({ taskRef, filePath, file })
        },
        [conflict$],
    )

    const resolve = useCallback(
        ({ type, taskRef, file }: Resolve): void => {
            if (
                Object.keys(resolves$).length > 0 &&
                resolves$[taskRef as number]
            ) {
                resolves$[taskRef as number].next({ type, taskRef, file })
                conflictFilesCollector = conflictFilesCollector.slice(1)
                setConflictFiles(conflictFiles.slice(1))
                delete resolves$[taskRef as number]
                if (Object.keys(resolves$).length === 0) {
                    conflictFilesCollector = []
                    setConflictFiles([])
                }
            }
        },
        [resolves$, conflictFiles],
    )

    const setResolve = useCallback(
        (taskRef: number) => {
            if (!resolves$[taskRef]) {
                resolves$[taskRef] = new Subject<Resolve>()
                setResolves$(resolves$)
            }
        },
        [resolves$],
    )

    const cancel = useCallback(
        (id: TaskRef) => {
            cancel$.next(id)
        },
        [cancel$],
    )

    const remove = useCallback(
        (taskRef: TaskRef) => {
            remove$.next(taskRef)
        },
        [remove$],
    )

    const download = useCallback(
        ({
            targets,
            prefix,
            onComplete,
            onCompleteCompress,
        }: {
            targets: IFile[]
            prefix?: string
            onComplete?: () => void
            onCompleteCompress?: (filename: string) => void
        }) => {
            download$.next({ targets, prefix, onComplete, onCompleteCompress })
        },
        [download$],
    )

    const upload = useCallback(
        (uploadAttempts: UploadItem[][], prefix?: string) => {
            upload$.next({
                items: uploadAttempts,
                prefix,
            })
        },
        [upload$],
    )
    const removeBackgroundImageProcess = useCallback(
        (
            selectedFiles: IFile[],
            done$: Subject<{ timestamp: number }>,
            error$: Subject<{ timestamp: number; statusCode?: number }>,
        ) => {
            removeBackgroundImageProcess$.next({ selectedFiles, done$, error$ })
        },
        [removeBackgroundImageProcess$],
    )

    const handleDeleteFiles = useCallback(
        ({ fileIds, path }: { fileIds: string[]; path: string }) => {
            setDeletingFileIds((prev) => [...prev, ...fileIds])
            deleteProcess$.next({ fileIds, path })
        },
        [deleteProcess$],
    )

    useEffect(() => {
        const bye$ = new Subject()
        conflict$.pipe(takeUntil(bye$)).subscribe((x) => {
            setResolve(x.taskRef as number)
            if (!isCreateDirectory(x.file)) {
                const isDir = x.filePath.includes("/")
                if (isDir) {
                    // only replace the folder name because some conflict of files/folders under the same folder
                    x.filePath = x.filePath.slice(0, x.filePath.indexOf("/"))
                }
            }
            if (
                !conflictFilesCollector.find(
                    (file) => file.filePath === x.filePath,
                )
            ) {
                conflictFilesCollector.push(x)
                setConflictFiles([...conflictFilesCollector])
            }
        })
    }, [conflict$, setResolve])

    const getUsage = useAuthGRPC(Customer.queryUsed, Customer)
    const getUploadLinks = useAuthGRPC(Drive.getUploadLinks, Drive)
    const getArchive = useAuthGRPC(Drive.archive, Drive)
    const drop = useAuthGRPC(Drive.drop, Drive)
    const getNewName = useAuthGRPC(Drive.resolveName, Drive)
    const mkdir = useAuthGRPC(Drive.mkdir, Drive)
    const isExists = useAuthGRPC(Drive.isExists, Drive)
    const authorize = useAuthGRPC(Events.authorize, Events)
    const getTaskCount = useAuthGRPC(
        ImageProcessing.getTaskCount,
        ImageProcessing,
    )
    const removeBackgroundImages = useAuthGRPC(
        ImageProcessing.removeBackground,
        ImageProcessing,
    )

    // 將 initTaskList 取出上傳路徑記錄在 taskUploadingPathMemo
    useEffect(() => {
        initTaskList.forEach((info) => {
            const modifedPrefix = info.prefix === "/" ? "" : info.prefix
            if (info.hasOwnProperty("children")) {
                const newObj: {
                    [key: string]: TaskUploadingPathMemoValue
                } = {}
                info.children.forEach((item: any) => {
                    const isDirAddString = item.isDir ? "/" : ""
                    newObj[modifedPrefix + item.path + isDirAddString] = {
                        taskID: info.id,
                        progress: item.progress,
                        state: item.state,
                    }
                })
                setTaskUploadingPathMemo((prevState) => {
                    const newUploadingPathMemo = { ...prevState, ...newObj }
                    let updatePathMemo: {
                        [key: string]: TaskUploadingPathMemoValue
                    } = {}
                    for (const [key, value] of Object.entries(
                        newUploadingPathMemo,
                    )) {
                        if (
                            value.progress !== 1 &&
                            value.state === TaskState.Running
                        ) {
                            updatePathMemo[key] = value
                        }
                    }
                    return updatePathMemo
                })
                return
            }

            setTaskUploadingPathMemo((prevState) => {
                const isDirAddString = info.isDir ? "/" : ""
                const newObj = { ...prevState }
                const newPath = modifedPrefix + info.path + isDirAddString
                newObj[newPath] = {
                    taskID: info.id,
                    progress: info.progress,
                    state: info.state,
                }
                return newObj
            })
        })
    }, [initTaskList])

    useEffect(() => {
        const bye$ = new Subject<void>()
        const nextID$ = merge(
            upload$,
            download$,
            removeBackgroundImageProcess$,
            deleteProcess$,
        ).pipe(scan((acc) => acc + 1, 0))

        const identityID$ = currentUser$.pipe(
            switchMap((user) =>
                !!user
                    ? from(Auth.currentCredentials()).pipe(
                          map(({ identityId }) => identityId),
                      )
                    : EMPTY,
            ),
        )

        function subscribeNotification(topicSuffix: string) {
            return identityID$.pipe(
                switchMap((identityId) => {
                    const request = new AuthorizeRequest()
                    request.setIdentityId(identityId)
                    return concat(
                        authorize(request).pipe(ignoreElements()),
                        wrapZenObservable(
                            PubSub.subscribe(`${identityId}/${topicSuffix}`),
                        ),
                    )
                }),
                map((event) => {
                    if (
                        typeof event.value === "object" &&
                        event.value !== null
                    ) {
                        const result = {
                            ...event.value,
                            progress: event.value.progress || 0,
                            taskID: event.value.taskID || "",
                        } as ArchiveResult
                        return result
                    }
                    return {
                        downloadLink: "",
                        ok: false,
                        id: "",
                        progress: 0,
                        taskID: "",
                    }
                }),

                shareReplay({ windowTime: 1000, refCount: true }),
            )
        }

        const archiveResult$ = subscribeNotification("archiving")
        // subscribe to make the observable hot
        archiveResult$.pipe(takeUntil(bye$)).subscribe()
        const uploadedEvents$ = subscribeNotification("uploaded")
        uploadedEvents$.pipe(takeUntil(bye$)).subscribe()
        const progressResult$ = subscribeNotification("archiving/progress")

        const {
            errors$,
            tasks$: uploadTasks$,
            newPaths$: uploadNewPath$,
        } = newUploadProcessor({
            getUsage,
            getUploadLinks,
            getNewName,
            mkdir,
            isExists,
            request$: upload$.pipe(
                withLatestFrom(nextID$),
                map(([request, id]) => ({ ...request, id })),
            ),
            remove$,
            cancel$,
            conflict,
            resolves$,
            uploaded$: uploadedEvents$,
            identity$: identityID$,
            intl,
        })

        const innerTasks$: Observable<
            | UITaskStatus[]
            | never[]
            | TransferTaskStatus[]
            | GroupedTaskStatus<TransferTaskStatus>[]
        > = combineLatest([
            uploadTasks$.pipe(startWith([])),
            newDownloadProcessor({
                getArchive,
                request$: download$.pipe(
                    withLatestFrom(nextID$),
                    map(([request, id]) => ({ ...request, id })),
                ),
                remove$,
                cancel$,
                progressResult$,
                archiveResult$,
            }).pipe(startWith([])),
            initRemoveBackgroundImageProcessor({
                request$: removeBackgroundImageProcess$.pipe(
                    withLatestFrom(nextID$),
                    map(([request, id]) => ({ ...request, id })),
                ),
                getTaskCount,
                removeBackgroundImages,
                remove$,
            }).pipe(startWith([])),
            deleteProcessor({
                drop,
                setDeletingFileIds,
                completeDropFile$,
                request$: deleteProcess$.pipe(
                    withLatestFrom(nextID$),
                    map(([request, id]) => ({ ...request, id })),
                ),
                remove$,
                cancel$,
            }).pipe(startWith([])),
        ]).pipe(
            map((tasks) => tasks.reduce((acc, c) => [...acc, ...c])),
            share(),
        )

        // 將上傳的檔案 state 為 1 (剛上傳) 記錄在 initTaskList
        innerTasks$
            .pipe(
                takeUntil(bye$),
                map((item) =>
                    item.filter(
                        (data: any) =>
                            data.state === 1 &&
                            data.type !== TaskType.REMOVE_IMAGE_BACKGROUND,
                    ),
                ),
            )
            .subscribe(
                (item) =>
                    item.length &&
                    setInitTaskList(
                        item as GroupedTransferTaskStatus<TransferTaskStatus>[],
                    ),
            )

        innerTasks$.pipe(takeUntil(bye$)).subscribe(setTasks)

        errors$.pipe(takeUntil(bye$)).subscribe(toastContextValue.display)

        // expose tasks observable to the context
        innerTasks$.pipe(takeUntil(bye$)).subscribe(tasks$.next.bind(tasks$))

        uploadNewPath$
            .pipe(takeUntil(bye$))
            .subscribe(newPath$.next.bind(newPath$))

        // 將上傳的檔案 state 為 2 (完成上傳) state 為 4 (中止上傳) 且 id 相同的 在 taskUploadingPathMemo 刪除
        // 這個 innerTasks$ pipe 一定要擺在最下面
        innerTasks$
            .pipe(
                takeUntil(bye$),
                map((item) =>
                    item.filter(
                        (data: any) => data.state === 2 || data.state === 4,
                    ),
                ),
            )
            .subscribe((taskList) => {
                let removeObj: { [key: string]: number }
                removeObj = {}
                taskList.length &&
                    taskList.forEach((task) => {
                        const modifedPrefix =
                            task.prefix === "/" ? "" : task.prefix
                        task.hasOwnProperty("children")
                            ? (
                                  task as GroupedTaskStatus<TransferTaskStatus>
                              ).children.forEach((info: TransferTaskStatus) => {
                                  const isDirAddString = info.isDir ? "/" : ""
                                  return (removeObj[
                                      modifedPrefix + info.path + isDirAddString
                                  ] = task.id)
                              })
                            : (() => {
                                  const isDirAddString = (
                                      task as TransferTaskStatus
                                  ).isDir
                                      ? "/"
                                      : ""
                                  removeObj[
                                      modifedPrefix +
                                          (task as TransferTaskStatus).path +
                                          isDirAddString
                                  ] = task.id
                              })()
                    })

                const removeKeyArray = Object.keys(removeObj)
                const removeObjLength = Object.keys(removeObj).length
                setTaskUploadingPathMemo((prev) => {
                    for (let x = 0; x < removeObjLength; x++) {
                        const removeKey = removeKeyArray[x]
                        removeKey in prev &&
                            prev[removeKey].taskID === removeObj[removeKey] &&
                            delete prev[removeKeyArray[x]]
                    }
                    return prev
                })
            })

        return () => bye$.next()
    }, [
        setInitTaskList,
        getUsage,
        getArchive,
        getNewName,
        authorize,
        cancel$,
        download$,
        remove$,
        upload$,
        tasks$,
        intl,
        toastContextValue.display,
        mkdir,
        newPath$,
        conflict,
        conflict$,
        isExists,
        resolves$,
        currentUser$,
        getUploadLinks,
        removeBackgroundImageProcess$,
        getTaskCount,
        removeBackgroundImages,
        deleteProcess$,
        drop,
        completeDropFile$,
    ])

    const value = useMemo(
        () => ({
            taskUploadingPathMemo,
            upload,
            upload$,
            removeBackgroundImageProcess: removeBackgroundImageProcess,
            cancel,
            remove,
            download,
            tasks,
            tasks$,
            newPath$,
            conflict$,
            resolve,
            setResolve,
            conflictFiles,
            isUploading,
            handleDeleteFiles,
            completeDropFile$,
            deletingFileIds,
        }),
        [
            taskUploadingPathMemo,
            upload,
            upload$,
            removeBackgroundImageProcess,
            cancel,
            remove,
            download,
            tasks,
            tasks$,
            newPath$,
            conflict$,
            resolve,
            setResolve,
            conflictFiles,
            isUploading,
            handleDeleteFiles,
            completeDropFile$,
            deletingFileIds,
        ],
    )

    return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
}
