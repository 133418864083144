import Amplify, { generateRandomString } from "@aws-amplify/core"
import "ie-array-find-polyfill"
import React from "react"
import ReactBreakpoints from "react-breakpoints"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { breakpoints } from "./breakpoints"
import AppSwitch from "./containers/AppSwitch"
import { LangProvider } from "./context/lang"
import { AWSIoTProvider } from "@aws-amplify/pubsub"
import { ImpersonateProvider } from "./context/impersonate"

Amplify.addPluggable(
    new AWSIoTProvider({
        clientId: `spa-${generateRandomString().substr(0, 6)}`,
        aws_pubsub_region: process.env.REACT_APP_IOT_REGION,
        aws_pubsub_endpoint: process.env.REACT_APP_MQTT_ENDPOINT,
    }),
)

const ampConfig = {
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
    },
}
Amplify.configure(ampConfig)

export default function App() {
    return (
        <ImpersonateProvider>
            <ReactBreakpoints breakpoints={breakpoints} breakpointUnit="px">
                <LangProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path="/*"
                                element={<AppSwitch lang={""} />}
                            />
                        </Routes>
                    </BrowserRouter>
                </LangProvider>
            </ReactBreakpoints>
        </ImpersonateProvider>
    )
}
