export enum Dialog {
    NONE,
    MKDIR,
    QR,
    COPY_FILE,
    MOVE_FILE,
    RENAME_FILE,
    SHARE_LINK,
    SELECT_FOLDER,
    NAME_CONFLICT,
    PREVIEW,
    LOGOUT,
    DELETE,
    DELETE_ALL,
    UPDATE_PASSWORD,
    ADMIN_UPDATE_USER_EMAIL,
    ADMIN_UPDATE_USER_PASSWORD,
    ADMIN_UPDATE_USER_ACCOUNT_TYPE,
    ADMIN_SEND_NEW_PASSWORD,
    ADMIN_CLOSE_USER_ACCOUNT,
    REMOVE_IMAGE_BACKGROUND,
    ANIMATION_SETTING,
    MK_ANIMATION,
    EDIT_IMAGE,
    MK_PROJECT,
}
