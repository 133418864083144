// source: protocol/animation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.saasphoto.AnimationErrorCode', null, global);
goog.exportSymbol('proto.saasphoto.AnimationSettingsResponse', null, global);
goog.exportSymbol('proto.saasphoto.CreateAnimationSettingsRequest', null, global);
goog.exportSymbol('proto.saasphoto.GetAnimationSettingsRequest', null, global);
goog.exportSymbol('proto.saasphoto.SaveAnimationSettingsRequest', null, global);
goog.exportSymbol('proto.saasphoto.SaveAnimationSettingsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetAnimationSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.GetAnimationSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetAnimationSettingsRequest.displayName = 'proto.saasphoto.GetAnimationSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.AnimationSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.AnimationSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.AnimationSettingsResponse.displayName = 'proto.saasphoto.AnimationSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.SaveAnimationSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.SaveAnimationSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.SaveAnimationSettingsRequest.displayName = 'proto.saasphoto.SaveAnimationSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.SaveAnimationSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.SaveAnimationSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.SaveAnimationSettingsResponse.displayName = 'proto.saasphoto.SaveAnimationSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.CreateAnimationSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.CreateAnimationSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.CreateAnimationSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.CreateAnimationSettingsRequest.displayName = 'proto.saasphoto.CreateAnimationSettingsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetAnimationSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetAnimationSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetAnimationSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetAnimationSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    replaceLibsUrl: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetAnimationSettingsRequest}
 */
proto.saasphoto.GetAnimationSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetAnimationSettingsRequest;
  return proto.saasphoto.GetAnimationSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetAnimationSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetAnimationSettingsRequest}
 */
proto.saasphoto.GetAnimationSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReplaceLibsUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetAnimationSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetAnimationSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetAnimationSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetAnimationSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReplaceLibsUrl();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.saasphoto.GetAnimationSettingsRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.GetAnimationSettingsRequest} returns this
 */
proto.saasphoto.GetAnimationSettingsRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool replace_libs_url = 2;
 * @return {boolean}
 */
proto.saasphoto.GetAnimationSettingsRequest.prototype.getReplaceLibsUrl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.GetAnimationSettingsRequest} returns this
 */
proto.saasphoto.GetAnimationSettingsRequest.prototype.setReplaceLibsUrl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.AnimationSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.AnimationSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AnimationSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstHtmlBlock: jspb.Message.getFieldWithDefault(msg, 2, ""),
    middleSettings: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastHtmlBlock: jspb.Message.getFieldWithDefault(msg, 4, ""),
    htmlContent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.AnimationSettingsResponse}
 */
proto.saasphoto.AnimationSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.AnimationSettingsResponse;
  return proto.saasphoto.AnimationSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.AnimationSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.AnimationSettingsResponse}
 */
proto.saasphoto.AnimationSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstHtmlBlock(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleSettings(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastHtmlBlock(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtmlContent(value);
      break;
    case 6:
      var value = /** @type {!proto.saasphoto.AnimationErrorCode} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.AnimationSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.AnimationSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AnimationSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstHtmlBlock();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMiddleSettings();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastHtmlBlock();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHtmlContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AnimationSettingsResponse} returns this
 */
proto.saasphoto.AnimationSettingsResponse.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_html_block = 2;
 * @return {string}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.getFirstHtmlBlock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AnimationSettingsResponse} returns this
 */
proto.saasphoto.AnimationSettingsResponse.prototype.setFirstHtmlBlock = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string middle_settings = 3;
 * @return {string}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.getMiddleSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AnimationSettingsResponse} returns this
 */
proto.saasphoto.AnimationSettingsResponse.prototype.setMiddleSettings = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_html_block = 4;
 * @return {string}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.getLastHtmlBlock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AnimationSettingsResponse} returns this
 */
proto.saasphoto.AnimationSettingsResponse.prototype.setLastHtmlBlock = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string html_content = 5;
 * @return {string}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.getHtmlContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AnimationSettingsResponse} returns this
 */
proto.saasphoto.AnimationSettingsResponse.prototype.setHtmlContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AnimationErrorCode status = 6;
 * @return {!proto.saasphoto.AnimationErrorCode}
 */
proto.saasphoto.AnimationSettingsResponse.prototype.getStatus = function() {
  return /** @type {!proto.saasphoto.AnimationErrorCode} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.saasphoto.AnimationErrorCode} value
 * @return {!proto.saasphoto.AnimationSettingsResponse} returns this
 */
proto.saasphoto.AnimationSettingsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.SaveAnimationSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.SaveAnimationSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SaveAnimationSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    saveKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settings: jspb.Message.getFieldWithDefault(msg, 3, ""),
    identity: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.SaveAnimationSettingsRequest}
 */
proto.saasphoto.SaveAnimationSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.SaveAnimationSettingsRequest;
  return proto.saasphoto.SaveAnimationSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.SaveAnimationSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.SaveAnimationSettingsRequest}
 */
proto.saasphoto.SaveAnimationSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaveKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettings(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.SaveAnimationSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.SaveAnimationSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SaveAnimationSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSaveKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettings();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.SaveAnimationSettingsRequest} returns this
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string save_key = 2;
 * @return {string}
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.getSaveKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.SaveAnimationSettingsRequest} returns this
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.setSaveKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string settings = 3;
 * @return {string}
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.SaveAnimationSettingsRequest} returns this
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.setSettings = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string identity = 4;
 * @return {string}
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.SaveAnimationSettingsRequest} returns this
 */
proto.saasphoto.SaveAnimationSettingsRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.SaveAnimationSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.SaveAnimationSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.SaveAnimationSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SaveAnimationSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.SaveAnimationSettingsResponse}
 */
proto.saasphoto.SaveAnimationSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.SaveAnimationSettingsResponse;
  return proto.saasphoto.SaveAnimationSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.SaveAnimationSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.SaveAnimationSettingsResponse}
 */
proto.saasphoto.SaveAnimationSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.saasphoto.AnimationErrorCode} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.SaveAnimationSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.SaveAnimationSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.SaveAnimationSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.SaveAnimationSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AnimationErrorCode status = 1;
 * @return {!proto.saasphoto.AnimationErrorCode}
 */
proto.saasphoto.SaveAnimationSettingsResponse.prototype.getStatus = function() {
  return /** @type {!proto.saasphoto.AnimationErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.saasphoto.AnimationErrorCode} value
 * @return {!proto.saasphoto.SaveAnimationSettingsResponse} returns this
 */
proto.saasphoto.SaveAnimationSettingsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.CreateAnimationSettingsRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.CreateAnimationSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.CreateAnimationSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateAnimationSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageBasicKeysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    imageLargeKeysList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    width: jspb.Message.getFieldWithDefault(msg, 4, 0),
    height: jspb.Message.getFieldWithDefault(msg, 5, 0),
    identity: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest}
 */
proto.saasphoto.CreateAnimationSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.CreateAnimationSettingsRequest;
  return proto.saasphoto.CreateAnimationSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.CreateAnimationSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest}
 */
proto.saasphoto.CreateAnimationSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addImageBasicKeys(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addImageLargeKeys(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.CreateAnimationSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.CreateAnimationSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.CreateAnimationSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageBasicKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getImageLargeKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getIdentity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string image_basic_keys = 2;
 * @return {!Array<string>}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.getImageBasicKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.setImageBasicKeysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.addImageBasicKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.clearImageBasicKeysList = function() {
  return this.setImageBasicKeysList([]);
};


/**
 * repeated string image_large_keys = 3;
 * @return {!Array<string>}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.getImageLargeKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.setImageLargeKeysList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.addImageLargeKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.clearImageLargeKeysList = function() {
  return this.setImageLargeKeysList([]);
};


/**
 * optional uint32 width = 4;
 * @return {number}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 height = 5;
 * @return {number}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string identity = 6;
 * @return {string}
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.getIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.CreateAnimationSettingsRequest} returns this
 */
proto.saasphoto.CreateAnimationSettingsRequest.prototype.setIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.saasphoto.AnimationErrorCode = {
  ANIMATION_OK: 0,
  ANIMATION_FILE_NOT_HTML: 1,
  ANIMATION_INCORRECT_FORMAT: 2,
  ANIMATION_MISSING_SAVE_KEY: 3,
  ANIMATION_DRIVE_SIZE_IS_NOT_ENOUGH: 4,
  ANIMATION_FILE_NOT_FOUND: 5,
  ANIMATION_FILE_NAME_IS_EXISTED: 6,
  ANIMATION_FILE_EXTENSION_NOT_ALLOWED: 7,
  ANIMATION_MISSING_IMAGE_BASIC_KEYS: 8,
  ANIMATION_MISSING_IMAGE_LARGE_KEYS: 9
};

goog.object.extend(exports, proto.saasphoto);
