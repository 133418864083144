/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/customer.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var protocol_plan_pb = require('../protocol/plan_pb.js')
const proto = {};
proto.saasphoto = require('./customer_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.CustomerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.CustomerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddFeedbackRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Customer_AddFeedback = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/AddFeedback',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddFeedbackRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.AddFeedbackRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.addFeedback =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/AddFeedback',
      request,
      metadata || {},
      methodDescriptor_Customer_AddFeedback,
      callback);
};


/**
 * @param {!proto.saasphoto.AddFeedbackRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.addFeedback =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/AddFeedback',
      request,
      metadata || {},
      methodDescriptor_Customer_AddFeedback);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetFeedbackListResponse>}
 */
const methodDescriptor_Customer_GetFeedbackList = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/GetFeedbackList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetFeedbackListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetFeedbackListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetFeedbackListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetFeedbackListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.getFeedbackList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/GetFeedbackList',
      request,
      metadata || {},
      methodDescriptor_Customer_GetFeedbackList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetFeedbackListResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.getFeedbackList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/GetFeedbackList',
      request,
      metadata || {},
      methodDescriptor_Customer_GetFeedbackList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.IsValidUserResponse>}
 */
const methodDescriptor_Customer_IsValidUser = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/IsValidUser',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.IsValidUserResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.IsValidUserResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.IsValidUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.IsValidUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.isValidUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/IsValidUser',
      request,
      metadata || {},
      methodDescriptor_Customer_IsValidUser,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.IsValidUserResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.isValidUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/IsValidUser',
      request,
      metadata || {},
      methodDescriptor_Customer_IsValidUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetChargesResponse>}
 */
const methodDescriptor_Customer_GetCharges = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/GetCharges',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetChargesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetChargesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetChargesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetChargesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.getCharges =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/GetCharges',
      request,
      metadata || {},
      methodDescriptor_Customer_GetCharges,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetChargesResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.getCharges =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/GetCharges',
      request,
      metadata || {},
      methodDescriptor_Customer_GetCharges);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetLatestUserPlanResponse>}
 */
const methodDescriptor_Customer_GetLatestUserPlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/GetLatestUserPlan',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetLatestUserPlanResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetLatestUserPlanResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetLatestUserPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetLatestUserPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.getLatestUserPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/GetLatestUserPlan',
      request,
      metadata || {},
      methodDescriptor_Customer_GetLatestUserPlan,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetLatestUserPlanResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.getLatestUserPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/GetLatestUserPlan',
      request,
      metadata || {},
      methodDescriptor_Customer_GetLatestUserPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.QueryUsedResponse>}
 */
const methodDescriptor_Customer_QueryUsed = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/QueryUsed',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.QueryUsedResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.QueryUsedResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.QueryUsedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.QueryUsedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.queryUsed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/QueryUsed',
      request,
      metadata || {},
      methodDescriptor_Customer_QueryUsed,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.QueryUsedResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.queryUsed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/QueryUsed',
      request,
      metadata || {},
      methodDescriptor_Customer_QueryUsed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetSystemNotifyListResponse>}
 */
const methodDescriptor_Customer_GetSystemNotifyList = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/GetSystemNotifyList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetSystemNotifyListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetSystemNotifyListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetSystemNotifyListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetSystemNotifyListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.getSystemNotifyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/GetSystemNotifyList',
      request,
      metadata || {},
      methodDescriptor_Customer_GetSystemNotifyList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetSystemNotifyListResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.getSystemNotifyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/GetSystemNotifyList',
      request,
      metadata || {},
      methodDescriptor_Customer_GetSystemNotifyList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdateDefaultUploadFolderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Customer_UpdateDefaultUploadFolder = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/UpdateDefaultUploadFolder',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdateDefaultUploadFolderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.UpdateDefaultUploadFolderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdateDefaultUploadFolderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.updateDefaultUploadFolder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/UpdateDefaultUploadFolder',
      request,
      metadata || {},
      methodDescriptor_Customer_UpdateDefaultUploadFolder,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdateDefaultUploadFolderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.updateDefaultUploadFolder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/UpdateDefaultUploadFolder',
      request,
      metadata || {},
      methodDescriptor_Customer_UpdateDefaultUploadFolder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SubscriptionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Customer_Subscription = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/Subscription',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SubscriptionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.SubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.subscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/Subscription',
      request,
      metadata || {},
      methodDescriptor_Customer_Subscription,
      callback);
};


/**
 * @param {!proto.saasphoto.SubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.subscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/Subscription',
      request,
      metadata || {},
      methodDescriptor_Customer_Subscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.CreateFreeTrialPlanRequest,
 *   !proto.saasphoto.CreateFreeTrialPlanResponse>}
 */
const methodDescriptor_Customer_CreateFreeTrialPlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/CreateFreeTrialPlan',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.CreateFreeTrialPlanRequest,
  proto.saasphoto.CreateFreeTrialPlanResponse,
  /**
   * @param {!proto.saasphoto.CreateFreeTrialPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.CreateFreeTrialPlanResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.CreateFreeTrialPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.CreateFreeTrialPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.CreateFreeTrialPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.createFreeTrialPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/CreateFreeTrialPlan',
      request,
      metadata || {},
      methodDescriptor_Customer_CreateFreeTrialPlan,
      callback);
};


/**
 * @param {!proto.saasphoto.CreateFreeTrialPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.CreateFreeTrialPlanResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.createFreeTrialPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/CreateFreeTrialPlan',
      request,
      metadata || {},
      methodDescriptor_Customer_CreateFreeTrialPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetAccountTypeResponse>}
 */
const methodDescriptor_Customer_GetAccountType = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/GetAccountType',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetAccountTypeResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetAccountTypeResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetAccountTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetAccountTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.getAccountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/GetAccountType',
      request,
      metadata || {},
      methodDescriptor_Customer_GetAccountType,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetAccountTypeResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.getAccountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/GetAccountType',
      request,
      metadata || {},
      methodDescriptor_Customer_GetAccountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.IsValidSerialNumberRequest,
 *   !proto.saasphoto.IsValidSerialNumberResponse>}
 */
const methodDescriptor_Customer_IsValidSerialNumber = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/IsValidSerialNumber',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.IsValidSerialNumberRequest,
  proto.saasphoto.IsValidSerialNumberResponse,
  /**
   * @param {!proto.saasphoto.IsValidSerialNumberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.IsValidSerialNumberResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.IsValidSerialNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.IsValidSerialNumberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.IsValidSerialNumberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.isValidSerialNumber =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/IsValidSerialNumber',
      request,
      metadata || {},
      methodDescriptor_Customer_IsValidSerialNumber,
      callback);
};


/**
 * @param {!proto.saasphoto.IsValidSerialNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.IsValidSerialNumberResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.isValidSerialNumber =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/IsValidSerialNumber',
      request,
      metadata || {},
      methodDescriptor_Customer_IsValidSerialNumber);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.CreateFreeTrialAccountRequest,
 *   !proto.saasphoto.CreateFreeTrialAccountResponse>}
 */
const methodDescriptor_Customer_CreateFreeTrialAccount = new grpc.web.MethodDescriptor(
  '/saasphoto.Customer/CreateFreeTrialAccount',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.CreateFreeTrialAccountRequest,
  proto.saasphoto.CreateFreeTrialAccountResponse,
  /**
   * @param {!proto.saasphoto.CreateFreeTrialAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.CreateFreeTrialAccountResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.CreateFreeTrialAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.CreateFreeTrialAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.CreateFreeTrialAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.CustomerClient.prototype.createFreeTrialAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Customer/CreateFreeTrialAccount',
      request,
      metadata || {},
      methodDescriptor_Customer_CreateFreeTrialAccount,
      callback);
};


/**
 * @param {!proto.saasphoto.CreateFreeTrialAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.CreateFreeTrialAccountResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.CustomerPromiseClient.prototype.createFreeTrialAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Customer/CreateFreeTrialAccount',
      request,
      metadata || {},
      methodDescriptor_Customer_CreateFreeTrialAccount);
};


module.exports = proto.saasphoto;

