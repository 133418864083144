import { Observable, Subject } from "rxjs"
import { useCallback, useState } from "react"

export default function useEventSubject<E>(): [
    (event: E) => void,
    Observable<E>,
] {
    const [subject$] = useState(new Subject<E>())
    const callback = useCallback(
        (e: E) => {
            subject$.next(e)
        },
        [subject$],
    )
    return [callback, subject$]
}
