/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/animation.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.saasphoto = require('./animation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.AnimationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.AnimationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetAnimationSettingsRequest,
 *   !proto.saasphoto.AnimationSettingsResponse>}
 */
const methodDescriptor_Animation_GetAnimationSettings = new grpc.web.MethodDescriptor(
  '/saasphoto.Animation/GetAnimationSettings',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetAnimationSettingsRequest,
  proto.saasphoto.AnimationSettingsResponse,
  /**
   * @param {!proto.saasphoto.GetAnimationSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.AnimationSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetAnimationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.AnimationSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.AnimationSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnimationClient.prototype.getAnimationSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Animation/GetAnimationSettings',
      request,
      metadata || {},
      methodDescriptor_Animation_GetAnimationSettings,
      callback);
};


/**
 * @param {!proto.saasphoto.GetAnimationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.AnimationSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnimationPromiseClient.prototype.getAnimationSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Animation/GetAnimationSettings',
      request,
      metadata || {},
      methodDescriptor_Animation_GetAnimationSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SaveAnimationSettingsRequest,
 *   !proto.saasphoto.SaveAnimationSettingsResponse>}
 */
const methodDescriptor_Animation_SaveAnimationSettings = new grpc.web.MethodDescriptor(
  '/saasphoto.Animation/SaveAnimationSettings',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SaveAnimationSettingsRequest,
  proto.saasphoto.SaveAnimationSettingsResponse,
  /**
   * @param {!proto.saasphoto.SaveAnimationSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.SaveAnimationSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SaveAnimationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.SaveAnimationSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.SaveAnimationSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnimationClient.prototype.saveAnimationSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Animation/SaveAnimationSettings',
      request,
      metadata || {},
      methodDescriptor_Animation_SaveAnimationSettings,
      callback);
};


/**
 * @param {!proto.saasphoto.SaveAnimationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.SaveAnimationSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnimationPromiseClient.prototype.saveAnimationSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Animation/SaveAnimationSettings',
      request,
      metadata || {},
      methodDescriptor_Animation_SaveAnimationSettings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.CreateAnimationSettingsRequest,
 *   !proto.saasphoto.AnimationSettingsResponse>}
 */
const methodDescriptor_Animation_CreateAnimationSettings = new grpc.web.MethodDescriptor(
  '/saasphoto.Animation/CreateAnimationSettings',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.CreateAnimationSettingsRequest,
  proto.saasphoto.AnimationSettingsResponse,
  /**
   * @param {!proto.saasphoto.CreateAnimationSettingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.AnimationSettingsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.CreateAnimationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.AnimationSettingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.AnimationSettingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnimationClient.prototype.createAnimationSettings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Animation/CreateAnimationSettings',
      request,
      metadata || {},
      methodDescriptor_Animation_CreateAnimationSettings,
      callback);
};


/**
 * @param {!proto.saasphoto.CreateAnimationSettingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.AnimationSettingsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnimationPromiseClient.prototype.createAnimationSettings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Animation/CreateAnimationSettings',
      request,
      metadata || {},
      methodDescriptor_Animation_CreateAnimationSettings);
};


module.exports = proto.saasphoto;

