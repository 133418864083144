import { MuiThemeProvider } from "@material-ui/core/styles"
import { Empty } from "google-protobuf/google/protobuf/empty_pb"
import { Suspense, lazy, useContext, useEffect, useMemo } from "react"
import { Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { Observable } from "rxjs"
import LoadingOverlay from "../components/LoadingOverlay"
import LoadingScreen from "../components/LoadingScreen"
import TitleChanger from "../components/TitleChanger"
import Toast from "../components/Toast"
import { AuthContext, AuthProvider } from "../context/auth"
import { DialogProvider } from "../context/dialog"
import { InternetStatusProvider } from "../context/internetConnected"
import { LangContext } from "../context/lang"
import { TaskProvider } from "../context/task"
import { ToastProvider } from "../context/toast"
import { UpdateAccountInfoProviderContextContainer } from "../context/updateAccountInfo"
import { UserSetting } from "../grpc"
import useAuthGRPC from "../hooks/useAuthGRPC"
import { QueryLanguageResponse } from "../protocol/user_settings_pb"
import { MaterialBright } from "../theme"
import ErrorBoundary from "./ErrorBoundary"
import ForceLogoutRoute from "./ForceLogoutRoute"
import ProtectedAdminRoute from "./ProtectedAdminRoute"
import ProtectedRoute from "./ProtectedRoute"
import RenderWithReactIntl from "./RenderWithReactIntl"

const LoginPage = lazy(
    () =>
        import(/* webpackChunkName: "LoginPage" */ "../scenes/login/LoginPage"),
)
const SignUpSuccess = lazy(
    () =>
        import(
            /* webpackChunkName: "SignUpSuccess" */ "../scenes/login/SignUpSuccess"
        ),
)
const VerifyRegisteredEmailPage = lazy(
    () =>
        import(
            /* webpackChunkName: "VerificationPage" */ "../scenes/login/VerifyRegisteredEmailPage"
        ),
)
const DrivePageContextHandler = lazy(
    () =>
        import(
            /* webpackChunkName: "DrivePage" */ "../scenes/drive/components/DrivePage"
        ),
)
const CheckoutPage = lazy(
    () =>
        import(
            /* webpackChunkName: "CheckoutPage" */ "../scenes/checkout/components/Checkout"
        ),
)
const PlanManagementPage = lazy(
    () =>
        import(
            /* webpackChunkName: "PlanManagementPage" */ "../scenes/planManagement/PlanManagementPage"
        ),
)
const Banned = lazy(
    () => import(/* webpackChunkName: "Banned" */ "../scenes/login/Banned"),
)
const AdminPageContextHandler = lazy(
    () =>
        import(
            /* webpackChunkName: "AdminPage" */ "../scenes/admin/components/AdminPage"
        ),
)
const PrivacyPolicy = lazy(
    () =>
        import(
            /* webpackChunkName: "PrivacyPolicy" */ "../scenes/document/PrivacyPolicy"
        ),
)
const TermsAndConditions = lazy(
    () =>
        import(
            /* webpackChunkName: "TermsAndConditions" */ "../scenes/document/TermsAndConditions"
        ),
)
const FreeTrialSignupPage = lazy(
    () =>
        import(
            /* webpackChunkName: "FreeTrialSignupPage" */
            "../scenes/planManagement/freeTrial/FreeTrialSignupPage/FreeTrialSignupPage"
        ),
)

interface ISwitchRouteContextHandler {
    dark?: boolean
    lang: string
}

type ISwitchRoute = {
    queryLanguage: (
        request: Empty,
        metadata?: any,
    ) => Observable<QueryLanguageResponse>
    initialized: boolean | undefined
    login: boolean | undefined
} & ISwitchRouteContextHandler

function getBrowserLang() {
    return (
        ["fr", "ja", "zh-CN", "zh-TW"].find((val) =>
            RegExp(`^${val}`).test(navigator.language),
        ) || "en"
    )
}

function SwitchRouteContextHandler(props: ISwitchRouteContextHandler) {
    // 此function 有用 useCallback 包住
    const queryLanguage = useAuthGRPC(UserSetting.queryLanguage, UserSetting)
    const { init: initialized, login } = useContext(AuthContext)
    return useMemo(
        () => (
            <SwitchRoute
                {...props}
                queryLanguage={queryLanguage}
                initialized={initialized}
                login={login}
            />
        ),
        [queryLanguage, props, initialized, login],
    )
}

const Contact = () => {
    window.location.href =
        "https://www.ortery.com/contact-ortery-technologies-inc/"

    return null
}

function SwitchRoute({
    queryLanguage,
    login,
    initialized,
    ...props
}: ISwitchRoute) {
    const { setLang } = useContext(LangContext)

    useEffect(() => {
        if (!initialized) {
            return
        }
        if (login) {
            const queryLangSub = queryLanguage(new Empty()).subscribe(
                (response) => {
                    const lang = response.getLanguage()
                    setLang(lang || getBrowserLang())
                },
            )

            return () => queryLangSub.unsubscribe()
        } else {
            const navLang = getBrowserLang()
            setLang(navLang)
        }
    }, [initialized, login, queryLanguage, setLang])
    return (
        <Suspense fallback={<LoadingOverlay />}>
            <ErrorBoundary>
                <TitleChanger
                    validPaths={[
                        "@my-drive",
                        "@trash",
                        "@favorite",
                        "@recent",
                        "settings",
                        "plan",
                        "statistics",
                        "select-plan",
                        "free-trial",
                        "free-trial-success",
                        "farewell",
                        "stop-subscription",
                        "restore-subscription",
                        "refill",
                        "login",
                        "signup",
                        "forgot-password",
                        "resend-confirmation",
                        "privacy-policy",
                        "terms-and-conditions",
                    ]}
                />
                <Routes>
                    {!initialized && (
                        <Route path="/*" element={<LoadingScreen />} />
                    )}

                    {initialized && (
                        <>
                            <Route
                                path="/privacy-policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="/terms-and-conditions"
                                element={<TermsAndConditions />}
                            />
                            <Route path="/contact" element={<Contact />} />
                            <Route
                                path="/signup/free_account/*"
                                element={
                                    <ForceLogoutRoute
                                        element={<FreeTrialSignupPage />}
                                    />
                                }
                            />
                            <Route path="/l/*" element={<LoginPage />} />
                            <Route
                                path="/signup-success"
                                element={<SignUpSuccess />}
                            />
                            <Route
                                path="/verify/*"
                                element={<VerifyRegisteredEmailPage />}
                            />
                            <Route
                                path="/banned"
                                element={
                                    <ProtectedRoute element={<Banned />} />
                                }
                            />
                            <Route
                                path="/p/*"
                                element={
                                    <ProtectedRoute
                                        element={<PlanManagementPage />}
                                    />
                                }
                            />
                            <Route
                                // 付款成功頁面
                                path="/checkout"
                                element={
                                    <ProtectedRoute
                                        element={<CheckoutPage />}
                                        {...props}
                                    />
                                }
                            />
                            <Route
                                path="/admin/*"
                                element={
                                    <ProtectedAdminRoute
                                        element={<AdminPageContextHandler />}
                                    />
                                }
                            />
                            <Route
                                path="/*"
                                element={
                                    <ProtectedRoute
                                        element={
                                            <UpdateAccountInfoProviderContextContainer>
                                                <DrivePageContextHandler />
                                            </UpdateAccountInfoProviderContextContainer>
                                        }
                                        {...props}
                                    />
                                }
                            />
                        </>
                    )}
                </Routes>
                <Toast />
                <ToastContainer
                    autoClose={1000}
                    pauseOnHover={false}
                    position="bottom-right"
                />
            </ErrorBoundary>
        </Suspense>
    )
}

export default function AppWrapper(props: ISwitchRouteContextHandler) {
    return (
        <RenderWithReactIntl>
            <AuthProvider>
                <MuiThemeProvider theme={MaterialBright}>
                    <ToastProvider>
                        <TaskProvider>
                            <DialogProvider>
                                <InternetStatusProvider>
                                    <SwitchRouteContextHandler {...props} />
                                </InternetStatusProvider>
                            </DialogProvider>
                        </TaskProvider>
                    </ToastProvider>
                </MuiThemeProvider>
            </AuthProvider>
        </RenderWithReactIntl>
    )
}
