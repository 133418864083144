/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/histogram.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.saasphoto = require('./histogram_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.HistogramClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.HistogramPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.Setting,
 *   !proto.saasphoto.HistogramResponse>}
 */
const methodDescriptor_Histogram_GetData = new grpc.web.MethodDescriptor(
  '/saasphoto.Histogram/GetData',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.Setting,
  proto.saasphoto.HistogramResponse,
  /**
   * @param {!proto.saasphoto.Setting} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.HistogramResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.Setting} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.HistogramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.HistogramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.HistogramClient.prototype.getData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Histogram/GetData',
      request,
      metadata || {},
      methodDescriptor_Histogram_GetData,
      callback);
};


/**
 * @param {!proto.saasphoto.Setting} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.HistogramResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.HistogramPromiseClient.prototype.getData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Histogram/GetData',
      request,
      metadata || {},
      methodDescriptor_Histogram_GetData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetHighestDataRequest,
 *   !proto.saasphoto.GetHighestDataResponse>}
 */
const methodDescriptor_Histogram_GetHighestData = new grpc.web.MethodDescriptor(
  '/saasphoto.Histogram/GetHighestData',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetHighestDataRequest,
  proto.saasphoto.GetHighestDataResponse,
  /**
   * @param {!proto.saasphoto.GetHighestDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetHighestDataResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetHighestDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetHighestDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetHighestDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.HistogramClient.prototype.getHighestData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Histogram/GetHighestData',
      request,
      metadata || {},
      methodDescriptor_Histogram_GetHighestData,
      callback);
};


/**
 * @param {!proto.saasphoto.GetHighestDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetHighestDataResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.HistogramPromiseClient.prototype.getHighestData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Histogram/GetHighestData',
      request,
      metadata || {},
      methodDescriptor_Histogram_GetHighestData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.Setting,
 *   !proto.saasphoto.HistogramResponse>}
 */
const methodDescriptor_Histogram_GetCreditData = new grpc.web.MethodDescriptor(
  '/saasphoto.Histogram/GetCreditData',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.Setting,
  proto.saasphoto.HistogramResponse,
  /**
   * @param {!proto.saasphoto.Setting} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.HistogramResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.Setting} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.HistogramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.HistogramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.HistogramClient.prototype.getCreditData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Histogram/GetCreditData',
      request,
      metadata || {},
      methodDescriptor_Histogram_GetCreditData,
      callback);
};


/**
 * @param {!proto.saasphoto.Setting} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.HistogramResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.HistogramPromiseClient.prototype.getCreditData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Histogram/GetCreditData',
      request,
      metadata || {},
      methodDescriptor_Histogram_GetCreditData);
};


module.exports = proto.saasphoto;

