import Auth from "@aws-amplify/auth"
import { defer, from, Observable } from "rxjs"
import { ajax } from "rxjs/ajax"
import { filter, map, switchMap } from "rxjs/operators"
import { GetUploadLinksResponseInfo } from "../protocol/drive_pb"

// tslint:disable:no-console
const getToken$ = defer(() =>
    from(Auth.currentSession()).pipe(
        map((session) => session.getIdToken().getJwtToken()),
    ),
)

const authHeader$ = defer(() =>
    getToken$.pipe(map((token) => ({ Authorization: token }))),
)

function getAPI(url: string) {
    return process.env.REACT_APP_API_BASE + url
}

export function get(url: string) {
    return authHeader$.pipe(
        switchMap((header) => ajax.get(getAPI(url), header)),
    )
}

export function post(url: string, data?: any) {
    return authHeader$.pipe(
        switchMap((header) =>
            ajax.post(getAPI(url), JSON.stringify(data), header),
        ),
    )
}
function objectToFormData(data: { [key: string]: any }): FormData {
    const formData = new FormData()
    Object.keys(data).forEach((k) => formData.append(k, data[k]))
    return formData
}

export function put(url: string, data?: any) {
    return authHeader$.pipe(
        switchMap((header) =>
            ajax.put(getAPI(url), JSON.stringify(data), header),
        ),
    )
}

export function head(url: string) {
    return authHeader$.pipe(
        switchMap((header) =>
            ajax({
                crossDomain: true,
                headers: header,
                method: "HEAD",
                url: getAPI(url),
            }),
        ),
    )
}

// post form. convert given object into formdata
export function postForm(url: string, data: { [key: string]: any }) {
    return authHeader$.pipe(
        switchMap((header) => {
            return ajax.post(getAPI(url), objectToFormData(data), header)
        }),
    )
}

export function httpDelete(url: string) {
    return authHeader$.pipe(
        switchMap((header) => ajax.delete(getAPI(url), header)),
    )
}

export interface Progress {
    loaded: number
    total: number
}

export function upload(
    urlLink: GetUploadLinksResponseInfo,
    file: File,
): Observable<Progress> {
    return ajax({
        body: file as File,
        crossDomain: true,
        method: "PUT",
        url: urlLink.getLink() as string,
        includeUploadProgress: true,
    }).pipe(
        filter((event) => event.type === "upload_progress"),
        map(({ loaded, total }) => {
            return { loaded, total }
        }),
    )
}
