export const translationData = {
    fr: () => import("./fr.json"),
    ja: () => import("./ja.json"),
    "zh-CN": () => import("./zh-CN.json"),
    "zh-TW": () => import("./zh-TW.json"),
}

async function loadLocaleData(locale: keyof typeof translationData) {
    const data = await translationData[locale]()
    return data.default
}

export default loadLocaleData
