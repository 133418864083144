import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Overlay from "./Overlay"

export default function LoadingOverlay({ shouldBlurBackground = true }) {
    return (
        <Overlay shouldBlurBackground={shouldBlurBackground}>
            <CircularProgress />
        </Overlay>
    )
}
