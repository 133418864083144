import React, { useCallback, useContext, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import { ToastContext } from "../context/toast"
import toastMessage from "../messages/toast"

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

interface IToast extends Omit<SnackbarProps, "open"> {}

const useStyles = makeStyles({
    container: {
        borderRadius: "2px",
        height: "50px",
    },
    closeBtn: {
        padding: "0px",
        marginRight: "2px",
        "& svg": {
            fontSize: "24px",
            color: "#FFFFFF",
        },
    },
    contentArea: {
        paddingLeft: "17px",
        fontSize: "16px",
        fontWeight: 400,
        "& div:nth-of-type(2)": {
            padding: "0",
            margin: "0",
            marginLeft: "auto",
        },
    },
    undoBtn: {
        color: "#C8DFFA",
        fontSize: "16px",
        fontWeight: 400,
        padding: "0px",
        minWidth: "auto",
        marginRight: "32px",
        "&:hover": {
            color: "#C8DFFA",
        },
    },
})

export default function Toast({ ...rest }: IToast) {
    const intl = useIntl()
    const { open, closeHandler, undoAction, message } = useContext(ToastContext)
    const [buttonDisabled, setButtonDisabled] = useState(true)

    const classes = useStyles()

    const undoHandler = useCallback(() => {
        undoAction?.()
        setButtonDisabled(true)
        closeHandler()
    }, [undoAction, closeHandler])

    useEffect(() => {
        open && setButtonDisabled(false)
    }, [open])

    return (
        <Snackbar
            className={classes.container}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={open}
            onClose={closeHandler}
            ContentProps={{
                className: classes.contentArea,
            }}
            autoHideDuration={5000}
            message={message}
            action={[
                undoAction ? (
                    <Button
                        disabled={buttonDisabled}
                        key="undo"
                        onClick={undoHandler}
                        className={classes.undoBtn}
                    >
                        {intl.formatMessage(toastMessage.undo)}
                    </Button>
                ) : null,
                <IconButton
                    key="close"
                    color="inherit"
                    onClick={closeHandler}
                    className={classes.closeBtn}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
            {...rest}
        />
    )
}
