import { useEffect } from "react"

interface ITitleProps {
    title: string
}
export function Title({ title }: ITitleProps) {
    useEffect(() => {
        document.title = title
    }, [title])
    return null
}
