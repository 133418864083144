/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/analytics.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.saasphoto = require('./analytics_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.AnalyticsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.AnalyticsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.RefillBandwidthResponse>}
 */
const methodDescriptor_Analytics_GetRefillBandwidth = new grpc.web.MethodDescriptor(
  '/saasphoto.Analytics/GetRefillBandwidth',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.RefillBandwidthResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.RefillBandwidthResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.RefillBandwidthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.RefillBandwidthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnalyticsClient.prototype.getRefillBandwidth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Analytics/GetRefillBandwidth',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetRefillBandwidth,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.RefillBandwidthResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnalyticsPromiseClient.prototype.getRefillBandwidth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Analytics/GetRefillBandwidth',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetRefillBandwidth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.StorageUsageResponse>}
 */
const methodDescriptor_Analytics_GetStorageUsage = new grpc.web.MethodDescriptor(
  '/saasphoto.Analytics/GetStorageUsage',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.StorageUsageResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.StorageUsageResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.StorageUsageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.StorageUsageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnalyticsClient.prototype.getStorageUsage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Analytics/GetStorageUsage',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetStorageUsage,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.StorageUsageResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnalyticsPromiseClient.prototype.getStorageUsage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Analytics/GetStorageUsage',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetStorageUsage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.BandwidthResponse>}
 */
const methodDescriptor_Analytics_GetBandwidthUsage = new grpc.web.MethodDescriptor(
  '/saasphoto.Analytics/GetBandwidthUsage',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.BandwidthResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.BandwidthResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.BandwidthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.BandwidthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnalyticsClient.prototype.getBandwidthUsage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Analytics/GetBandwidthUsage',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetBandwidthUsage,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.BandwidthResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnalyticsPromiseClient.prototype.getBandwidthUsage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Analytics/GetBandwidthUsage',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetBandwidthUsage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.RPResponse>}
 */
const methodDescriptor_Analytics_GetMostView = new grpc.web.MethodDescriptor(
  '/saasphoto.Analytics/GetMostView',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.RPResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.RPResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.RPResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.RPResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.AnalyticsClient.prototype.getMostView =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Analytics/GetMostView',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetMostView,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.RPResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.AnalyticsPromiseClient.prototype.getMostView =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Analytics/GetMostView',
      request,
      metadata || {},
      methodDescriptor_Analytics_GetMostView);
};


module.exports = proto.saasphoto;

