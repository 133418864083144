export const VALID_REMOVE_IMAGE_BACKGROUND_EXTENSION = [
    "jpg",
    "jpeg",
    "png",
    "thumb",
]
export const VALID_CREATE_ANIMATION_IMAGE_EXTENSION = [
    "image/jpg",
    "image/jpeg",
    "image/png",
]
export const VALID_REMOVE_IMAGE_BACKGROUND_TYPES = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/thumb",
]
export const MAX_SELECTED_CREATE_ANIMATION_IMAGES = 360
export const MAX_ANIMATION_CANVAS_WIDTH = 600
export const MAX_DIMENSION_CANVAS = 9999
export const TRANSPARENT_IMAGE = "background.png"
export const HTML_TYPE = "text/html; charset=utf-8"
export const VALID_ANIMATION_SETTING_TYPES = [HTML_TYPE]
export const UPDATE_URL_STORAGE_NAME = "urls"
export const VALID_EDIT_IMAGE_EXTENSION: string[] = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
]
export const DELETE_FILE_CHUNK_SIZE = 100
export const MAX_INPUT_LENGTH = 200
