import React from "react"
import {
    borders,
    ComposedStyleFunction,
    display,
    flexbox,
    grid,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography,
    PropsFor,
} from "@material-ui/system"
import { BoxProps } from "@material-ui/core/Box"
import styled from "@emotion/styled"

type BoxStyleFunction = ComposedStyleFunction<
    [
        typeof borders,
        typeof display,
        typeof flexbox,
        typeof grid,
        typeof palette,
        typeof positions,
        typeof shadows,
        typeof sizing,
        typeof spacing,
        typeof typography,
    ]
>

type SystemProps = PropsFor<BoxStyleFunction>

const WFakeMaterialBox = styled.div<SystemProps>`
    ${borders}
    ${display}
    ${flexbox}
    ${grid}
    ${palette}
    ${positions}
    ${shadows}
    ${sizing}
    ${spacing}
    ${typography}
`

export default function FakeMaterialBox(props: BoxProps) {
    const { children, ...other } = props
    return <WFakeMaterialBox {...other}>{children}</WFakeMaterialBox>
}
