import React from "react"
import { styled } from "@material-ui/core/styles"
import Button, { ButtonProps } from "@material-ui/core/Button"

const StyledButton = styled(Button)({
    width: "112px",
    height: "38px",
    borderRadius: "2px",
    textTransform: "none",
})

export default function FormButton(props: ButtonProps) {
    return <StyledButton {...props} variant="contained" />
}
