import React from "react"
import ReactLoading from "react-loading"
import { makeStyles } from "@material-ui/core/styles"
import FlexBox from "./FlexBox"
import Logo from "./Logo"

const useStyles = makeStyles({
    background: {
        backgroundColor: "#20ab78",
    },
})

export default function LoadingScreen() {
    const classes = useStyles()
    return (
        <FlexBox
            className={classes.background}
            flex={1}
            justifyContent="center"
        >
            <FlexBox flexDirection="column" justifyContent="center">
                <FlexBox alignItems="center">
                    <Logo invert={true} />
                    <ReactLoading type="spinningBubbles" color="#fff" />
                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
}
