import { IFile } from "./models/IFile"

export function ensureSlash(key: string) {
    return key.replace(/(.*?)\/?$/, (matches, $1) => `${$1}/`)
}

export function basename(key: string) {
    return key.replace(/\/$/, "").replace(/.*\//g, "")
}
export function multipleName(flies: IFile[]): string {
    let path = ""
    flies.forEach((item) => {
        path += `${basename(item.key)}, `
    })
    return path.slice(0, -2)
}
export function replaceBasename(key: string, newBasename: string) {
    const path = dirPath(key)
    return `${path}${newBasename}${key.endsWith("/") ? "/" : ""}`
}

export function dirPath(key: string) {
    const fullPath = key.replace(/\/$/, "")
    const regexp = /(^.*\/)(.*)/
    const result = regexp.exec(fullPath)
    if (result) {
        return result[1]
    }
    return ""
}

const quotemeta = (text: string) =>
    text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")

export function getPrevDirectory(pre: string, cur: string) {
    let prevDirectory = ""

    if (!pre || !cur) {
        return prevDirectory
    }

    const regex = RegExp(`^${quotemeta(cur.replace(/^\//, ""))}([^/]+)`)
    const matches = pre.replace(/^\//, "").match(regex)
    if (!matches) {
        return ""
    }
    return matches[1]
}
