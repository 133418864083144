// source: protocol/plan.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.saasphoto.ActivePlanCustomRequest', null, global);
goog.exportSymbol('proto.saasphoto.ActivePlanCustomResponse', null, global);
goog.exportSymbol('proto.saasphoto.ActivePlanPricingRequest', null, global);
goog.exportSymbol('proto.saasphoto.AddPlanCustomRequest', null, global);
goog.exportSymbol('proto.saasphoto.AddPlanCustomResponse', null, global);
goog.exportSymbol('proto.saasphoto.AddPlanPricingRequest', null, global);
goog.exportSymbol('proto.saasphoto.AddPlanRequest', null, global);
goog.exportSymbol('proto.saasphoto.DeletePlanRequest', null, global);
goog.exportSymbol('proto.saasphoto.GetPlanCustomRequest', null, global);
goog.exportSymbol('proto.saasphoto.GetPlanCustomResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetPlanCustomsResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetPlanPricingsRequest', null, global);
goog.exportSymbol('proto.saasphoto.GetPlanPricingsResponse', null, global);
goog.exportSymbol('proto.saasphoto.GetPlansResponse', null, global);
goog.exportSymbol('proto.saasphoto.ModifyPlanRequest', null, global);
goog.exportSymbol('proto.saasphoto.PlanCustom', null, global);
goog.exportSymbol('proto.saasphoto.PlanCustomInfo', null, global);
goog.exportSymbol('proto.saasphoto.PlanDetail', null, global);
goog.exportSymbol('proto.saasphoto.PlanErrorCode', null, global);
goog.exportSymbol('proto.saasphoto.PlanPricingDetail', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.PlanDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.PlanDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.PlanDetail.displayName = 'proto.saasphoto.PlanDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetPlansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetPlansResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetPlansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetPlansResponse.displayName = 'proto.saasphoto.GetPlansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.AddPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.AddPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.AddPlanRequest.displayName = 'proto.saasphoto.AddPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.ModifyPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.ModifyPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.ModifyPlanRequest.displayName = 'proto.saasphoto.ModifyPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.DeletePlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.DeletePlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.DeletePlanRequest.displayName = 'proto.saasphoto.DeletePlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.PlanPricingDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.PlanPricingDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.PlanPricingDetail.displayName = 'proto.saasphoto.PlanPricingDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetPlanPricingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.GetPlanPricingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetPlanPricingsRequest.displayName = 'proto.saasphoto.GetPlanPricingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetPlanPricingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetPlanPricingsResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetPlanPricingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetPlanPricingsResponse.displayName = 'proto.saasphoto.GetPlanPricingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.AddPlanPricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.AddPlanPricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.AddPlanPricingRequest.displayName = 'proto.saasphoto.AddPlanPricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.ActivePlanPricingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.ActivePlanPricingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.ActivePlanPricingRequest.displayName = 'proto.saasphoto.ActivePlanPricingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.PlanCustomInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.PlanCustomInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.PlanCustomInfo.displayName = 'proto.saasphoto.PlanCustomInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.AddPlanCustomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.AddPlanCustomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.AddPlanCustomRequest.displayName = 'proto.saasphoto.AddPlanCustomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.AddPlanCustomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.AddPlanCustomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.AddPlanCustomResponse.displayName = 'proto.saasphoto.AddPlanCustomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetPlanCustomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.GetPlanCustomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetPlanCustomRequest.displayName = 'proto.saasphoto.GetPlanCustomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetPlanCustomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.GetPlanCustomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetPlanCustomResponse.displayName = 'proto.saasphoto.GetPlanCustomResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.PlanCustom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.PlanCustom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.PlanCustom.displayName = 'proto.saasphoto.PlanCustom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.GetPlanCustomsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.saasphoto.GetPlanCustomsResponse.repeatedFields_, null);
};
goog.inherits(proto.saasphoto.GetPlanCustomsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.GetPlanCustomsResponse.displayName = 'proto.saasphoto.GetPlanCustomsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.ActivePlanCustomRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.ActivePlanCustomRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.ActivePlanCustomRequest.displayName = 'proto.saasphoto.ActivePlanCustomRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.saasphoto.ActivePlanCustomResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.saasphoto.ActivePlanCustomResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.saasphoto.ActivePlanCustomResponse.displayName = 'proto.saasphoto.ActivePlanCustomResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.PlanDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.PlanDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.PlanDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    storage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bandwidth: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.PlanDetail}
 */
proto.saasphoto.PlanDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.PlanDetail;
  return proto.saasphoto.PlanDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.PlanDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.PlanDetail}
 */
proto.saasphoto.PlanDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStorage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBandwidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.PlanDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.PlanDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.PlanDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStorage();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getBandwidth();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.PlanDetail.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanDetail} returns this
 */
proto.saasphoto.PlanDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.saasphoto.PlanDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.PlanDetail} returns this
 */
proto.saasphoto.PlanDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 storage = 3;
 * @return {number}
 */
proto.saasphoto.PlanDetail.prototype.getStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanDetail} returns this
 */
proto.saasphoto.PlanDetail.prototype.setStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 bandwidth = 4;
 * @return {number}
 */
proto.saasphoto.PlanDetail.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanDetail} returns this
 */
proto.saasphoto.PlanDetail.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetPlansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetPlansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetPlansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetPlansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    proto.saasphoto.PlanDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetPlansResponse}
 */
proto.saasphoto.GetPlansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetPlansResponse;
  return proto.saasphoto.GetPlansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetPlansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetPlansResponse}
 */
proto.saasphoto.GetPlansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.PlanDetail;
      reader.readMessage(value,proto.saasphoto.PlanDetail.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetPlansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetPlansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetPlansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.saasphoto.PlanDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlanDetail plans = 1;
 * @return {!Array<!proto.saasphoto.PlanDetail>}
 */
proto.saasphoto.GetPlansResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.saasphoto.PlanDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.saasphoto.PlanDetail, 1));
};


/**
 * @param {!Array<!proto.saasphoto.PlanDetail>} value
 * @return {!proto.saasphoto.GetPlansResponse} returns this
*/
proto.saasphoto.GetPlansResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.saasphoto.PlanDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.PlanDetail}
 */
proto.saasphoto.GetPlansResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.saasphoto.PlanDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetPlansResponse} returns this
 */
proto.saasphoto.GetPlansResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.AddPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.AddPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.AddPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    storage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bandwidth: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.AddPlanRequest}
 */
proto.saasphoto.AddPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.AddPlanRequest;
  return proto.saasphoto.AddPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.AddPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.AddPlanRequest}
 */
proto.saasphoto.AddPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStorage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBandwidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.AddPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.AddPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.AddPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStorage();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBandwidth();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.saasphoto.AddPlanRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AddPlanRequest} returns this
 */
proto.saasphoto.AddPlanRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 storage = 2;
 * @return {number}
 */
proto.saasphoto.AddPlanRequest.prototype.getStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.AddPlanRequest} returns this
 */
proto.saasphoto.AddPlanRequest.prototype.setStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 bandwidth = 3;
 * @return {number}
 */
proto.saasphoto.AddPlanRequest.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.AddPlanRequest} returns this
 */
proto.saasphoto.AddPlanRequest.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.ModifyPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.ModifyPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.ModifyPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ModifyPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    storage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bandwidth: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.ModifyPlanRequest}
 */
proto.saasphoto.ModifyPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.ModifyPlanRequest;
  return proto.saasphoto.ModifyPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.ModifyPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.ModifyPlanRequest}
 */
proto.saasphoto.ModifyPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStorage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBandwidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.ModifyPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.ModifyPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.ModifyPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ModifyPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStorage();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getBandwidth();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.ModifyPlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.ModifyPlanRequest} returns this
 */
proto.saasphoto.ModifyPlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.saasphoto.ModifyPlanRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.ModifyPlanRequest} returns this
 */
proto.saasphoto.ModifyPlanRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 storage = 3;
 * @return {number}
 */
proto.saasphoto.ModifyPlanRequest.prototype.getStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.ModifyPlanRequest} returns this
 */
proto.saasphoto.ModifyPlanRequest.prototype.setStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 bandwidth = 4;
 * @return {number}
 */
proto.saasphoto.ModifyPlanRequest.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.ModifyPlanRequest} returns this
 */
proto.saasphoto.ModifyPlanRequest.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.DeletePlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.DeletePlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.DeletePlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.DeletePlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.DeletePlanRequest}
 */
proto.saasphoto.DeletePlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.DeletePlanRequest;
  return proto.saasphoto.DeletePlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.DeletePlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.DeletePlanRequest}
 */
proto.saasphoto.DeletePlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.DeletePlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.DeletePlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.DeletePlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.DeletePlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.DeletePlanRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.DeletePlanRequest} returns this
 */
proto.saasphoto.DeletePlanRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.PlanPricingDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.PlanPricingDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.PlanPricingDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanPricingDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    price: jspb.Message.getFieldWithDefault(msg, 2, 0),
    month: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pricingApiId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    plan: (f = msg.getPlan()) && proto.saasphoto.PlanDetail.toObject(includeInstance, f),
    customPlanKey: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.PlanPricingDetail}
 */
proto.saasphoto.PlanPricingDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.PlanPricingDetail;
  return proto.saasphoto.PlanPricingDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.PlanPricingDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.PlanPricingDetail}
 */
proto.saasphoto.PlanPricingDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricingApiId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 6:
      var value = new proto.saasphoto.PlanDetail;
      reader.readMessage(value,proto.saasphoto.PlanDetail.deserializeBinaryFromReader);
      msg.setPlan(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomPlanKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.PlanPricingDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.PlanPricingDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.PlanPricingDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanPricingDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPricingApiId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPlan();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.saasphoto.PlanDetail.serializeBinaryToWriter
    );
  }
  f = message.getCustomPlanKey();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.PlanPricingDetail.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 price = 2;
 * @return {number}
 */
proto.saasphoto.PlanPricingDetail.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 month = 3;
 * @return {number}
 */
proto.saasphoto.PlanPricingDetail.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string pricing_api_id = 4;
 * @return {string}
 */
proto.saasphoto.PlanPricingDetail.prototype.getPricingApiId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.setPricingApiId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.saasphoto.PlanPricingDetail.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional PlanDetail plan = 6;
 * @return {?proto.saasphoto.PlanDetail}
 */
proto.saasphoto.PlanPricingDetail.prototype.getPlan = function() {
  return /** @type{?proto.saasphoto.PlanDetail} */ (
    jspb.Message.getWrapperField(this, proto.saasphoto.PlanDetail, 6));
};


/**
 * @param {?proto.saasphoto.PlanDetail|undefined} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
*/
proto.saasphoto.PlanPricingDetail.prototype.setPlan = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.clearPlan = function() {
  return this.setPlan(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.PlanPricingDetail.prototype.hasPlan = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string custom_plan_key = 7;
 * @return {string}
 */
proto.saasphoto.PlanPricingDetail.prototype.getCustomPlanKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.PlanPricingDetail} returns this
 */
proto.saasphoto.PlanPricingDetail.prototype.setCustomPlanKey = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetPlanPricingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetPlanPricingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetPlanPricingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanPricingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activeOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    ignoreStorageUsage: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetPlanPricingsRequest}
 */
proto.saasphoto.GetPlanPricingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetPlanPricingsRequest;
  return proto.saasphoto.GetPlanPricingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetPlanPricingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetPlanPricingsRequest}
 */
proto.saasphoto.GetPlanPricingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActiveOnly(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreStorageUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetPlanPricingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetPlanPricingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetPlanPricingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanPricingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActiveOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIgnoreStorageUsage();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool active_only = 1;
 * @return {boolean}
 */
proto.saasphoto.GetPlanPricingsRequest.prototype.getActiveOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.GetPlanPricingsRequest} returns this
 */
proto.saasphoto.GetPlanPricingsRequest.prototype.setActiveOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool ignore_storage_usage = 2;
 * @return {boolean}
 */
proto.saasphoto.GetPlanPricingsRequest.prototype.getIgnoreStorageUsage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.GetPlanPricingsRequest} returns this
 */
proto.saasphoto.GetPlanPricingsRequest.prototype.setIgnoreStorageUsage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetPlanPricingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetPlanPricingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetPlanPricingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetPlanPricingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanPricingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planPricingsList: jspb.Message.toObjectList(msg.getPlanPricingsList(),
    proto.saasphoto.PlanPricingDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetPlanPricingsResponse}
 */
proto.saasphoto.GetPlanPricingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetPlanPricingsResponse;
  return proto.saasphoto.GetPlanPricingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetPlanPricingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetPlanPricingsResponse}
 */
proto.saasphoto.GetPlanPricingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.PlanPricingDetail;
      reader.readMessage(value,proto.saasphoto.PlanPricingDetail.deserializeBinaryFromReader);
      msg.addPlanPricings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetPlanPricingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetPlanPricingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetPlanPricingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanPricingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanPricingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.saasphoto.PlanPricingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlanPricingDetail plan_pricings = 1;
 * @return {!Array<!proto.saasphoto.PlanPricingDetail>}
 */
proto.saasphoto.GetPlanPricingsResponse.prototype.getPlanPricingsList = function() {
  return /** @type{!Array<!proto.saasphoto.PlanPricingDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.saasphoto.PlanPricingDetail, 1));
};


/**
 * @param {!Array<!proto.saasphoto.PlanPricingDetail>} value
 * @return {!proto.saasphoto.GetPlanPricingsResponse} returns this
*/
proto.saasphoto.GetPlanPricingsResponse.prototype.setPlanPricingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.saasphoto.PlanPricingDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.PlanPricingDetail}
 */
proto.saasphoto.GetPlanPricingsResponse.prototype.addPlanPricings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.saasphoto.PlanPricingDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetPlanPricingsResponse} returns this
 */
proto.saasphoto.GetPlanPricingsResponse.prototype.clearPlanPricingsList = function() {
  return this.setPlanPricingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.AddPlanPricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.AddPlanPricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.AddPlanPricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanPricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    planId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.AddPlanPricingRequest}
 */
proto.saasphoto.AddPlanPricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.AddPlanPricingRequest;
  return proto.saasphoto.AddPlanPricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.AddPlanPricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.AddPlanPricingRequest}
 */
proto.saasphoto.AddPlanPricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlanId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.AddPlanPricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.AddPlanPricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.AddPlanPricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanPricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPlanId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint64 price = 1;
 * @return {number}
 */
proto.saasphoto.AddPlanPricingRequest.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.AddPlanPricingRequest} returns this
 */
proto.saasphoto.AddPlanPricingRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.saasphoto.AddPlanPricingRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.AddPlanPricingRequest} returns this
 */
proto.saasphoto.AddPlanPricingRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 plan_id = 3;
 * @return {number}
 */
proto.saasphoto.AddPlanPricingRequest.prototype.getPlanId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.AddPlanPricingRequest} returns this
 */
proto.saasphoto.AddPlanPricingRequest.prototype.setPlanId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.saasphoto.AddPlanPricingRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.AddPlanPricingRequest} returns this
 */
proto.saasphoto.AddPlanPricingRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.ActivePlanPricingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.ActivePlanPricingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.ActivePlanPricingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ActivePlanPricingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.ActivePlanPricingRequest}
 */
proto.saasphoto.ActivePlanPricingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.ActivePlanPricingRequest;
  return proto.saasphoto.ActivePlanPricingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.ActivePlanPricingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.ActivePlanPricingRequest}
 */
proto.saasphoto.ActivePlanPricingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.ActivePlanPricingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.ActivePlanPricingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.ActivePlanPricingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ActivePlanPricingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.ActivePlanPricingRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.ActivePlanPricingRequest} returns this
 */
proto.saasphoto.ActivePlanPricingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.saasphoto.ActivePlanPricingRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.ActivePlanPricingRequest} returns this
 */
proto.saasphoto.ActivePlanPricingRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.PlanCustomInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.PlanCustomInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.PlanCustomInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanCustomInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    storage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bandwidth: jspb.Message.getFieldWithDefault(msg, 2, 0),
    price: jspb.Message.getFieldWithDefault(msg, 3, 0),
    month: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.PlanCustomInfo}
 */
proto.saasphoto.PlanCustomInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.PlanCustomInfo;
  return proto.saasphoto.PlanCustomInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.PlanCustomInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.PlanCustomInfo}
 */
proto.saasphoto.PlanCustomInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStorage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBandwidth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.PlanCustomInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.PlanCustomInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.PlanCustomInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanCustomInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBandwidth();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint64 storage = 1;
 * @return {number}
 */
proto.saasphoto.PlanCustomInfo.prototype.getStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanCustomInfo} returns this
 */
proto.saasphoto.PlanCustomInfo.prototype.setStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 bandwidth = 2;
 * @return {number}
 */
proto.saasphoto.PlanCustomInfo.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanCustomInfo} returns this
 */
proto.saasphoto.PlanCustomInfo.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 price = 3;
 * @return {number}
 */
proto.saasphoto.PlanCustomInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanCustomInfo} returns this
 */
proto.saasphoto.PlanCustomInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 month = 4;
 * @return {number}
 */
proto.saasphoto.PlanCustomInfo.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanCustomInfo} returns this
 */
proto.saasphoto.PlanCustomInfo.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.AddPlanCustomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.AddPlanCustomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.AddPlanCustomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanCustomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    planCustomInfo: (f = msg.getPlanCustomInfo()) && proto.saasphoto.PlanCustomInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.AddPlanCustomRequest}
 */
proto.saasphoto.AddPlanCustomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.AddPlanCustomRequest;
  return proto.saasphoto.AddPlanCustomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.AddPlanCustomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.AddPlanCustomRequest}
 */
proto.saasphoto.AddPlanCustomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = new proto.saasphoto.PlanCustomInfo;
      reader.readMessage(value,proto.saasphoto.PlanCustomInfo.deserializeBinaryFromReader);
      msg.setPlanCustomInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.AddPlanCustomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.AddPlanCustomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.AddPlanCustomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanCustomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlanCustomInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.saasphoto.PlanCustomInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.saasphoto.AddPlanCustomRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AddPlanCustomRequest} returns this
 */
proto.saasphoto.AddPlanCustomRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PlanCustomInfo plan_custom_info = 2;
 * @return {?proto.saasphoto.PlanCustomInfo}
 */
proto.saasphoto.AddPlanCustomRequest.prototype.getPlanCustomInfo = function() {
  return /** @type{?proto.saasphoto.PlanCustomInfo} */ (
    jspb.Message.getWrapperField(this, proto.saasphoto.PlanCustomInfo, 2));
};


/**
 * @param {?proto.saasphoto.PlanCustomInfo|undefined} value
 * @return {!proto.saasphoto.AddPlanCustomRequest} returns this
*/
proto.saasphoto.AddPlanCustomRequest.prototype.setPlanCustomInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.AddPlanCustomRequest} returns this
 */
proto.saasphoto.AddPlanCustomRequest.prototype.clearPlanCustomInfo = function() {
  return this.setPlanCustomInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.AddPlanCustomRequest.prototype.hasPlanCustomInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.AddPlanCustomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.AddPlanCustomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.AddPlanCustomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanCustomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.AddPlanCustomResponse}
 */
proto.saasphoto.AddPlanCustomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.AddPlanCustomResponse;
  return proto.saasphoto.AddPlanCustomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.AddPlanCustomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.AddPlanCustomResponse}
 */
proto.saasphoto.AddPlanCustomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {!proto.saasphoto.PlanErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.AddPlanCustomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.AddPlanCustomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.AddPlanCustomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.AddPlanCustomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.AddPlanCustomResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.AddPlanCustomResponse} returns this
 */
proto.saasphoto.AddPlanCustomResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.saasphoto.AddPlanCustomResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.AddPlanCustomResponse} returns this
 */
proto.saasphoto.AddPlanCustomResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PlanErrorCode error_code = 3;
 * @return {!proto.saasphoto.PlanErrorCode}
 */
proto.saasphoto.AddPlanCustomResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.PlanErrorCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.saasphoto.PlanErrorCode} value
 * @return {!proto.saasphoto.AddPlanCustomResponse} returns this
 */
proto.saasphoto.AddPlanCustomResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetPlanCustomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetPlanCustomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetPlanCustomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanCustomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetPlanCustomRequest}
 */
proto.saasphoto.GetPlanCustomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetPlanCustomRequest;
  return proto.saasphoto.GetPlanCustomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetPlanCustomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetPlanCustomRequest}
 */
proto.saasphoto.GetPlanCustomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetPlanCustomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetPlanCustomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetPlanCustomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanCustomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.saasphoto.GetPlanCustomRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.GetPlanCustomRequest} returns this
 */
proto.saasphoto.GetPlanCustomRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetPlanCustomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetPlanCustomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetPlanCustomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanCustomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planPricings: (f = msg.getPlanPricings()) && proto.saasphoto.PlanPricingDetail.toObject(includeInstance, f),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetPlanCustomResponse}
 */
proto.saasphoto.GetPlanCustomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetPlanCustomResponse;
  return proto.saasphoto.GetPlanCustomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetPlanCustomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetPlanCustomResponse}
 */
proto.saasphoto.GetPlanCustomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.PlanPricingDetail;
      reader.readMessage(value,proto.saasphoto.PlanPricingDetail.deserializeBinaryFromReader);
      msg.setPlanPricings(value);
      break;
    case 2:
      var value = /** @type {!proto.saasphoto.PlanErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetPlanCustomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetPlanCustomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetPlanCustomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanCustomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanPricings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.saasphoto.PlanPricingDetail.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional PlanPricingDetail plan_pricings = 1;
 * @return {?proto.saasphoto.PlanPricingDetail}
 */
proto.saasphoto.GetPlanCustomResponse.prototype.getPlanPricings = function() {
  return /** @type{?proto.saasphoto.PlanPricingDetail} */ (
    jspb.Message.getWrapperField(this, proto.saasphoto.PlanPricingDetail, 1));
};


/**
 * @param {?proto.saasphoto.PlanPricingDetail|undefined} value
 * @return {!proto.saasphoto.GetPlanCustomResponse} returns this
*/
proto.saasphoto.GetPlanCustomResponse.prototype.setPlanPricings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.GetPlanCustomResponse} returns this
 */
proto.saasphoto.GetPlanCustomResponse.prototype.clearPlanPricings = function() {
  return this.setPlanPricings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.GetPlanCustomResponse.prototype.hasPlanPricings = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PlanErrorCode error_code = 2;
 * @return {!proto.saasphoto.PlanErrorCode}
 */
proto.saasphoto.GetPlanCustomResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.PlanErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.saasphoto.PlanErrorCode} value
 * @return {!proto.saasphoto.GetPlanCustomResponse} returns this
 */
proto.saasphoto.GetPlanCustomResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.PlanCustom.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.PlanCustom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.PlanCustom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanCustom.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    planCustomInfo: (f = msg.getPlanCustomInfo()) && proto.saasphoto.PlanCustomInfo.toObject(includeInstance, f),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.PlanCustom}
 */
proto.saasphoto.PlanCustom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.PlanCustom;
  return proto.saasphoto.PlanCustom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.PlanCustom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.PlanCustom}
 */
proto.saasphoto.PlanCustom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = new proto.saasphoto.PlanCustomInfo;
      reader.readMessage(value,proto.saasphoto.PlanCustomInfo.deserializeBinaryFromReader);
      msg.setPlanCustomInfo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.PlanCustom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.PlanCustom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.PlanCustom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.PlanCustom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlanCustomInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.saasphoto.PlanCustomInfo.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.PlanCustom.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.PlanCustom} returns this
 */
proto.saasphoto.PlanCustom.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.saasphoto.PlanCustom.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.PlanCustom} returns this
 */
proto.saasphoto.PlanCustom.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.saasphoto.PlanCustom.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.saasphoto.PlanCustom} returns this
 */
proto.saasphoto.PlanCustom.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PlanCustomInfo plan_custom_info = 4;
 * @return {?proto.saasphoto.PlanCustomInfo}
 */
proto.saasphoto.PlanCustom.prototype.getPlanCustomInfo = function() {
  return /** @type{?proto.saasphoto.PlanCustomInfo} */ (
    jspb.Message.getWrapperField(this, proto.saasphoto.PlanCustomInfo, 4));
};


/**
 * @param {?proto.saasphoto.PlanCustomInfo|undefined} value
 * @return {!proto.saasphoto.PlanCustom} returns this
*/
proto.saasphoto.PlanCustom.prototype.setPlanCustomInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.saasphoto.PlanCustom} returns this
 */
proto.saasphoto.PlanCustom.prototype.clearPlanCustomInfo = function() {
  return this.setPlanCustomInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.saasphoto.PlanCustom.prototype.hasPlanCustomInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.saasphoto.PlanCustom.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.PlanCustom} returns this
 */
proto.saasphoto.PlanCustom.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.saasphoto.GetPlanCustomsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.GetPlanCustomsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.GetPlanCustomsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanCustomsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    planCustomsList: jspb.Message.toObjectList(msg.getPlanCustomsList(),
    proto.saasphoto.PlanCustom.toObject, includeInstance),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.GetPlanCustomsResponse}
 */
proto.saasphoto.GetPlanCustomsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.GetPlanCustomsResponse;
  return proto.saasphoto.GetPlanCustomsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.GetPlanCustomsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.GetPlanCustomsResponse}
 */
proto.saasphoto.GetPlanCustomsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.saasphoto.PlanCustom;
      reader.readMessage(value,proto.saasphoto.PlanCustom.deserializeBinaryFromReader);
      msg.addPlanCustoms(value);
      break;
    case 2:
      var value = /** @type {!proto.saasphoto.PlanErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.GetPlanCustomsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.GetPlanCustomsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.GetPlanCustomsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanCustomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.saasphoto.PlanCustom.serializeBinaryToWriter
    );
  }
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated PlanCustom plan_customs = 1;
 * @return {!Array<!proto.saasphoto.PlanCustom>}
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.getPlanCustomsList = function() {
  return /** @type{!Array<!proto.saasphoto.PlanCustom>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.saasphoto.PlanCustom, 1));
};


/**
 * @param {!Array<!proto.saasphoto.PlanCustom>} value
 * @return {!proto.saasphoto.GetPlanCustomsResponse} returns this
*/
proto.saasphoto.GetPlanCustomsResponse.prototype.setPlanCustomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.saasphoto.PlanCustom=} opt_value
 * @param {number=} opt_index
 * @return {!proto.saasphoto.PlanCustom}
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.addPlanCustoms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.saasphoto.PlanCustom, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.saasphoto.GetPlanCustomsResponse} returns this
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.clearPlanCustomsList = function() {
  return this.setPlanCustomsList([]);
};


/**
 * optional PlanErrorCode error_code = 2;
 * @return {!proto.saasphoto.PlanErrorCode}
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.PlanErrorCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.saasphoto.PlanErrorCode} value
 * @return {!proto.saasphoto.GetPlanCustomsResponse} returns this
 */
proto.saasphoto.GetPlanCustomsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.ActivePlanCustomRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.ActivePlanCustomRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.ActivePlanCustomRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ActivePlanCustomRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.ActivePlanCustomRequest}
 */
proto.saasphoto.ActivePlanCustomRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.ActivePlanCustomRequest;
  return proto.saasphoto.ActivePlanCustomRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.ActivePlanCustomRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.ActivePlanCustomRequest}
 */
proto.saasphoto.ActivePlanCustomRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.ActivePlanCustomRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.ActivePlanCustomRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.ActivePlanCustomRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ActivePlanCustomRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.saasphoto.ActivePlanCustomRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.saasphoto.ActivePlanCustomRequest} returns this
 */
proto.saasphoto.ActivePlanCustomRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.saasphoto.ActivePlanCustomRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.saasphoto.ActivePlanCustomRequest} returns this
 */
proto.saasphoto.ActivePlanCustomRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.saasphoto.ActivePlanCustomResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.saasphoto.ActivePlanCustomResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.saasphoto.ActivePlanCustomResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ActivePlanCustomResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.saasphoto.ActivePlanCustomResponse}
 */
proto.saasphoto.ActivePlanCustomResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.saasphoto.ActivePlanCustomResponse;
  return proto.saasphoto.ActivePlanCustomResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.saasphoto.ActivePlanCustomResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.saasphoto.ActivePlanCustomResponse}
 */
proto.saasphoto.ActivePlanCustomResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.saasphoto.PlanErrorCode} */ (reader.readEnum());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.saasphoto.ActivePlanCustomResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.saasphoto.ActivePlanCustomResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.saasphoto.ActivePlanCustomResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.saasphoto.ActivePlanCustomResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional PlanErrorCode error_code = 1;
 * @return {!proto.saasphoto.PlanErrorCode}
 */
proto.saasphoto.ActivePlanCustomResponse.prototype.getErrorCode = function() {
  return /** @type {!proto.saasphoto.PlanErrorCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.saasphoto.PlanErrorCode} value
 * @return {!proto.saasphoto.ActivePlanCustomResponse} returns this
 */
proto.saasphoto.ActivePlanCustomResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.saasphoto.PlanErrorCode = {
  PLAN_OK: 0,
  PLAN_PERMISSION_DENIED: 1,
  PLAN_EMAIL_NOT_EXIST: 2,
  PLAN_CUSTOM_PLAN_NOT_EXIST: 3,
  PLAN_USER_CANNOT_USE_THIS_CUSTOM_PLAN: 4,
  PLAN_CUSTOM_PLAN_CANNOT_BE_USED: 5,
  PLAN_INVALID_KEY: 6,
  PLAN_CUSTOM_PLAN_UPDATE_FAILED: 7,
  PLAN_STORAGE_SIZE_IS_NOT_ENOUGH: 8
};

goog.object.extend(exports, proto.saasphoto);
