import React, {
    useEffect,
    createContext,
    ReactElement,
    useState,
    useContext,
    useCallback,
} from "react"
import { useIntl } from "react-intl"
import { ToastContext } from "./toast"
import toastMessage from "../messages/toast"

export const InternetStatusContext = createContext<boolean>(true)

InternetStatusContext.displayName = "InternetStatusContext"

export function InternetStatusProvider({
    children,
}: {
    children: ReactElement
}) {
    const intl = useIntl()

    const toastContextValue = useContext(ToastContext)
    const [connected, setConnected] = useState(navigator.onLine)

    const onOnline = useCallback(() => {
        setConnected(true)
        toastContextValue.display(
            intl.formatMessage(toastMessage.internetConnected),
        )
    }, [toastContextValue, intl])

    const onOffline = useCallback(() => {
        setConnected(false)
        toastContextValue.display(
            intl.formatMessage(toastMessage.internetDisconnected),
        )
    }, [toastContextValue, intl])

    useEffect(() => {
        window.addEventListener("online", onOnline)
        window.addEventListener("offline", onOffline)
        return () => {
            window.removeEventListener("online", onOnline)
            window.removeEventListener("offline", onOffline)
        }
    }, [onOnline, onOffline])

    return (
        <InternetStatusContext.Provider value={connected}>
            {children}
        </InternetStatusContext.Provider>
    )
}
export const InternetStatusConsumer = InternetStatusContext.Consumer
