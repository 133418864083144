import React, { ErrorInfo } from "react"
import { defineMessages, injectIntl, WrappedComponentProps } from "react-intl"
import StatusLayout from "../components/StatusLayout"
import errorImage from "../images/hintIcon-500.png"
import FormSecondaryButton from "../components/button/FormSecondaryButton"
import { NavigateFunction, useNavigate } from "react-router-dom"

const messages = defineMessages({
    title: {
        id: "error.boundary.title",
        defaultMessage: "Oops! Something went wrong",
    },
    description: {
        id: "error.boundary.description",
        defaultMessage: "If you continue to see this error, please contact us.",
    },
    button: {
        id: "error.boundary.retry",
        defaultMessage: "Try Again",
    },
})

interface Props extends WrappedComponentProps {
    children: React.ReactNode
    navigate: NavigateFunction
}

interface State {
    hasError: boolean
}

export const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const navigate = useNavigate()

        return <Component navigate={navigate} {...props} />
    }

    return Wrapper
}

class ErrorBoundary extends React.PureComponent<Props, State> {
    public state: State = {
        hasError: false,
    }

    private handleClickButton = () => {
        const { navigate } = this.props
        navigate(0)
    }

    public static getDerivedStateFromError() {
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo)
    }

    public render() {
        const { intl, children } = this.props
        const { hasError } = this.state

        return hasError ? (
            <StatusLayout
                title={intl.formatMessage(messages.title)}
                description={intl.formatMessage(messages.description)}
                image={errorImage}
                buttonComponents={[
                    <FormSecondaryButton onClick={this.handleClickButton}>
                        {intl.formatMessage(messages.button)}
                    </FormSecondaryButton>,
                ]}
            />
        ) : (
            children
        )
    }
}

export default injectIntl(withRouter(ErrorBoundary))
