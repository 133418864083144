export function removeTrailingSlash(path: string) {
    const regex = /\/$/
    if (regex.test(path)) {
        return path.slice(0, -1)
    }
    return path
}
export function removeExtension(filename: string) {
    const lastDotIndex = filename.lastIndexOf(".")
    if (lastDotIndex === -1) return filename
    return filename.substring(0, lastDotIndex)
}
export function getFileExtension(filename: string): string {
    const parts = filename.split(".")
    return parts.length > 1 ? parts.pop() ?? "" : ""
}
