import { RpcError, StatusCode } from "grpc-web"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { IntlShape } from "react-intl/lib"
import { BehaviorSubject, EMPTY, combineLatest, of, throwError } from "rxjs"
import { catchError, filter, switchMap, take } from "rxjs/operators"
import { UnaryCallback, grpc } from "../grpc"

import { AuthContext } from "../context/auth"
import { ImpersonateContext } from "../context/impersonate"
import { IToastProps, ToastContext } from "../context/toast"
import { HttpKey } from "../enums"
import toastMessage from "../messages/toast"

function notShowErrorWhen<T>(e: RpcError, key: T, ...keys: T[]) {
    //Disregard the display timeout error for a specific instance of apt.
    if (e?.code !== StatusCode.DEADLINE_EXCEEDED) return false
    if (keys.includes(key)) return true
    return false
}
export default function useAuthGRPC<T, R, B>(
    callback: UnaryCallback<T, R>,
    toBind: B,
    noToken?: boolean,
) {
    const binded = useMemo(() => callback.bind(toBind), [toBind, callback])

    const intl = useIntl()

    const { getIdToken, checkValidUser$ } = useContext(AuthContext)
    const toastContextValue = useContext(ToastContext)
    const [toastContext$] = useState(
        new BehaviorSubject<IToastProps | null>(null),
    )
    useEffect(() => {
        toastContext$.next(toastContextValue)
    }, [toastContext$, toastContextValue])

    const wrapped = useMemo(() => grpc(binded), [binded])

    const [intl$] = useState(new BehaviorSubject<IntlShape | null>(null))
    useEffect(() => {
        intl$.next(intl)
    }, [intl, intl$])
    const { asEmail } = useContext(ImpersonateContext)

    return useCallback(
        (request: T, metadata?: any, options?: any) => {
            let returnObservable$ = of("noToken")

            if (!noToken) {
                returnObservable$ = getIdToken()
            }
            return returnObservable$.pipe(
                switchMap((idtoken) => {
                    const m = Object.assign(
                        metadata || {},
                        { idtoken: idtoken },
                        asEmail.length > 0 ? { asEmail } : {},
                    )

                    return wrapped(request, m).pipe(
                        catchError((e) => {
                            if (e.code === StatusCode.PERMISSION_DENIED) {
                                // if CUSTOMER_UNKNOWN, CUSTOMER_NO_USER_PLAN, CUSTOMER_USER_PLAN_EXPIRED
                                checkValidUser$.next(m)
                                return EMPTY
                            }
                            combineLatest({
                                intl: intl$.pipe(filter((x) => x !== null)),
                                toast: toastContext$.pipe(
                                    filter((x) => x !== null),
                                ),
                            })
                                .pipe(take(1))
                                .subscribe(({ intl, toast }) => {
                                    notShowErrorWhen(
                                        e,
                                        options?.httpKey,
                                        HttpKey.REMOVE_IMAGE_BACKGROUND,
                                    ) ||
                                        toast?.display(
                                            intl?.formatMessage(
                                                toastMessage.serverError,
                                            ) || "Server Error",
                                        )
                                })

                            return throwError(() => e)
                        }),
                    )
                }),
            )
        },
        [
            wrapped,
            getIdToken,
            intl$,
            toastContext$,
            checkValidUser$,
            asEmail,
            noToken,
        ],
    )
}
