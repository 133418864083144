import * as React from "react"
import {
    IndexRouteProps,
    LayoutRouteProps,
    Navigate,
    PathRouteProps,
    RouteProps,
} from "react-router-dom"
import useLogin from "../hooks/useLogin"

type IMemorizedProtectedRouteProps = (
    | PathRouteProps
    | LayoutRouteProps
    | IndexRouteProps
) & {
    login?: boolean
}

const MemorizedProtectedRoute = React.memo(
    ({ login, element, ...rest }: IMemorizedProtectedRouteProps) => {
        return login ? <>{element}</> : <Navigate to="/l/login" />
    },
)

export default function ProtectedRoute(props: RouteProps) {
    const login = useLogin()
    return <MemorizedProtectedRoute login={login} {...props} />
}
