import React from "react"
import { styled } from "@material-ui/core/styles"
import invertLogo from "../images/saasphoto-invert.png"
import logo from "../images/saasphoto.svg"

const LogoImage = styled("img")({
    display: "block",
    maxWidth: "100%",
    height: "auto",
    cursor: "pointer",
})

interface ILogoProps {
    invert?: boolean
}

export default function Logo(props: ILogoProps) {
    return (
        <LogoImage
            onClick={() => (window.location.href = "/")}
            src={props.invert ? invertLogo : logo}
            alt="saasphoto"
        />
    )
}
