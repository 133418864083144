import Grid, { GridProps } from "@material-ui/core/Grid"
import { makeStyles, Theme } from "@material-ui/core/styles"

interface IOverlayProps extends GridProps {
    shouldBlurBackground?: boolean
}

interface IOverlayStyleProps {
    shouldBlurBackground?: boolean
}

const useStyles = makeStyles<Theme, IOverlayStyleProps>({
    container: {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        backgroundColor: (props) =>
            props.shouldBlurBackground
                ? "rgba(255, 255, 255, 0.9)"
                : "transparent",
        zIndex: 100,
    },
})

export default function Overlay({
    shouldBlurBackground,
    children,
}: IOverlayProps) {
    const classes = useStyles({ shouldBlurBackground })

    return (
        <>
            {children ? (
                <Grid
                    container
                    className={classes.container}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    {children}
                </Grid>
            ) : null}
        </>
    )
}
