/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/drive.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.saasphoto = require('./drive_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.DriveClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.DrivePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.LsRequest,
 *   !proto.saasphoto.LsResponse>}
 */
const methodDescriptor_Drive_Ls = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Ls',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.LsRequest,
  proto.saasphoto.LsResponse,
  /**
   * @param {!proto.saasphoto.LsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.LsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.LsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.LsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.LsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.ls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Ls',
      request,
      metadata || {},
      methodDescriptor_Drive_Ls,
      callback);
};


/**
 * @param {!proto.saasphoto.LsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.LsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.ls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Ls',
      request,
      metadata || {},
      methodDescriptor_Drive_Ls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.InfoRequest,
 *   !proto.saasphoto.InfoResponse>}
 */
const methodDescriptor_Drive_Info = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Info',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.InfoRequest,
  proto.saasphoto.InfoResponse,
  /**
   * @param {!proto.saasphoto.InfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.InfoResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.InfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.InfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.InfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.info =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Info',
      request,
      metadata || {},
      methodDescriptor_Drive_Info,
      callback);
};


/**
 * @param {!proto.saasphoto.InfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.InfoResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.info =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Info',
      request,
      metadata || {},
      methodDescriptor_Drive_Info);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetUploadLinkRequest,
 *   !proto.saasphoto.GetUploadLinkResponse>}
 */
const methodDescriptor_Drive_GetUploadLink = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/GetUploadLink',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetUploadLinkRequest,
  proto.saasphoto.GetUploadLinkResponse,
  /**
   * @param {!proto.saasphoto.GetUploadLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetUploadLinkResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetUploadLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetUploadLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetUploadLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.getUploadLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/GetUploadLink',
      request,
      metadata || {},
      methodDescriptor_Drive_GetUploadLink,
      callback);
};


/**
 * @param {!proto.saasphoto.GetUploadLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetUploadLinkResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.getUploadLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/GetUploadLink',
      request,
      metadata || {},
      methodDescriptor_Drive_GetUploadLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.MkdirRequest,
 *   !proto.saasphoto.MkdirResponse>}
 */
const methodDescriptor_Drive_Mkdir = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Mkdir',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.MkdirRequest,
  proto.saasphoto.MkdirResponse,
  /**
   * @param {!proto.saasphoto.MkdirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.MkdirResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.MkdirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.MkdirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.MkdirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.mkdir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Mkdir',
      request,
      metadata || {},
      methodDescriptor_Drive_Mkdir,
      callback);
};


/**
 * @param {!proto.saasphoto.MkdirRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.MkdirResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.mkdir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Mkdir',
      request,
      metadata || {},
      methodDescriptor_Drive_Mkdir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.MoveFileRequest,
 *   !proto.saasphoto.MoveResponse>}
 */
const methodDescriptor_Drive_Move = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Move',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.MoveFileRequest,
  proto.saasphoto.MoveResponse,
  /**
   * @param {!proto.saasphoto.MoveFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.MoveResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.MoveFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.MoveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.MoveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.move =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Move',
      request,
      metadata || {},
      methodDescriptor_Drive_Move,
      callback);
};


/**
 * @param {!proto.saasphoto.MoveFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.MoveResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.move =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Move',
      request,
      metadata || {},
      methodDescriptor_Drive_Move);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.CopyRequest,
 *   !proto.saasphoto.CopyResponse>}
 */
const methodDescriptor_Drive_Copy = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Copy',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.CopyRequest,
  proto.saasphoto.CopyResponse,
  /**
   * @param {!proto.saasphoto.CopyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.CopyResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.CopyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.CopyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.CopyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.copy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Copy',
      request,
      metadata || {},
      methodDescriptor_Drive_Copy,
      callback);
};


/**
 * @param {!proto.saasphoto.CopyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.CopyResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.copy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Copy',
      request,
      metadata || {},
      methodDescriptor_Drive_Copy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SearchFileRequest,
 *   !proto.saasphoto.SearchFileResponse>}
 */
const methodDescriptor_Drive_Search = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Search',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SearchFileRequest,
  proto.saasphoto.SearchFileResponse,
  /**
   * @param {!proto.saasphoto.SearchFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.SearchFileResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SearchFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.SearchFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.SearchFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Search',
      request,
      metadata || {},
      methodDescriptor_Drive_Search,
      callback);
};


/**
 * @param {!proto.saasphoto.SearchFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.SearchFileResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Search',
      request,
      metadata || {},
      methodDescriptor_Drive_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetUploadFolderListResponse>}
 */
const methodDescriptor_Drive_GetUploadFolderList = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/GetUploadFolderList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetUploadFolderListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetUploadFolderListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetUploadFolderListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetUploadFolderListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.getUploadFolderList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/GetUploadFolderList',
      request,
      metadata || {},
      methodDescriptor_Drive_GetUploadFolderList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetUploadFolderListResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.getUploadFolderList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/GetUploadFolderList',
      request,
      metadata || {},
      methodDescriptor_Drive_GetUploadFolderList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ArchiveRequest,
 *   !proto.saasphoto.ArchiveResponse>}
 */
const methodDescriptor_Drive_Archive = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Archive',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ArchiveRequest,
  proto.saasphoto.ArchiveResponse,
  /**
   * @param {!proto.saasphoto.ArchiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.ArchiveResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ArchiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.ArchiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.ArchiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.archive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Archive',
      request,
      metadata || {},
      methodDescriptor_Drive_Archive,
      callback);
};


/**
 * @param {!proto.saasphoto.ArchiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.ArchiveResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.archive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Archive',
      request,
      metadata || {},
      methodDescriptor_Drive_Archive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.DeleteRequest,
 *   !proto.saasphoto.DeleteResponse>}
 */
const methodDescriptor_Drive_Delete = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Delete',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.DeleteRequest,
  proto.saasphoto.DeleteResponse,
  /**
   * @param {!proto.saasphoto.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.DeleteResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.DeleteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.DeleteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Delete',
      request,
      metadata || {},
      methodDescriptor_Drive_Delete,
      callback);
};


/**
 * @param {!proto.saasphoto.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.DeleteResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Delete',
      request,
      metadata || {},
      methodDescriptor_Drive_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SetFavoriteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Drive_SetFavorite = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/SetFavorite',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SetFavoriteRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.SetFavoriteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SetFavoriteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.setFavorite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/SetFavorite',
      request,
      metadata || {},
      methodDescriptor_Drive_SetFavorite,
      callback);
};


/**
 * @param {!proto.saasphoto.SetFavoriteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.setFavorite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/SetFavorite',
      request,
      metadata || {},
      methodDescriptor_Drive_SetFavorite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.LsResponse>}
 */
const methodDescriptor_Drive_LsFavorite = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/LsFavorite',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.LsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.LsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.LsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.LsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.lsFavorite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/LsFavorite',
      request,
      metadata || {},
      methodDescriptor_Drive_LsFavorite,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.LsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.lsFavorite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/LsFavorite',
      request,
      metadata || {},
      methodDescriptor_Drive_LsFavorite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.LsResponse>}
 */
const methodDescriptor_Drive_LsRecent = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/LsRecent',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.LsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.LsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.LsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.LsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.lsRecent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/LsRecent',
      request,
      metadata || {},
      methodDescriptor_Drive_LsRecent,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.LsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.lsRecent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/LsRecent',
      request,
      metadata || {},
      methodDescriptor_Drive_LsRecent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.LsViewRankRequest,
 *   !proto.saasphoto.LsResponse>}
 */
const methodDescriptor_Drive_LsViewRank = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/LsViewRank',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.LsViewRankRequest,
  proto.saasphoto.LsResponse,
  /**
   * @param {!proto.saasphoto.LsViewRankRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.LsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.LsViewRankRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.LsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.LsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.lsViewRank =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/LsViewRank',
      request,
      metadata || {},
      methodDescriptor_Drive_LsViewRank,
      callback);
};


/**
 * @param {!proto.saasphoto.LsViewRankRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.LsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.lsViewRank =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/LsViewRank',
      request,
      metadata || {},
      methodDescriptor_Drive_LsViewRank);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.LsTrashCanResponse>}
 */
const methodDescriptor_Drive_LsTrashCan = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/LsTrashCan',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.LsTrashCanResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.LsTrashCanResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.LsTrashCanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.LsTrashCanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.lsTrashCan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/LsTrashCan',
      request,
      metadata || {},
      methodDescriptor_Drive_LsTrashCan,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.LsTrashCanResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.lsTrashCan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/LsTrashCan',
      request,
      metadata || {},
      methodDescriptor_Drive_LsTrashCan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.DropRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Drive_Drop = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Drop',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.DropRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.DropRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.DropRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.drop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Drop',
      request,
      metadata || {},
      methodDescriptor_Drive_Drop,
      callback);
};


/**
 * @param {!proto.saasphoto.DropRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.drop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Drop',
      request,
      metadata || {},
      methodDescriptor_Drive_Drop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.RestoreRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Drive_Restore = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/Restore',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.RestoreRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.RestoreRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.RestoreRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.restore =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/Restore',
      request,
      metadata || {},
      methodDescriptor_Drive_Restore,
      callback);
};


/**
 * @param {!proto.saasphoto.RestoreRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.restore =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/Restore',
      request,
      metadata || {},
      methodDescriptor_Drive_Restore);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.IsExistsRequest,
 *   !proto.saasphoto.IsExistsResponse>}
 */
const methodDescriptor_Drive_IsExists = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/IsExists',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.IsExistsRequest,
  proto.saasphoto.IsExistsResponse,
  /**
   * @param {!proto.saasphoto.IsExistsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.IsExistsResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.IsExistsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.IsExistsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.IsExistsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.isExists =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/IsExists',
      request,
      metadata || {},
      methodDescriptor_Drive_IsExists,
      callback);
};


/**
 * @param {!proto.saasphoto.IsExistsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.IsExistsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.isExists =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/IsExists',
      request,
      metadata || {},
      methodDescriptor_Drive_IsExists);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.PathMessage,
 *   !proto.saasphoto.PathMessage>}
 */
const methodDescriptor_Drive_ResolveName = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/ResolveName',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.PathMessage,
  proto.saasphoto.PathMessage,
  /**
   * @param {!proto.saasphoto.PathMessage} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PathMessage.deserializeBinary
);


/**
 * @param {!proto.saasphoto.PathMessage} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PathMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PathMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.resolveName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/ResolveName',
      request,
      metadata || {},
      methodDescriptor_Drive_ResolveName,
      callback);
};


/**
 * @param {!proto.saasphoto.PathMessage} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PathMessage>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.resolveName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/ResolveName',
      request,
      metadata || {},
      methodDescriptor_Drive_ResolveName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetShareLinksRequest,
 *   !proto.saasphoto.GetShareLinksResponse>}
 */
const methodDescriptor_Drive_GetShareLinks = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/GetShareLinks',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetShareLinksRequest,
  proto.saasphoto.GetShareLinksResponse,
  /**
   * @param {!proto.saasphoto.GetShareLinksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetShareLinksResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetShareLinksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetShareLinksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetShareLinksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.getShareLinks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/GetShareLinks',
      request,
      metadata || {},
      methodDescriptor_Drive_GetShareLinks,
      callback);
};


/**
 * @param {!proto.saasphoto.GetShareLinksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetShareLinksResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.getShareLinks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/GetShareLinks',
      request,
      metadata || {},
      methodDescriptor_Drive_GetShareLinks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetUploadLinksRequest,
 *   !proto.saasphoto.GetUploadLinksResponse>}
 */
const methodDescriptor_Drive_GetUploadLinks = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/GetUploadLinks',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetUploadLinksRequest,
  proto.saasphoto.GetUploadLinksResponse,
  /**
   * @param {!proto.saasphoto.GetUploadLinksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetUploadLinksResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetUploadLinksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetUploadLinksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetUploadLinksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.getUploadLinks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/GetUploadLinks',
      request,
      metadata || {},
      methodDescriptor_Drive_GetUploadLinks,
      callback);
};


/**
 * @param {!proto.saasphoto.GetUploadLinksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetUploadLinksResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.getUploadLinks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/GetUploadLinks',
      request,
      metadata || {},
      methodDescriptor_Drive_GetUploadLinks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ClearCDNCacheRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Drive_ClearCDNCache = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/ClearCDNCache',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ClearCDNCacheRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.ClearCDNCacheRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ClearCDNCacheRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.clearCDNCache =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/ClearCDNCache',
      request,
      metadata || {},
      methodDescriptor_Drive_ClearCDNCache,
      callback);
};


/**
 * @param {!proto.saasphoto.ClearCDNCacheRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.clearCDNCache =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/ClearCDNCache',
      request,
      metadata || {},
      methodDescriptor_Drive_ClearCDNCache);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetServerStatusRequest,
 *   !proto.saasphoto.GetServerStatusResponse>}
 */
const methodDescriptor_Drive_GetServerStatus = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/GetServerStatus',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetServerStatusRequest,
  proto.saasphoto.GetServerStatusResponse,
  /**
   * @param {!proto.saasphoto.GetServerStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetServerStatusResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetServerStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetServerStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetServerStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.getServerStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/GetServerStatus',
      request,
      metadata || {},
      methodDescriptor_Drive_GetServerStatus,
      callback);
};


/**
 * @param {!proto.saasphoto.GetServerStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetServerStatusResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.getServerStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/GetServerStatus',
      request,
      metadata || {},
      methodDescriptor_Drive_GetServerStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.MkProjectRequest,
 *   !proto.saasphoto.MkProjectResponse>}
 */
const methodDescriptor_Drive_MkProject = new grpc.web.MethodDescriptor(
  '/saasphoto.Drive/MkProject',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.MkProjectRequest,
  proto.saasphoto.MkProjectResponse,
  /**
   * @param {!proto.saasphoto.MkProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.MkProjectResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.MkProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.MkProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.MkProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.DriveClient.prototype.mkProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Drive/MkProject',
      request,
      metadata || {},
      methodDescriptor_Drive_MkProject,
      callback);
};


/**
 * @param {!proto.saasphoto.MkProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.MkProjectResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.DrivePromiseClient.prototype.mkProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Drive/MkProject',
      request,
      metadata || {},
      methodDescriptor_Drive_MkProject);
};


module.exports = proto.saasphoto;

