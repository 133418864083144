import * as React from "react"
import { useContext } from "react"
import {
    IndexRouteProps,
    LayoutRouteProps,
    PathRouteProps,
    RouteProps,
} from "react-router-dom"
import { AuthContext } from "../context/auth"
import useLogin from "../hooks/useLogin"
type IMemorizedProtectedRouteProps = (
    | PathRouteProps
    | LayoutRouteProps
    | IndexRouteProps
) & {
    login?: boolean
}

const MemorizedForceLogoutRoute = React.memo(
    ({ login, ...rest }: IMemorizedProtectedRouteProps) => {
        const { logout } = useContext(AuthContext)
        const { element } = rest
        return login ? (
            (() => {
                logout()

                return <>{element}</>
            })()
        ) : (
            <>{element}</>
        )
    },
)

export default function ForceLogoutRoute(props: RouteProps) {
    const login = useLogin()
    return <MemorizedForceLogoutRoute login={login} {...props} />
}
