/**
 * @fileoverview gRPC-Web generated client stub for saasphoto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: protocol/payment.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.saasphoto = require('./payment_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.PaymentClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.saasphoto.PaymentPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.ChargePlanRequest,
 *   !proto.saasphoto.PaymentResponse>}
 */
const methodDescriptor_Payment_ChargePlan = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/ChargePlan',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.ChargePlanRequest,
  proto.saasphoto.PaymentResponse,
  /**
   * @param {!proto.saasphoto.ChargePlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PaymentResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.ChargePlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.chargePlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/ChargePlan',
      request,
      metadata || {},
      methodDescriptor_Payment_ChargePlan,
      callback);
};


/**
 * @param {!proto.saasphoto.ChargePlanRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PaymentResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.chargePlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/ChargePlan',
      request,
      metadata || {},
      methodDescriptor_Payment_ChargePlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.RefillBandwidthRequest,
 *   !proto.saasphoto.PaymentResponse>}
 */
const methodDescriptor_Payment_RefillBandwidth = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/RefillBandwidth',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.RefillBandwidthRequest,
  proto.saasphoto.PaymentResponse,
  /**
   * @param {!proto.saasphoto.RefillBandwidthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PaymentResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.RefillBandwidthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.refillBandwidth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/RefillBandwidth',
      request,
      metadata || {},
      methodDescriptor_Payment_RefillBandwidth,
      callback);
};


/**
 * @param {!proto.saasphoto.RefillBandwidthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PaymentResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.refillBandwidth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/RefillBandwidth',
      request,
      metadata || {},
      methodDescriptor_Payment_RefillBandwidth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.RefillCreditRequest,
 *   !proto.saasphoto.PaymentResponse>}
 */
const methodDescriptor_Payment_RefillCredit = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/RefillCredit',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.RefillCreditRequest,
  proto.saasphoto.PaymentResponse,
  /**
   * @param {!proto.saasphoto.RefillCreditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PaymentResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.RefillCreditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PaymentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PaymentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.refillCredit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/RefillCredit',
      request,
      metadata || {},
      methodDescriptor_Payment_RefillCredit,
      callback);
};


/**
 * @param {!proto.saasphoto.RefillCreditRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PaymentResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.refillCredit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/RefillCredit',
      request,
      metadata || {},
      methodDescriptor_Payment_RefillCredit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.FinishedRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Payment_Finished = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/Finished',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.FinishedRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.FinishedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.FinishedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.finished =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/Finished',
      request,
      metadata || {},
      methodDescriptor_Payment_Finished,
      callback);
};


/**
 * @param {!proto.saasphoto.FinishedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.finished =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/Finished',
      request,
      metadata || {},
      methodDescriptor_Payment_Finished);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.GetChargePlanAmountRequest,
 *   !proto.saasphoto.GetChargePlanAmountResponse>}
 */
const methodDescriptor_Payment_GetChargePlanAmount = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/GetChargePlanAmount',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.GetChargePlanAmountRequest,
  proto.saasphoto.GetChargePlanAmountResponse,
  /**
   * @param {!proto.saasphoto.GetChargePlanAmountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetChargePlanAmountResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.GetChargePlanAmountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetChargePlanAmountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetChargePlanAmountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.getChargePlanAmount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/GetChargePlanAmount',
      request,
      metadata || {},
      methodDescriptor_Payment_GetChargePlanAmount,
      callback);
};


/**
 * @param {!proto.saasphoto.GetChargePlanAmountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetChargePlanAmountResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.getChargePlanAmount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/GetChargePlanAmount',
      request,
      metadata || {},
      methodDescriptor_Payment_GetChargePlanAmount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetStripeCustomerPortalResponse>}
 */
const methodDescriptor_Payment_GetStripeCustomerPortal = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/GetStripeCustomerPortal',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetStripeCustomerPortalResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetStripeCustomerPortalResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetStripeCustomerPortalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetStripeCustomerPortalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.getStripeCustomerPortal =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/GetStripeCustomerPortal',
      request,
      metadata || {},
      methodDescriptor_Payment_GetStripeCustomerPortal,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetStripeCustomerPortalResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.getStripeCustomerPortal =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/GetStripeCustomerPortal',
      request,
      metadata || {},
      methodDescriptor_Payment_GetStripeCustomerPortal);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.saasphoto.GetPaymentMethodsResponse>}
 */
const methodDescriptor_Payment_GetPaymentMethods = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/GetPaymentMethods',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.saasphoto.GetPaymentMethodsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.GetPaymentMethodsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.GetPaymentMethodsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.GetPaymentMethodsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.getPaymentMethods =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/GetPaymentMethods',
      request,
      metadata || {},
      methodDescriptor_Payment_GetPaymentMethods,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.GetPaymentMethodsResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.getPaymentMethods =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/GetPaymentMethods',
      request,
      metadata || {},
      methodDescriptor_Payment_GetPaymentMethods);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.UpdatePaymentMethodRequest,
 *   !proto.saasphoto.PaymentMethod>}
 */
const methodDescriptor_Payment_UpdatePaymentMethod = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/UpdatePaymentMethod',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.UpdatePaymentMethodRequest,
  proto.saasphoto.PaymentMethod,
  /**
   * @param {!proto.saasphoto.UpdatePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.PaymentMethod.deserializeBinary
);


/**
 * @param {!proto.saasphoto.UpdatePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.PaymentMethod)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.PaymentMethod>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.updatePaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/UpdatePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_UpdatePaymentMethod,
      callback);
};


/**
 * @param {!proto.saasphoto.UpdatePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.PaymentMethod>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.updatePaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/UpdatePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_UpdatePaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.AddPaymentMethodRequest,
 *   !proto.saasphoto.AddPaymentMethodResponse>}
 */
const methodDescriptor_Payment_AddPaymentMethod = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/AddPaymentMethod',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.AddPaymentMethodRequest,
  proto.saasphoto.AddPaymentMethodResponse,
  /**
   * @param {!proto.saasphoto.AddPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.saasphoto.AddPaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.saasphoto.AddPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.saasphoto.AddPaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.saasphoto.AddPaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.addPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/AddPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_AddPaymentMethod,
      callback);
};


/**
 * @param {!proto.saasphoto.AddPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.saasphoto.AddPaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.addPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/AddPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_AddPaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.RemovePaymentMethodRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Payment_RemovePaymentMethod = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/RemovePaymentMethod',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.RemovePaymentMethodRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.RemovePaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.RemovePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.removePaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/RemovePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_RemovePaymentMethod,
      callback);
};


/**
 * @param {!proto.saasphoto.RemovePaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.removePaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/RemovePaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_RemovePaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.saasphoto.SetDefaultPaymentMethodRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Payment_SetDefaultPaymentMethod = new grpc.web.MethodDescriptor(
  '/saasphoto.Payment/SetDefaultPaymentMethod',
  grpc.web.MethodType.UNARY,
  proto.saasphoto.SetDefaultPaymentMethodRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.saasphoto.SetDefaultPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.saasphoto.SetDefaultPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.saasphoto.PaymentClient.prototype.setDefaultPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/saasphoto.Payment/SetDefaultPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_SetDefaultPaymentMethod,
      callback);
};


/**
 * @param {!proto.saasphoto.SetDefaultPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.saasphoto.PaymentPromiseClient.prototype.setDefaultPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/saasphoto.Payment/SetDefaultPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_Payment_SetDefaultPaymentMethod);
};


module.exports = proto.saasphoto;

