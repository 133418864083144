/* eslint-disable no-fallthrough */
import React, {
    createContext,
    ReactElement,
    useState,
    useCallback,
    useMemo,
} from "react"
import { SnackbarProps } from "@material-ui/core/Snackbar"

export interface IToastData {
    sourcePaths?: Array<string>
    targetFolderPath?: string
}
export type Display = (
    message: string,
    configs?: { onUndo?: () => void },
) => any
export type UndoAction = () => void | undefined
export type ICloseHandler = (
    event?:
        | React.SyntheticEvent<Element, Event>
        | React.MouseEvent<Element, MouseEvent>,
    reason?: string | undefined,
) => void

export interface IToastProps extends SnackbarProps {
    display: Display
    closeHandler: ICloseHandler
    undoAction?: UndoAction
    message: string
}

export const ToastContext = createContext<IToastProps>({
    display: () => {},
    open: false,
    closeHandler: () => {},
    undoAction: () => {},
    message: "",
})

ToastContext.displayName = "ToastContext"

export function ToastProvider({ children }: { children: ReactElement }) {
    const [open, setOpen] = useState<boolean>(false)
    const [message, setMessage] = useState("")

    const [undoAction, setUndoAction] = useState<UndoAction>()

    const display = useCallback<Display>((message, configs) => {
        if (configs) {
            const { onUndo } = configs
            setUndoAction(() => {
                return onUndo
            })
        } else {
            setUndoAction(undefined)
        }
        setMessage(message)
        setOpen(true)
    }, [])

    const closeHandler = useCallback(
        (event?: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
            if (reason === "clickaway") {
                return
            }
            setOpen(false)
        },
        [],
    )

    const value = useMemo(
        () => ({
            display,
            open,
            closeHandler,
            undoAction,
            message,
        }),
        [display, open, closeHandler, undoAction, message],
    )

    return (
        <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
    )
}
export const ToastConsumer = ToastContext.Consumer
